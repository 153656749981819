import {createSlice} from "@reduxjs/toolkit";

type FetchState = 'failed' | 'idle' | 'loading' | 'succeeded'

interface CompositeSubscriptionsState {
    subscriptions: object,
    error: string | undefined,
    status: FetchState
}

const initialState: CompositeSubscriptionsState = {
    subscriptions: {},
    error: undefined,
    status: 'idle'
};

export const compositeSubscriptionsSlice = createSlice({
        name: 'compositeSubscriptions',
        initialState,
        reducers: {
            updateCompositeSubscriptions: (
                state,
                {payload}) => {
                state.subscriptions = payload;
                state.status = 'succeeded';
            },
        },
    }
);

export default compositeSubscriptionsSlice.reducer;

export const {updateCompositeSubscriptions} = compositeSubscriptionsSlice.actions;

export const selectCompositeSubscriptionsStatus = state => state.compositeSubscriptions.status;

export const selectCompositeSubscriptions = state => state.compositeSubscriptions.subscriptions;
