import {Component, ReactNode} from 'react';
import * as log from 'loglevel';
import {ErrorLoading} from './ErrorLoading';

type ErrorHandlerProps = {
    children: ReactNode
    defaultRender?: (error: any) => ReactNode
}

class ErrorHandler extends Component<ErrorHandlerProps> {
    state = {
        error: null
    }

    static getDerivedStateFromError(_error) {
        return {error: true}
    }

    componentDidCatch(error, info) {
        this.logErrorToServices(error.toString(), info)
    }

    logErrorToServices = (error, info) => log.error(error, info)

    render() {
        if (this.state.error) {
            if (this.props.defaultRender) {
                return this.props.defaultRender(this.state.error)
            } else {
                log.error(this.state.error);
                return (
                    <ErrorLoading/>
                )
            }
        } else {
            return (
                this.props.children
            )
        }
    }
}

export default ErrorHandler;
