import * as log from 'loglevel';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {unixConverterEncode, unixConverterYear_strategy} from './utils';
import {ApiBaseUrl} from '../App';
import {authService} from "./AuthenticationService";
import {DataProcessors} from './DataProcessors';
import {productNamespace} from '../namespace/namespace';

export type ApiReq =
    'availableSubscriptions'
    | 'compositeRealTimeStatus'
    | 'historical'
    | 'portfolioSubscriptions'
    | 'portfolioWeights'
    | 'realTimeAll'
    | 'resources'
    | 'stockSplit'
    | 'strategy'
    | 'subscriptions'
    | 'systemStatus';

export type StrategyName =
    'FirstUpFirstEnd'
    | 'FirstUpOpEnd'
    | 'Invert'
    | 'SecondUpFirstEnd'
    | 'SecondUpOpEnd'
    | 'ThirdUpFirstEnd';
export type Trade = 'Long' | 'Short';

interface AllSubscriptionsRequest {
    email: string
}

interface CompositeSubscriptionsRequest {
    email: string,
    alertType: string
}

interface AvailableSubscription {
    alertType: string,
    algorithmSpecId: number,
    classifications: string[],
    description: string,
    exchange: string,
    region: string,
    subscriptionAlert: string,
    symbol: string,
}

interface CompositeHistoricalDataRequest {
    product: string
    portfolioName: string,
    symbol: string,
    startTime?: string,
    endTime?: string,
}

interface HistoricalDataRequest {
    product: string,
    symbol: string,
    startTime?: string,
    endTime?: string,
}

export interface HistoricalData {
    dts: string,
    flag: string,
    price: number,
    symbol: string,
    endFlag: string,
    endPrice: number,
    endTime: string,
}

interface PortfolioSubscriptionsRequest {
    email: string
}

interface PortfolioWeightsRequest {
    portfolio: string
    user: string
}

interface PortfolioWeights {
    dates: string[],
    portfolioName: string,
    symbols: string[],
    weights: number[][],
}

interface RealTimeStatusRequest {
    email: string
}

interface RealTimeStatus {
    flags: object,
    products: object,
    latestPrices: object,
}

interface ResourcesRequest {
    resourceType?: string,
}

interface Resource {
    name: string,
    path: string,
    description?: string,
    resourceType: string,
}

interface StockSplitRequest {
    symbol: string,
}

interface StockSplit {
    symbol: string,
    splitDate: string,
    oldShares: number,
    newShares: number,
}

interface StrategyDataRequest {
    name: string,
    product: string,
    symbol: string,
    trade: string,
}

interface Flag {
    flag: string,
    endFlag: string,
    symbol: string,
    price: number,
    dts: string,
    endPrice: number,
    endTime: string,
}

export interface StrategyData {
    flags: Flag[]
    metrics: any
}

export interface ComponentStatus {
    component: string,
    status: string,
    message: string
    lastProbeTime: string,
    lastTransitionTime: string,
}

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: async (headers) => {
            let count = 0;
            let authorized = false;
            while (!authorized && count < 5) {
                try {
                    authorized = authService.isAuthorised();
                    if (!authorized) {
                        count += 1;
                        await new Promise(r => setTimeout(r, 1000))
                    }
                } catch (_) {
                    log.error('prepareHeaders: failed to refresh tokens');
                    return headers;
                }
            }

            const token = await authService.asyncToken();
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllSubscriptions: builder.query<any, AllSubscriptionsRequest>({
            query: ({email}) => {
                const encodedEmail = encodeURIComponent(email)
                const url = `${ApiBaseUrl}/v1/reference/GetSubscriptions?User=${encodedEmail}&AlertType=ALL`;
                return {url}
            },
            transformResponse: (response: any) => {
                let responseData: any[] = [];
                for (const [product, subscriptions] of Object.entries(response['subscriptions'] as Object)) {
                    const productName = String(product).toUpperCase();
                    try {
                        responseData.push({[productName]: subscriptions})
                    } catch (e) {
                        log.error("subscription error for ", productName, e);
                        responseData.push(undefined)
                    }
                }
                return responseData;
            }
        }),
        getAvailableSubscriptions: builder.query<AvailableSubscription[], any>({
            query: () => {
                const url = `${ApiBaseUrl}/v1/reference/GetAvailableSubscriptions`;
                return {url}
            },
            transformResponse: (response: any[]) => {
                return response;
            },
        }),
        getCompositeHistoricalData: builder.query<HistoricalData[], CompositeHistoricalDataRequest>({
            query: ({portfolioName, symbol, startTime, endTime}) => {
                const encodedSymbol = encodeURIComponent(symbol);
                const encodedPortfolioName = encodeURIComponent(portfolioName);
                let params = "&Identifier=" + encodedSymbol + "&Portfolio=" + encodedPortfolioName;
                if (startTime) {
                    params = `${params}&StartTime=${startTime}`
                }
                if (endTime) {
                    params = `${params}&EndTime=${endTime}`
                }
                const url = `${ApiBaseUrl}/v1/GetCompositeRecords?IdentifierType=Symbol&Format=json${params}`;
                return {url}
            },
            transformResponse: (response, _, {product}) => {
                return DataProcessors['historical'](response, product)
            },

        }),
        getCompositeSubscriptions: builder.query<any, CompositeSubscriptionsRequest>({
            query: ({email, alertType}) => {
                if (email !== '') {
                    const encodedEmail = encodeURIComponent(email);
                    const url = `${ApiBaseUrl}/v1/reference/GetCompositeSubscriptions?User=${encodedEmail}&AlertType=${alertType}`;
                    return {url}
                } else {
                    return {url: ''}
                }
            },
            transformResponse: (response) => {
                return DataProcessors['portfolioSubscriptions'](response);
            },
        }),
        getHistoricalData: builder.query<HistoricalData[], HistoricalDataRequest>({
            query: ({product, symbol, startTime, endTime}) => {
                const productApiName = productNamespace[product]['realTime'];
                const encodedSymbol = encodeURIComponent(symbol);
                let params = "&AlertType=" + productApiName + "&Identifier=" + encodedSymbol;
                if (startTime) {
                    params = `${params}&StartTime=${startTime}`
                }
                if (endTime) {
                    params = `${params}&EndTime=${endTime}`
                }
                const url = `${ApiBaseUrl}/v1/GetRecords?IdentifierType=Symbol&Format=json${params}`;
                return {url}
            },
            transformResponse: (response, _, {product}) => {
                return DataProcessors['historical'](response, product)
            },
        }),
        getPortfolioSubscriptions: builder.query<any, PortfolioSubscriptionsRequest>({
            query: ({email}) => {
                if (email !== '') {
                    const encodedEmail = encodeURIComponent(email);
                    const url = `${ApiBaseUrl}/v1/reference/GetPortfolioSubscriptions?User=${encodedEmail}`;
                    return {url}
                } else {
                    return {url: ''}
                }
            },
            transformResponse: (response) => {
                return DataProcessors['portfolioSubscriptions'](response);
            },
        }),
        getPortfolioWeightsByName: builder.query<PortfolioWeights, PortfolioWeightsRequest>({
            query: ({portfolio, user}) => ({
                url: `${ApiBaseUrl}/v1/reference/GetPortfolioWeights?User=${user}&Portfolio=${portfolio}`,
            }),
            transformResponse: (response: { weights: PortfolioWeights }) => {
                return response.weights;
            },
        }),
        getCompositeRealTimeStatus: builder.query<RealTimeStatus, RealTimeStatusRequest>({
            query: ({email}) => {
                if (email !== '') {
                    const encodedEmail = encodeURIComponent(email)
                    const url = `${ApiBaseUrl}/v1/GetCompositeRealTimeStatus?Email=${encodedEmail}&AlertType=ALL`;
                    return {url}
                } else {
                    return {url: ''}
                }
            },
            transformResponse: (response) => {
                return DataProcessors['realTimeAll'](response);
            }
        }),
        getRealTimeStatus: builder.query<RealTimeStatus, RealTimeStatusRequest>({
            query: ({email}) => {
                const encodedEmail = encodeURIComponent(email)
                const url = `${ApiBaseUrl}/v1/GetRealTimeStatus?Email=${encodedEmail}&AlertType=ALL`;
                return {url}
            },
            transformResponse: (response) => {
                return DataProcessors['realTimeAll'](response);
            }
        }),
        getResources: builder.query<Resource[], ResourcesRequest>({
            query: ({resourceType}) => {
                if (resourceType) {
                    const url = `${ApiBaseUrl}/v1/reference/GetResources?Pretty=true&ResourceType=${resourceType}`;
                    return {url}
                } else {
                    const url = `${ApiBaseUrl}/v1/reference/GetResources`;
                    return {url}
                }
            },
            transformResponse: (response) => {
                return DataProcessors['resources'](response);
            }
        }),
        getStrategyData: builder.query<StrategyData, StrategyDataRequest>({
            query: ({product, symbol, name, trade}) => {
                const encodedSymbol = encodeURIComponent(symbol);
                const url = `${ApiBaseUrl}/v1/MarketContext/strategy/metrics?name=${name}&symbol=${encodedSymbol}&trade=${trade}&product=${product}`;
                return {url}
            },
            transformResponse: (response: object | null) => {
                let flags = [];
                let metrics = {};
                if (response) {
                    Object.keys(response).forEach((key) => {
                        if (key === 'csv_data') {
                            flags = response[key].map((input) => {
                                const {dts, "endTime": end_time, endPrice} = input;

                                const [dts_formatted, endTime_formatted] = [dts, end_time].map((str_time) => {

                                    if (str_time == null || str_time === "") return "Open"

                                    const remove_quote = (str_time as string).replace("'", "");
                                    return unixConverterYear_strategy(remove_quote);
                                })

                                const status = endTime_formatted === "Open" ? "Open" : "Closed";
                                const endPriceStatus = endTime_formatted === "Open" ? "Open" : endPrice;

                                return {
                                    ...input, ...{
                                        "dts": dts_formatted,
                                        "endTime": endTime_formatted,
                                        "status": status,
                                        "endPrice": endPriceStatus
                                    }
                                }
                            }).sort((a, b) =>
                                (unixConverterEncode(b['dts']) - unixConverterEncode(a['dts']))
                            );
                        } else {
                            metrics[key] = response[key];
                        }
                    });
                } else {
                    metrics['all'] = {};
                }
                return {flags, metrics}
            },
        }),
        getStockSplit: builder.query<StockSplit[], StockSplitRequest>({
            query: ({symbol}) => {
                const encodedSymbol = encodeURIComponent(symbol);
                const url = `${ApiBaseUrl}/v1/reference/GetStockSplits?Symbol=${encodedSymbol}`;
                return {url}
            },
            transformResponse: (response: any[] | null) => {
                if (response) {
                    return response.map((item) => ({
                        symbol: item.symbol,
                        splitDate: item.split_date,
                        oldShares: item.old_shares,
                        newShares: item.new_shares,
                    }));
                } else {
                    return []
                }
            },
        }),
        getSystemStatus: builder.query<ComponentStatus, any>({
            query: () => `${ApiBaseUrl}/v1/SystemStatus`
        })
    })
});

export const {
    useGetAllSubscriptionsQuery, useGetAvailableSubscriptionsQuery,
    useGetCompositeRealTimeStatusQuery, useGetCompositeHistoricalDataQuery, useGetCompositeSubscriptionsQuery,
    useGetHistoricalDataQuery, useGetPortfolioSubscriptionsQuery, useGetPortfolioWeightsByNameQuery,
    useGetRealTimeStatusQuery, useGetResourcesQuery, useGetStockSplitQuery, useGetStrategyDataQuery,
    useGetSystemStatusQuery,
} = api;