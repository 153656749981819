import {Fragment, useState} from 'react';
import {Theme} from '@mui/material/styles';
import {makeStyles} from 'tss-react/mui';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery} from '@mui/material';
import PageContentPortfolioWeights from './PageContentPortfolioWeights';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {useTypedSelector} from '../../../../reducers/hooks/hooks';
import {selectPortfolioSubscriptions} from '../../../../reducers/features/portfolios/portfolioSubscriptionsSlice';

const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(1),
    },
    itemStyle: {
        backgroundColor: '#E5E7EC',
        marginTop: 0,
        paddingTop: 0,
        height: '100%'
    },
    table: {
        width: "100%",
        backgroundColor: "white"
    },
    tableHead: {
        backgroundColor: 'rgba(60, 77, 94,0.5)',
        color: 'black'
    },
    tableHeaderCell: {
        color: "black",

    },

}));

const PortfolioData = () => {
    const {classes} = useStyles();

    const [expanded, setExpanded] = useState(-1);

    const toggle = (i) => {
        if (expanded === i) {
            setExpanded(-1);
        } else {
            setExpanded(i);
        }
    };

    const subscriptions = useTypedSelector(selectPortfolioSubscriptions);
    const names = Object.keys(subscriptions).sort();
    return (
        <Fragment>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableHead}>
                        {['Name', 'Description', 'Instruments', 'Weights'].map((v, i) => (
                            <TableCell className={classes.tableHeaderCell} key={i}>{v}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {names.map((name, i) => (
                        <Fragment key={i}>
                            <TableRow key={name}>
                                <TableCell>{name}</TableCell>
                                <TableCell>{subscriptions[name]['description']}</TableCell>
                                <TableCell>{subscriptions[name]['symbols'].join(", ")}</TableCell>
                                <TableCell onClick={(_e) => toggle(i)}>{expanded === i ? <ExpandLess/> :
                                    <ExpandMore/>}</TableCell>
                            </TableRow>
                            {expanded === i ? (
                                <TableRow key={`${name}-weight-${i}`}>
                                    <TableCell colSpan={4}><PageContentPortfolioWeights portfolio={name}/></TableCell>
                                </TableRow>) : null}
                        </Fragment>
                    ))
                    }
                </TableBody>
            </Table>
        </Fragment>
    )
}

const PageContentPortfolios = () => {
    const {classes} = useStyles();

    const mobileTablet = useMediaQuery('(max-width: 900px)');

    const spacing = mobileTablet ? 1 : 2;
    return (
        <Grid container spacing={spacing}>
            <Grid item md={1}/>
            <Grid item xs={12} md={10} className={classes.itemStyle}>
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        <PortfolioData/>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item sm={1}/>
        </Grid>
    )
}

export default PageContentPortfolios;
