import {FC} from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'

interface HighLightCodeProps {
    language?: string,
    code?: string
}

const codeContent = `
import requests
import json
import os
from datetime import datetime

api_address = "https://api.algodynamix.com"
email = os.environ.get("EMAIL")
password = os.environ.get("PASSWORD")

"""

@warning - client secret below, restricted access, do not share. Add as env variable.

client_secret = os.environ.get("CLIENT_SECRET")

"""
client_secret = "40b72f1b-f987-4bcc-adf1-ff379e81fa5c"


def get_access_token():

    url = "https://auth.algodynamix.com/auth/realms/customer/protocol/openid-connect/token"

    params = {
        "client_id": "api",
        "client_secret": client_secret,
        "username": email,
        "password": password,
        "grant_type": "password"
    }

    headers = {
        "Content-Type": "application/x-www-form-urlencoded"
    }

    """
    Summary: 
        200 response from url.

    Returns:
        json with following type:

        {
            "access_token" : "",
            "expires_in":300,
            "refresh_expires_in":1800,
            "refresh_token":"",
            "token_type":"bearer",
            "not-before-policy":0,
            "session_state":""
        }
    """

    response = requests.post(url, data=params, headers=headers)

    if response.status_code == 200:
        data = response.json()
        token = data["access_token"]
        refresh_token = data["refresh_token"]
        return token, refresh_token
    else:
        return None, None


def get_token_from_refresh(refresh_token):

    url = "https://auth.algodynamix.com/auth/realms/customer/protocol/openid-connect/token"

    params = {
        "client_id": "api",
        "client_secret": client_secret,
        "refresh_token": refresh_token,
        "grant_type": "refresh_token"
    }

    headers = {
        "Content-Type": "application/x-www-form-urlencoded"
    }

    response = requests.post(url, data=params, headers=headers)

    if response.status_code == 200:
        data = response.json()
        token = data["access_token"]
        return token
    else:
        return None

    pass


def get_api_data(token: str, alert_type: str = "ALL"):
    """Get realtime status current flags

    Args:
        token (str): access token
        alert_type (str, optional): ["PIFX", "RAPFX", "RAPEQ", "PIEQ"]. Defaults to "ALL".

    Returns:
        [type]: None or json following format

        {
            "flags : {
                 "PiFx": {
                    "instruments": {
                        "XETUSD": {
                            "symbol": "XETUSD",
                            "description": "",
                            "segments": [
                                {
                                    "startFlags": [
                                        {
                                            "direction": "Up",
                                            "ts": 1638518400000,
                                            "price": 4573.13
                                        },
                                        {
                                            "direction": "Up",
                                            "ts": 1638522000000,
                                            "price": 4563.24
                                        }
                                    ],
                                    "endFlag": {
                                        "direction": "End",
                                        "ts": 1638590400000,
                                        "price": 4059.7
                                    }
                                }
                            ]
                        },
                    }
                }
            },
            "products" : {
                "PiFx": {
                    "name": "PiFx",
                    "abbreviation": "PIFX"
                },
            },
            "latestPrices" : {
                "XETUSD": {
                    "price": 3977.59,
                    "ts": 1639402860000
                },
            }

        }
    """

    url = f"{api_address}/v1/GetRealTimeStatus?Email={email}&AlertType={alert_type}"

    headers = {
        "Authorization": f"Bearer {token}",
        "Accept": "application/json"
    }

    response = requests.get(url, headers=headers)

    if response.status_code == 200:
        return response.json()
    else:
        return None


def get_realtime_composite_flags(token: str, alert_type: str = "ALL"):
    """Get realtime composite flags

    Args:
        token (str): access token
        alert_type (str, optional): Defaults to "ALL".

    Returns:
        [type]: None or json following format

        {
            "flags : {
                "Pi": {
                    "portfolio1": {
                        "instruments": {
                            "XETUSD": {
                                "symbol": "XETUSD",
                                "description": "",
                                "segments": [
                                    {
                                        "startFlags": [
                                            {
                                                "direction": "Up",
                                                "ts": 1638518400000,
                                                "price": 4573.13
                                            },
                                            {
                                                "direction": "Up",
                                                "ts": 1638522000000,
                                                "price": 4563.24
                                            }
                                        ],
                                        "endFlag": {
                                            "direction": "End",
                                            "ts": 1638590400000,
                                            "price": 4059.7
                                        }
                                    }
                                ]
                            },
                        }
                    },
                    "portfolioName": "portfolio-1",
                    "portfolioid": 1,
                    "portfolioDescription": "Composite flag portfolio",
                    "product": "PiEq"
                }
            },
            "products" : {
                "PiFx": {
                    "name": "PiFx",
                    "abbreviation": "PIFX"
                },
            },
            "latestPrices" : {
                "XETUSD": {
                    "price": 3977.59,
                    "ts": 1639402860000
                },
            }

        }
    """

    url = f"{api_address}/v1/GetCompositeRealTimeStatus?Email={email}&AlertType=ALL"

    headers = {
        "Authorization": f"Bearer {token}",
        "Accept": "application/json"
    }

    response = requests.get(url, headers=headers)

    if response.status_code == 200:
        return response.json()
    else:
        return None

def get_historical_composite_flags(token: str, portfolio: str, symbol: str, start_time: str, end_time: str):
    """Get historical composite flags for the given portfolio and symbol

    Args:
        token (str): access token
        portfolio (str): the name of the portfolio
        symbol (str): the symbol of the instrument

    Returns:
        [type]: None or json following format

        [{
            "algorithmSpecId": 3044, 
            "dts": 1529348640000, 
            "flag": "Up", 
            "price": 6720.0, 
            "symbol": "BTC"
         }, ... ]}
    """
    url = f"{api_address}/v1/GetCompositeRecords?IdentifierType=Symbol&Identifier={symbol}&Portfolio=crypto-15&Format=json"
    url = url + f"&StartTime={start_time}&EndTime={end_time}"

    headers = {
        "Authorization": f"Bearer {token}",
        "Accept": "application/json"
    }

    response = requests.get(url, headers=headers)

    if response.status_code == 200:
        return response.json()
    else:
        return None


def get_historical_flags(token: str, product: str, symbol: str, start_time: str, end_time: str):
    """Get historical flags for the given product and symbol

    Args:
        token (str): access token
        product (str): ["PIFX", "RAPFX", "RAPEQ", "PIEQ"]. 
        symbol (str): the symbol of the instrument

    Returns:
        [type]: None or json following format

        [{
            "algorithmSpecId": 3044, 
            "dts": 1529348640000, 
            "flag": "Up", 
            "price": 6720.0, 
            "symbol": "BTC"
         }, ... ]}
    """

    url = f"{api_address}/v1/pi/GetRecords?IdentifierType=Symbol&Format=json"
    url = url + f"&AlertType={product}&Identifier={symbol}&StartTime={start_time}&EndTime={end_time}"

    headers = {
        "Authorization": f"Bearer {token}",
        "Accept": "application/json"
    }

    response = requests.get(url, headers=headers)

    if response.status_code == 200:
        return response.json()
    else:
        return None



def get_open_flags_per_product(product_flags):
    """Extract open flags i.e. no end flag issued

    Args:
        product_flags : Format of product flags shown below. The segments contains latest flag event.
        If flag events has no endflag key, as shown in last element of segments array below, it means
        it's an open flag event. The first element in the array. 

                {
                    "symbol": "XETUSD",
                    "description": "",
                    "segments": [
                        {
                            "startFlags": [
                                {
                                    "direction": "Up",
                                    "ts": 1638518400000,
                                    "price": 4573.13
                                },
                                {
                                    "direction": "Up",
                                    "ts": 1638522000000,
                                    "price": 4563.24
                                }
                            ],
                            "endFlag": {
                                "direction": "End",
                                "ts": 1638590400000,
                                "price": 4059.7
                            }
                        }
                    ]
                },


    Returns: only flags that are open
    """

    open_flags = []

    for symbol, data in product_flags.items():
        if len(data["segments"]) > 0 and len(data["segments"][-1]["startFlags"]) > 0:

            if "endFlag" not in data["segments"][-1]:
                open_flags.append(data)

    return open_flags

def realtime_composite_flags():

    token, refresh_token = get_access_token()

    if token is None:
        return None

    """
        Access to refresh token. Token expires after 5 mins. Refresh token token
        lasts 30mins. To get updated token get_token_from_refresh could be called
        periodically.
    """

    token_refresh = get_token_from_refresh(refresh_token)

    token = token if token_refresh is None else token_refresh

    flags = get_realtime_composite_flags(token)

    if flags is None:
        return None

    return flags

def factory_api_call():
    token, refresh_token = get_access_token()

    if token is None:
        return None

    """
        Access to refresh token. Token expires after 5 mins. Refresh token token
        lasts 30mins. To get updated token get_token_from_refresh could be called
        periodically.
    """

    token_refresh = get_token_from_refresh(refresh_token)

    token = token if token_refresh is None else token_refresh

    flags = get_api_data(token)

    if flags is None:
        return None

    """
        Summary : Example get open flags for PiFx
    """

    example_product = "PiFx"

    open_flags_for_PiFx = get_open_flags_per_product(
        flags["flags"][example_product]['instruments'])

    return flags

def historical_composite_flags():
    token, refresh_token = get_access_token()

    if token is None:
        return None

    portfolio = 'crypto-15'
    product = 'Rap'
    start_time = "2018-06-13T00:00:00Z"
    end_time = "2022-06-13T00:00:00Z"
    symbol = "XETUSD"
    flags = get_historical_composite_flags(token, portfolio, symbol, start_time, end_time)

    if flags is None:
        return None

    if "PI" in product:
        start_direction = "Up"
        end_direction = "Down"
    else:
        start_direction = "Down"
        end_direction = "Up"

    segments = []
    start_flags = []
    end_flag = None
    for f in flags:
        if f["flag"] == start_direction:
            start_flag = dict(direction=start_direction, ts=f["dts"], prices=f["price"])
            start_flags.append(start_flag)
        elif f["flag"] == end_direction:
            end_flag = dict(direction="End", ts=f["dts"], prices=f["price"])

        if end_flag is not None:
            segment = dict(startFlags=start_flags, endFlag=end_flag)
            segments.append(segment)
            end_flag = None
            start_flags = []

    instruments = dict()
    instruments[symbol] = dict(symbol=symbol, segments=segments)
    historical_flags = dict()
    historical_flags[product] = dict(instruments=instruments)

    return historical_flags

def historical_flags():
    token, refresh_token = get_access_token()

    if token is None:
        return None

    """
        Access to refresh token. Token expires after 5 mins. Refresh token token
        lasts 30mins. To get updated token get_token_from_refresh could be called
        periodically.
    """

    token_refresh = get_token_from_refresh(refresh_token)

    token = token if token_refresh is None else token_refresh

    example_product = "PiFx"
    start_time = "2018-06-13T00:00:00Z"
    end_time = "2022-06-13T00:00:00Z"
    symbol = "BTC"
    flags = get_historical_flags(token, example_product.upper(), symbol, start_time, end_time)

    if flags is None:
        return None

    if "PI" in example_product:
        start_direction = "Up"
        end_direction = "Down"
    else:
        start_direction = "Down"
        end_direction = "Up"

    segments = []
    start_flags = []
    end_flag = None
    for f in flags:
        if f["flag"] == start_direction:
            start_flag = dict(direction=start_direction, ts=f["dts"], prices=f["price"])
            start_flags.append(start_flag)
        elif f["flag"] == end_direction:
            end_flag = dict(direction="End", ts=f["dts"], prices=f["price"])

        if end_flag is not None:
            segment = dict(startFlags=start_flags, endFlag=end_flag)
            segments.append(segment)
            end_flag = None
            start_flags = []

    instruments = dict()
    instruments[symbol] = dict(symbol=symbol, segments=segments)
    historical_flags = dict()
    historical_flags[example_product] = dict(instruments=instruments)

    return historical_flags

def get_system_status():
    """Get system status

    Returns:
        [type]: json following format

        [{
            "component": "databases", 
            "status": "True", 
            "message": "Up and Running", 
            "lastProbeTime": "2023-04-21T14:42:34.327535259Z",
            "lastTransitionTime": "2023-04-21T13:30:34.073494308Z",
         }, ... ]}
    """

    token, refresh_token = get_access_token()

    if token is None:
        return None

    url = f"{api_address}/v1/SystemStatus"

    headers = {
        "Authorization": f"Bearer {token}",
        "Accept": "application/json"
    }

    response = requests.get(url, headers=headers)

    if response.status_code == 200:
        return response.json()
    else:
        return None


if __name__ == "__main__":
    flags = factory_api_call()
    hist_flags = historical_flags()

    composite_flags = realtime_composite_flags()
    hist_composite_flags = historical_composite_flags()
    system_status = get_system_status()
`


export const HightLightCode: FC<HighLightCodeProps> = (props) => {

    const {language = 'python'} = props;

    const highlightCode = hljs.highlight(codeContent, {language, ignoreIllegals: true})

    return (
        <pre>
            <code dangerouslySetInnerHTML={{__html: highlightCode.value}}/>
        </pre>
    )
}
