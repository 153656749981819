import {useReducer} from 'react';
import {makeStyles} from 'tss-react/mui';
import {Box, Button, Container, TextField, Typography} from '@mui/material';

const useStyle = () => ({
    formRoot: {
        width: "100%"
    }
});

const useStyles = makeStyles()(useStyle);

const PageContentSupport = () => {
    const {classes} = useStyles();

    const defaultBody = {
        email: '',
        comment: ''
    }

    const middleware = (state, {value, payload}) => {
        if (value !== undefined && payload !== undefined) {
            return {...state, [value]: payload}
        }
        return state
    }

    const [currentState, dispatch] = useReducer(middleware, defaultBody);

    const sendEmail = () => {
        window.location.href = `mailto:support@algodynamix.com?subject=Support - ${currentState.email} &body=${currentState.comment}`
    }

    return (
        <Container maxWidth={"lg"} style={{marginTop: '20px'}}>
            <Box>
                <Typography variant={'body1'}>
                    If you are having any difficulties using our software please do not hesitate to contact support via
                    email (support@algodynamix.com) or contact form below.
                </Typography>
                <div style={{'marginTop': '50px'}}>
                    <form action="mailto:support@algodynamix.com" method="post" className={classes.formRoot}
                          encType="text/plain" target="_top">
                        <TextField
                            id="email"
                            label="Subject"
                            variant="filled"
                            fullWidth
                            onChange={(e) => (dispatch({value: 'email', payload: e.target.value}))}
                        />
                        <TextField
                            id="comment"
                            label="Comment"
                            multiline
                            rows={10}
                            variant="filled"
                            fullWidth
                            onChange={(e) => (dispatch({value: 'comment', payload: e.target.value}))}
                        />
                        <Button onClick={sendEmail} fullWidth>
                            Send
                        </Button>
                    </form>
                </div>
            </Box>
        </Container>
    )
}

export default PageContentSupport