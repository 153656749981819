import { makeStyles } from 'tss-react/mui';
import { Box, Container, Typography } from '@mui/material'
import { HightLightCode } from './CodeHighlight';
import { ApiBaseUrl } from '../../../../App';

const useStyle = () => ({
    callout: {
        padding: '20px',
        margin: '20px 0',
        border: '1px solid #eee',
        borderLeftWidth: '5px',
        borderRadius: '3px',
    },
    codeBlock: {
        backgroundColor: 'white',
        border: '1px solid black',
        padding: '2em',
        minHeight: '50px'
    },
    codeBox: {
        borderLeftColor: '#B94A48',
        marginBottom: '3em',
    },
    codeHeading: {
        color: '#B94A48',
    },
    infoBox: {
        borderLeftColor: '#5bc0de',
    }
})

const subscriptionRtn = {
    "subscriptions": [{
        "symbol": "ES",
        "alertType": "PIL",
        "channel": "default",
        "classifications": [
            "Equity Index Future"
        ],
        "description": "S&P 500 INDEX DECEMBER 2016",
        "exchange": "CME"
    }],
    "user": {
        "email": "john.doe@company.com",
        "fullName": "John Doe",
        "lastName": "Doe",
        "firstName": "John",
        "activeFrom": 1442016000000,
        "active": true,
        "id": {
            "id": 1234
        }
    }
};

const realTimeComposite = {
    "flags": {
        "Pi": {
            "portfolio-1": {
                "instruments": {
                    "AEX": {
                        "symbol": "AEX",
                        "description": "AEX INDEX NOVEMBER 2021",
                        "composite": true,
                        "segments": [
                            {
                                "startFlags": [
                                    {
                                        "direction": "Up",
                                        "ts": 1638820800000,
                                        "price": 781.0
                                    }
                                ],
                                "endFlag": {
                                    "direction": "End",
                                    "ts": 1638990000000,
                                    "price": 799.1
                                }
                            }
                        ]
                    },
                }
            },
            "portfolioName": "portfolio-1",
            "portfolioId": 1,
            "portfolioDescription": "Composite flag portfolio",
            "product": "PiEq"
        }
    },
    "products": {
        "PiEq": {
            "name": "PiEq",
            "abbreviation": "PIEQ"
        },
    },
    "latestPrices": {
        "AEX": {
            "price": 790.05,
            "ts": 1639401960000
        },
    }
}

const realTimeRust = {
    "flags": {
        "PiEq": {
            "instruments": {
                "AEX": {
                    "symbol": "AEX",
                    "description": "AEX INDEX NOVEMBER 2021",
                    "segments": [
                        {
                            "startFlags": [
                                {
                                    "direction": "Up",
                                    "ts": 1638820800000,
                                    "price": 781.0
                                }
                            ],
                            "endFlag": {
                                "direction": "End",
                                "ts": 1638990000000,
                                "price": 799.1
                            }
                        }
                    ]
                },
            }
        }
    },
    "products": {
        "PiEq": {
            "name": "PiEq",
            "abbreviation": "PIEQ"
        },
    },
    "latestPrices": {
        "AEX": {
            "price": 790.05,
            "ts": 1639401960000
        },
    }
}

const historicalFlags = {
    "PiFx": {
        "instruments": {
            "BTC": {
                "symbol": "BTC",
                "segments": [
                    {
                        "startFlags": [],
                        "endFlag": {
                            "direction": "End",
                            "ts": 1529348640000,
                            "prices": 6720
                        }
                    },
                    {
                        "startFlags": [
                            {
                                "direction": "Down",
                                "ts": 1529439000000,
                                "prices": 6730
                            }
                        ],
                        "endFlag": {
                            "direction": "End",
                            "ts": 1531087260000,
                            "prices": 6745
                        }
                    }]
            }
        }
    }
};

const systemStatus = [
    {
        "component": "databases",
        "status": "True",
        "message": "Up and Running",
        "lastProbeTime": "2021-01-01T10:00:00Z",
        "lastTransitionTime": "2021-01-01T00:00:00Z",
    }
]

const useStyles = makeStyles()(useStyle);

const PageContentAPI = () => {
    const { classes } = useStyles();

    const baseUrl = ApiBaseUrl;

    return (
        <Container maxWidth={"lg"} style={{ marginTop: '20px' }}>

            <Box className={`${classes.callout} ${classes.infoBox}`}>
                <Typography variant={"h4"} component={"h2"}>
                    AlgoDynamix API
                </Typography>
                <p>AlgoDynamix offers REST API for users to query their active subscriptions and the alert status in
                    realtime.
                    In order to use AlgoDynamix API, the user must be registered and have active subscriptions
                    via either SaaS license or a PoC evaluation license.</p>
                <p>The AlgoDynamix API endpoint is located at <a href="{baseUrl}/v1/">{baseUrl}/v1/</a>. The API calls
                    and responses are documented below.
                    Currently, the REST API endpoint is protected by https and HTTP Basic Authentication.
                    AlgoDynamix can also provide token authentication, private cloud hosting and on premise
                    installations if required.</p>
                <p>The example usages are illustrated via curl and the API call returns data in Json format.</p>
            </Box>

            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Get PI™ subscription details - /reference/GetSubscriptions?User=<strong>user_email</strong>&AlertType=PIL
                </Typography>
                <p>Get current active subscription detail for user. This will return a list of active subscriptions and
                    other details for the user.</p>
                <p><strong>API call</strong>:</p>
                <pre className={classes.codeBlock}>
                    curl -u $EMAIL:$PASSWORD "{baseUrl}/v1/reference/GetSubscriptions?User=$EMAIL&AlertType=PIL"
                </pre>
                <p>Response: (pipe it via | python -m json.tool for display formatting purposes)</p>
                <pre className={classes.codeBlock}>
                    {JSON.stringify(subscriptionRtn, null, 2)}
                </pre>
                <p>Response Json:</p>
                <dl>
                    <dt>subscriptions</dt>
                    <dd>The list of active subscriptions for the user.</dd>
                    <dt>symbol</dt>
                    <dd>Instrument symbol/identifier.</dd>
                    <dt>alertType</dt>
                    <dd>The type of Flag for this instrument.</dd>
                    <dt>channel</dt>
                    <dd>The channel where the alert will be distributed. 'default' - web, excel and api, 'email' - email
                        alert.
                    </dd>
                    <dt>classifications</dt>
                    <dd>Classification/type of instrument/asset class.</dd>
                    <dt>description</dt>
                    <dd>Instrument description.</dd>
                    <dt>exchange</dt>
                    <dd>The exchange (or primary exchange/trading venue).</dd>
                    <dt>user</dt>
                    <dd>The profile of the user.</dd>
                    <dt>firstName</dt>
                    <dd>The first name of user.</dd>
                    <dt>lastName</dt>
                    <dd>The last name of user.</dd>
                    <dt>email</dt>
                    <dd>Email address of user.</dd>
                    <dt>active</dt>
                    <dd>Subscription status of user.</dd>
                    <dt>activeFrom</dt>
                    <dd>The UTC timestamp denoting start date of user subscription (milliseconds since epoch).</dd>
                    <dt>activeTo</dt>
                    <dd>The UTC timestamp after which the user will become inactive (milliseconds since epoch). If not
                        present, the user remains active indefinitely.
                    </dd>
                    <dt>id</dt>
                    <dd>The internal user id.</dd>
                </dl>
            </Box>

            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Get authentication token
                    https://auth.algodynamix.com/auth/realms/customer/protocol/openid-connect/token
                </Typography>
                <pre className={classes.codeBlock}>
                    curl -s --header 'Content-Type: application/x-www-form-urlencoded' -d 'client_id=api' <br />
                    &nbsp; -d 'client_secret=$CLIENT_SECRET' -d "username=$EMAIL" -d "password=$PASSWORD" -d "grant_type=password"<br />
                    &nbsp; 'https://auth.algodynamix.com/auth/realms/customer/protocol/openid-connect/token'
                </pre>
            </Box>

            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Get refresh token https://auth.algodynamix.com/auth/realms/customer/protocol/openid-connect/token
                </Typography>
                <pre className={classes.codeBlock}>
                    curl -s --header 'Content-Type: application/x-www-form-urlencoded' -d 'client_id=api' <br />
                    &nbsp; -d 'client_secret=<strong>$CLIENT_SECRET</strong>' -d "grant_type=refresh_token" -d "refresh_token=<strong>$REFRESH_TOKEN</strong>" <br />
                    &nbsp; 'https://auth.algodynamix.com/auth/realms/customer/protocol/openid-connect/token'
                </pre>
            </Box>

            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Get real time flags - /v1/GetRealTimeStatus?Email=$EMAIL&AlertType=ALL
                </Typography>
                <p>Get real time flags for user. This will return a list of real time flags according to the user's
                    current active subscriptions.</p>
                <p><strong>API call</strong>:</p>
                <pre className={classes.codeBlock}>
                    curl -vs -H "Authorization: Bearer <strong>$TOKEN</strong>" -H "Accept: application/json" <br />
                    &nbsp; "<strong>$API_URL</strong>/v1/GetRealTimeStatus?Email=<strong>$EMAIL</strong>&AlertType=ALL"
                </pre>
                <p>Response: (pipe it via | python -m json.tool for display formatting purposes)</p>
                <pre className={classes.codeBlock}>
                    {JSON.stringify(realTimeRust, null, 2)}
                </pre>
                <dl>
                    <dt>flags</dt>
                    <dd>The flags, keyed by the product name.
                        <dl>
                            <dt>PiEq</dt>
                            <dd>The flags for the product <strong>PiEq</strong>.
                                <dl>
                                    <dt>instruments</dt>
                                    <dd>The <strong>PiEq</strong> flags, keyed by instrument name.
                                        <dl>
                                            <dt>AEX</dt>
                                            <dd>The flags for the instrument <strong>AEX</strong>.
                                                <dl>
                                                    <dt>symbol</dt>
                                                    <dd>The name of the instrument.</dd>
                                                    <dt>description</dt>
                                                    <dd>The description of the instrument/contract.</dd>
                                                    <dt>segments</dt>
                                                    <dd>An array of segments. Each segment contains
                                                        one or more <strong>startFlags</strong> and an
                                                        optional <strong>endFlag</strong>.
                                                        <dl>
                                                            <dt>direction</dt>
                                                            <dd>The direction of the flag.</dd>
                                                            <dt>ts</dt>
                                                            <dd>The UTC timestamp of the flag.</dd>
                                                            <dt>price</dt>
                                                            <dd>The indicative price of the instrument when the flag was
                                                                issued.
                                                            </dd>
                                                        </dl></dd>
                                                </dl>
                                            </dd>
                                        </dl>
                                    </dd>

                                </dl>
                            </dd>
                        </dl>
                    </dd>
                    <dt>products</dt>
                    <dd>The products, keyed by the product name.
                        <dl>
                            <dt>PiEq</dt>
                            <dd>The name and abbreviation of the product <strong>PiEq</strong>.
                                <dl>
                                    <dt>name</dt>
                                    <dd>The name of the product.</dd>
                                    <dt>abbreviation</dt>
                                    <dd>The abbreviation of the product</dd>
                                </dl></dd>
                        </dl>
                    </dd>
                    <dt>latestPrices</dt>
                    <dd>The latest prices for the instruments, keyed by the instrument name.
                        <dl>
                            <dt>AEX</dt>
                            <dd>The price and timestamp for the instrument <strong>AEX</strong>.
                                <dl>
                                    <dt>price</dt>
                                    <dd>The price of the instrument.</dd>
                                    <dt>ts</dt>
                                    <dd>The UTC timestamp of the price.</dd>
                                </dl>
                            </dd>
                        </dl>
                    </dd>
                </dl>

            </Box>

            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Get Historical flags - /v1/GetRecords?IdentifierType=Symbol&AlertType=PIFX&Identifier=BTC&StartTime=2022-01-01T10:00:00Z&EndTime=2022-01-10T10:10:00Z
                </Typography>
                <p>Get historical flags for user. This will return a list of historical flags for the given product and
                    time range</p>
                <p><strong>API call</strong>:</p>
                <pre className={classes.codeBlock}>
                    curl -vs -H "Authorization: Bearer <strong>$TOKEN</strong>" -H "Accept: application/json" <br />
                    &nbsp; "<strong>$API_URL</strong>/v1/pi/GetRecords?IdentifierType=Symbol&Format=json \<br />
                    &nbsp; &AlertType=<strong>$PRODUCT</strong>&Identifier=<strong>$SYMBOL</strong>&StartTime=<strong>2022-01-01T10:00:00Z</strong>&EndTime=<strong>2022-01-10T10:00:00Z</strong>"
                </pre>
                <p>Response: (pipe it via | python -m json.tool for display formatting purposes)</p>
                <pre className={classes.codeBlock}>
                    {JSON.stringify(historicalFlags, null, 2)}
                </pre>
                <dl>
                    <dt>PiFx</dt>
                    <dd>The flags for the product <strong>PiFx</strong>.
                        <dl>
                            <dt>instruments</dt>
                            <dd>The <strong>PiFx</strong> flags, keyed by instrument name.
                                <dl>
                                    <dt>BTC</dt>
                                    <dd>The flags for the instrument <strong>BTC</strong>.
                                        <dl>
                                            <dt>symbol</dt>
                                            <dd>The name of the instrument.</dd>
                                            <dt>description</dt>
                                            <dd>The description of the instrument/contract.</dd>
                                            <dt>segments</dt>
                                            <dd>An array of segments. Each segment contains
                                                one or more <strong>startFlags</strong> and an
                                                optional <strong>endFlag</strong>.
                                                <dl>
                                                    <dt>direction</dt>
                                                    <dd>The direction of the flag.</dd>
                                                    <dt>dts</dt>
                                                    <dd>The UTC timestamp of the flag.</dd>
                                                    <dt>price</dt>
                                                    <dd>The indicative price of the instrument when the flag was
                                                        issued.
                                                    </dd>
                                                </dl></dd>
                                        </dl>
                                    </dd>
                                </dl>
                            </dd>

                        </dl>
                    </dd>
                </dl>
            </Box>


            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Get real time composite flags - /v1/GetCompositeRealTimeStatus?Email=$EMAIL&AlertType=ALL
                </Typography>
                <p>Get real time composite flags for user. This will return a list of real time composite flags according to the user's
                    current active subscriptions.</p>
                <p><strong>API call</strong>:</p>
                <pre className={classes.codeBlock}>
                    curl -vs -H "Authorization: Bearer <strong>$TOKEN</strong>" -H "Accept: application/json" <br />
                    &nbsp; "<strong>$API_URL</strong>/v1/GetCompositeRealTimeStatus?Email=<strong>$EMAIL</strong>&AlertType=ALL"
                </pre>
                <p>Response: (pipe it via | python -m json.tool for display formatting purposes)</p>
                <pre className={classes.codeBlock}>
                    {JSON.stringify(realTimeComposite, null, 2)}
                </pre>
                <dl>
                    <dt>flags</dt>
                    <dd>The flags, keyed by the product name.
                        <dl>
                            <dt>Pi</dt>
                            <dd>The flags for the product <strong>Pi</strong>.
                                <dl>
                                    <dt>portfolio-1</dt>
                                    <dd>The name of the portfolio
                                        <dl>
                                            <dt>instruments</dt>
                                            <dd>The <strong>Pi composite</strong> flags, keyed by instrument name.
                                                <dl>
                                                    <dt>AEX</dt>
                                                    <dd>The flags for the instrument <strong>AEX</strong>.
                                                        <dl>
                                                            <dt>symbol</dt>
                                                            <dd>The name of the instrument.</dd>
                                                            <dt>description</dt>
                                                            <dd>The description of the instrument/contract.</dd>
                                                            <dt>composite</dt>
                                                            <dd><strong>true</strong> - this instrument has composite flag.<br /><strong>false</strong> - this instrument is used to generate composite flags but itself has no composite flag .</dd>
                                                            <dt>segments</dt>
                                                            <dd>An array of segments. Each segment contains
                                                                one or more <strong>startFlags</strong> and an
                                                                optional <strong>endFlag</strong>.
                                                                <dl>
                                                                    <dt>direction</dt>
                                                                    <dd>The direction of the flag.</dd>
                                                                    <dt>ts</dt>
                                                                    <dd>The UTC timestamp of the flag.</dd>
                                                                    <dt>price</dt>
                                                                    <dd>The indicative price of the instrument when the flag was
                                                                        issued.
                                                                    </dd>
                                                                </dl></dd>
                                                        </dl>
                                                    </dd>
                                                </dl>
                                            </dd>

                                        </dl>
                                    </dd>
                                </dl>
                            </dd>
                        </dl>
                    </dd>
                    <dt>products</dt>
                    <dd>The products, keyed by the product name.
                        <dl>
                            <dt>PiEq</dt>
                            <dd>The name and abbreviation of the product <strong>PiEq</strong>.
                                <dl>
                                    <dt>name</dt>
                                    <dd>The name of the product.</dd>
                                    <dt>abbreviation</dt>
                                    <dd>The abbreviation of the product</dd>
                                </dl></dd>
                        </dl>
                    </dd>
                    <dt>latestPrices</dt>
                    <dd>The latest prices for the instruments, keyed by the instrument name.
                        <dl>
                            <dt>AEX</dt>
                            <dd>The price and timestamp for the instrument <strong>AEX</strong>.
                                <dl>
                                    <dt>price</dt>
                                    <dd>The price of the instrument.</dd>
                                    <dt>ts</dt>
                                    <dd>The UTC timestamp of the price.</dd>
                                </dl>
                            </dd>
                        </dl>
                    </dd>
                </dl>

            </Box>

            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Get Historical composite flags - /v1/GetCompositeRecords?IdentifierType=Symbol&Portfolio=portfolio-1&Identifier=BTC&StartTime=2022-01-01T10:00:00Z&EndTime=2022-01-10T10:10:00Z
                </Typography>
                <p>Get historical composite flags for user. This will return a list of historical flags for the given symbol in portfolio and
                    time range</p>
                <p><strong>API call</strong>:</p>
                <pre className={classes.codeBlock}>
                    curl -vs -H "Authorization: Bearer <strong>$TOKEN</strong>" -H "Accept: application/json" <br />
                    &nbsp; "<strong>$API_URL</strong>/v1/pi/GetCompositeRecords?IdentifierType=Symbol&Format=json \<br />
                    &nbsp; &Portfolio=<strong>$PORTFOLIO</strong>&Identifier=<strong>$SYMBOL</strong>&StartTime=<strong>2022-01-01T10:00:00Z</strong>&EndTime=<strong>2022-01-10T10:00:00Z</strong>"
                </pre>
                <p>Response: (pipe it via | python -m json.tool for display formatting purposes)</p>
                <pre className={classes.codeBlock}>
                    {JSON.stringify(historicalFlags, null, 2)}
                </pre>
                <dl>
                    <dt>PiFx</dt>
                    <dd>The flags for the product <strong>PiFx</strong>.
                        <dl>
                            <dt>instruments</dt>
                            <dd>The <strong>PiFx</strong> flags, keyed by instrument name.
                                <dl>
                                    <dt>BTC</dt>
                                    <dd>The flags for the instrument <strong>BTC</strong>.
                                        <dl>
                                            <dt>symbol</dt>
                                            <dd>The name of the instrument.</dd>
                                            <dt>description</dt>
                                            <dd>The description of the instrument/contract.</dd>
                                            <dt>segments</dt>
                                            <dd>An array of segments. Each segment contains
                                                one or more <strong>startFlags</strong> and an
                                                optional <strong>endFlag</strong>.
                                                <dl>
                                                    <dt>direction</dt>
                                                    <dd>The direction of the flag.</dd>
                                                    <dt>dts</dt>
                                                    <dd>The UTC timestamp of the flag.</dd>
                                                    <dt>price</dt>
                                                    <dd>The indicative price of the instrument when the flag was
                                                        issued.
                                                    </dd>
                                                </dl></dd>
                                        </dl>
                                    </dd>
                                </dl>
                            </dd>

                        </dl>
                    </dd>
                </dl>
            </Box>

            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Get System Status - /v1/SystemStatus
                </Typography>
                <p>Get System status. This will return a list of component status</p>
                <p><strong>API call</strong>:</p>
                <pre className={classes.codeBlock}>
                    curl -vs -H "Authorization: Bearer <strong>$TOKEN</strong>" -H "Accept: application/json"
                    &nbsp; "<strong>$API_URL</strong>/v1/SystemStatus"
                </pre>
                <p>Response: (pipe it via | python -m json.tool for display formatting purposes)</p>
                <pre className={classes.codeBlock}>
                    {JSON.stringify(systemStatus, null, 2)}
                </pre>
                <dl>
                    <dt>component</dt>
                    <dd>The name of the system component.</dd>
                    <dt>status</dt>
                    <dd>The status the system component. <strong>true</strong> - component is healthy, <strong>false</strong> - component is unhealthy.</dd>
                    <dt>message</dt>
                    <dd>The descriptive status of the system component.</dd>
                    <dt>lastProbeTime</dt>
                    <dd>The time when the component was probed.</dd>
                    <dt>lastTransitionTime</dt>
                    <dd>The time when the component transition from one status to another.</dd>
                </dl>
            </Box>


            <Box className={`${classes.callout} ${classes.codeBox}`}>
                <Typography variant={"h5"} className={classes.codeHeading}>
                    Python script showing an example of making an API call
                </Typography>
                <Box className={classes.codeBlock}>
                    <HightLightCode />
                </Box>
            </Box>

        </Container>
    )
}

export default PageContentAPI;