import {Fragment, useEffect, useState} from "react";
import {Link, Outlet, useNavigate, useRoutes} from "react-router-dom"
import {Box} from '@mui/system';
import {makeStyles} from 'tss-react/mui';
import {Theme} from '@mui/material/styles';
import {
    AppBar,
    Button,
    CssBaseline,
    Divider,
    Drawer,
    Grid,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Toolbar,
    Typography,
    useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {AlertStatusHeaders} from './AlertStatus';
import Footer from './Footer';
import {useAppDispatch, useTypedSelector} from '../../reducers/hooks/hooks';
import {logout, selectCurrentUser} from '../../reducers/features/auth/authSlice';
import {
    selectPortfolioStatus,
    selectPortfolioSubscriptions,
    updateSubscriptions
} from '../../reducers/features/portfolios/portfolioSubscriptionsSlice';
import {
    selectCompositeSubscriptions,
    selectCompositeSubscriptionsStatus,
    updateCompositeSubscriptions,
} from '../../reducers/features/composites/compositeSubscriptionsSlice';
import {useGetCompositeSubscriptionsQuery, useGetPortfolioSubscriptionsQuery} from "../../services/api";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    mobileDraw: {
        '@media (min-width: 600px)': {
            width: '150px'
        }
    },
    subToolbar: {
        //backgroundColor: 'rgba(60, 77, 94,0.5)',
        //backgroundColor: '#233358',
        backgroundColor: 'rgba(81,109,145,1)',
        minHeight: '70px',
        color: 'rgb(0,0,0)',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between'
    },
    title: {
        flexGrow: 1
    },
}));

const SettingsPopover = () => {
    const dispatch = useAppDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'instruments-popover' : undefined;

    const navigate = useNavigate();

    const signOut = () => {
        dispatch(logout());
    }

    const redirectSettings = () => {
        navigate("/subscriptions");
    }

    const clearCache = () => {
        const cacheKeys = ['compositeRealTime', 'realTimeRust', 'availableInstruments', 'realTime'];

        for (const cacheKey of cacheKeys) {
            localStorage.removeItem(cacheKey)
        }
    }

    return (
        <Fragment>
            <IconButton onClick={handleClick} style={{'fontSize': '0.75rem', 'padding': '2px'}}>
                <SettingsIcon fontSize="large" style={{'fontSize': '1.2rem', 'color': 'black'}}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{'padding': '5px'}}
            >
                <Grid container>
                    <Grid item xs={12} style={{marginLeft: "23%"}}>
                        <Button color="inherit" onClick={redirectSettings}>Settings</Button>
                    </Grid>
                    <Grid item xs={12} style={{marginLeft: "23%"}}>
                        <Button color="inherit" onClick={clearCache}>Clear Cache</Button>
                    </Grid>
                    <Grid item xs={12} style={{marginLeft: "23%"}}>
                        <Button color="inherit" onClick={signOut}>Sign Out</Button>
                    </Grid>
                </Grid>

            </Popover>
        </Fragment>
    )
}

const SideBarDrawer = (props) => {
    const {hasCompositeSubscriptions, hasPortfolios} = props;
    let sideBarData = [{
        name: "Alert Status",
        link: '/alertstatus',
        icon: null
    },];

    if (hasCompositeSubscriptions) {
        sideBarData.push({
            name: "Composite Alert Status",
            link: '/compositealertstatus',
            icon: null
        })
    }

    if (hasPortfolios) {
        sideBarData.push({name: "Portfolios", link: '/portfolios', icon: null});
    }
    sideBarData = sideBarData.concat([
        {
            name: "Resources",
            link: '/resources',
            icon: null
        }, {
            name: "Api",
            link: "api",
            icon: null
        }]);

    return (
        <div>
            <Divider/>
            <List>
                {sideBarData.map((header, index) => (
                    <ListItem button key={`${index}-${header.name}`} component={Link} to={header.link}>
                        {header.icon && <ListItemIcon>{}</ListItemIcon>}
                        <ListItemText primary={header.name}/>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

const SubToolbar = () => {
    const {classes} = useStyles();
    const routes = useRoutes([
        {path: "/", element: <AlertStatusHeaders/>},
        {path: "/alertstatus", element: <AlertStatusHeaders/>},
        {path: "/compositealertstatus", element: <AlertStatusHeaders/>},
    ])

    return <Toolbar className={classes.subToolbar}>{routes}</Toolbar>;
}

const AppCanvas = () => {
    const {classes,} = useStyles();

    const mobile = useMediaQuery('(max-width:600px)');
    const [mobileOpen, setMobileOpen] = useState(false)
    const mobileIconClickHandler = () => {
        setMobileOpen(!mobileOpen)
    };

    const dispatch = useAppDispatch();

    const currentUser = useTypedSelector(selectCurrentUser);
    const email = currentUser?.email ?? '';

    const {data: compositeData, isSuccess: isCompositeSuccess} = useGetCompositeSubscriptionsQuery({
        email,
        alertType: 'ALL'
    }, {
        refetchOnMountOrArgChange: true,
    });

    const compositeSubscriptionsStatus = useTypedSelector(selectCompositeSubscriptionsStatus);
    useEffect(() => {
        if (isCompositeSuccess && compositeSubscriptionsStatus === 'idle') {
            dispatch(updateCompositeSubscriptions(compositeData.subscriptions));
        }
    }, [isCompositeSuccess, compositeData, compositeSubscriptionsStatus, dispatch]);
    const compositeSubscriptions = useTypedSelector(selectCompositeSubscriptions);
    const hasCompositeSubscriptions = isCompositeSuccess && compositeSubscriptions && Object.keys(compositeSubscriptions).length > 0;

    const {data, isSuccess} = useGetPortfolioSubscriptionsQuery({email}, {
        refetchOnMountOrArgChange: true,
    });
    const portfolioStatus = useTypedSelector(selectPortfolioStatus);
    useEffect(() => {
        if (isSuccess && portfolioStatus === 'idle') {
            dispatch(updateSubscriptions(data.subscriptions));
        }
    }, [isSuccess, data, portfolioStatus, dispatch]);
    const portfolioSubscriptions = useTypedSelector(selectPortfolioSubscriptions);
    const hasPortfolios = isSuccess && portfolioSubscriptions && Object.keys(portfolioSubscriptions).length > 0;

    return (
        <Box>
            <Box className={classes.root}>
                <CssBaseline/>
                <AppBar
                    position="fixed"
                    component="nav"
                    className={classes.appBar}
                    elevation={1}
                >
                    <Toolbar>
                        {mobile &&
                        <IconButton onClick={mobileIconClickHandler}>
                            <MenuIcon/>
                        </IconButton>
                        }
                        <Typography variant="h6" className={classes.title}>
                            AlgoDynamix
                        </Typography>
                        {!mobile &&
                        <div>
                            <Button color="inherit" to='/alertstatus' component={Link}>Alert Status</Button>
                            {hasCompositeSubscriptions &&
                            <Button color="inherit" to='/compositealertstatus' component={Link}>Composite Alert
                                Status</Button>}
                            {hasPortfolios &&
                            <Button color="inherit" to='/portfolios' component={Link}>Portfolios</Button>}
                            <Button color="inherit" to='/resources' component={Link}>Resources</Button>
                            <Button color="inherit" to='/api' component={Link}>Api</Button>
                        </div>
                        }
                        <SettingsPopover/>
                    </Toolbar>
                    <nav className={classes.mobileDraw}>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                variant="temporary"
                                anchor={'left'}
                                open={mobileOpen}
                                onClose={mobileIconClickHandler}
                                classes={{
                                    paper: classes.mobileDraw,
                                }}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                {<SideBarDrawer hasCompositeSubscriptions={hasCompositeSubscriptions}
                                                hasPortfolios={hasPortfolios}/>}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <SubToolbar/>
                </AppBar>
                <Outlet/>
            </Box>
            <Footer/>
        </Box>
    )
}

export default AppCanvas;