import Canvas from './Canvas';
import PageContentResources from './pagecontent/resources/PageContentResources';

export const AnalyticsResources = () => {
    const subHeader = [
        {'name': '', 'description': 'Webinars', 'color': 'white'},
        {'name': '', 'description': 'Workshops', 'color': 'white'},
        {'name': '', 'description': 'Research Notes', 'color': 'white'},
        {'name': '', 'description': 'Conferences', 'color': 'white'}];
    return (
        <Canvas subHeader={subHeader}>
            <PageContentResources/>
        </Canvas>
    )
}
