import {FC, Fragment, useEffect, useState} from 'react';
import {Theme} from '@mui/material/styles';
import {makeStyles} from 'tss-react/mui';
import {
    Autocomplete,
    Badge,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    IconButton,
    Link,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FlagIcon from '@mui/icons-material/Flag';
import LaunchIcon from '@mui/icons-material/Launch';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HistoricalSnapShot, {CompositeHistoricalSnapShot} from './HistoricalSnapShot';
import InfoIcon from '@mui/icons-material/Info';
import {useAppDispatch, useTypedSelector} from '../../../../reducers/hooks/hooks';
import {current_time, unixConverter} from '../../../../services/utils';
import {lastUpdate} from '../../../../reducers/features/instruments/instrumentSlice';
import ErrorHandler from '../../../common/ErrorHandler';
import Ajv from 'ajv';
import {instrumentSchema} from '../../../../models/realTimeAllSchema';
import Loading from '../../../common/Loading';
import {useGetCompositeRealTimeStatusQuery, useGetRealTimeStatusQuery, useGetSystemStatusQuery} from '../../../../services/api';
import {selectCurrentUser} from '../../../../reducers/features/auth/authSlice';
import {productNamespace} from '../../../../namespace/namespace';
import { updateSystemStatus } from '../../../../reducers/features/system/systemSlice';

const useStyles = makeStyles()((theme: Theme) => ({
    card: {
        margin: theme.spacing(2)
    },
    subCard: {
        marginTop: theme.spacing(1),
        marginLeft: "O",
        marginRight: "O"
    },
    media: {
        height: 190,
    },
    cardHeader: {
        backgroundColor: 'rgba(30, 71, 118, 1)',
        color: "white"
    },
    subCardHeader: {
        backgroundColor: theme.palette.secondary.light,
        color: "white",
        height: "40px"
    },
    redAlert: {
        borderBottomColor: "red",
        borderBottomWidth: "4px",
        borderBottomStyle: "solid",
        marginBottom: "3px",
        paddingBottom: "5px",
        '& .MuiTableCell-root': {
            fontSize: '1.01rem',
            color: "red",
        }
    },
    amberAlert: {
        color: "orange",
        borderBottomColor: "orange",
        borderBottomWidth: "4px",
        borderBottomStyle: "solid",
        marginBottom: "3px",
        paddingBottom: "5px",
        fontSize: '1.01rem',
        '& .MuiTableCell-root': {
            fontSize: '1.01rem',
            color: "orange",
        }
    },
    defaultAlert: {
        color: "black",
        marginBottom: "3px"
    },
    greyAlert: {
        "& .MuiTableCell-body": {
            color: "rgba(220,220,220,0.85)",
            marginBottom: "3px",
            paddingBottom: "5px",
            overflowX: 'hidden',
            fontSize: '0.95rem'
        }
    },
    box: {
        backgroundColor: 'rgb(255,255,255)'
    },
    tableHeader: {
        backgroundColor: 'rgba(0,0,0,0.05)'
    },
    autoComplete: {
        width: '200px',
        color: "white",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "green"
        },
        "& .MuiFormLabel-root": {
            color: "rgba(255,255,255,0.5)"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        }
    },
    popupIndicator: {
        transform: 'none'
    },
    tablePopOverRow: {
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)'
        }
    },
    productTableRow: {
        fontSize: "0.8rem",
        height: "120px"
    },
    mediaDisplay: {
        '@media (max-width: 600px)': {
            display: 'None'
        }
    },
    mediaDisplayTablet: {
        '@media (max-width: 1330px)': {
            display: 'None'
        }

    },
    innerTableCell: {
        marginTop: '0px',
        marginBottom: '0px',
        marginRight: '5px',
        paddingRight: '0px',
        '& .MuiTableCell-root': {
            padding: '13px'
        }
    },
    openFlagBadge: {
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            marginRight: '5px',
            marginTop: '5px',
            transform: 'scale(1) translate(20%, -20%)'
        }

    },
    tableCellNoWrap: {overflow: 'hidden', width: '100%', whiteSpace: 'nowrap'},
}))

export interface Flags {
    symbol: string,
    description: string,
    direction: any[],
    issued: any[],
    issuedSince: any[],
    flagPrice: any[],
    lastPrice: number,
    lastTime: string
}

export type FormatFlags = {
    formatFlags: Flags[],
    openFlagList: Flags[]
}

interface OpenFlagsPopoverProps {
    data: IInstrumentRust[],
    latestPrices: ILatestPrices,
}

interface PopOverData {
    instrument: string,
    flagDirection?: 'Up' | 'End',
    flagPrice: number,
    currentPrice: number | 'Unavailable',
    issuedSince?: number
}

const OpenFlagsPopover: FC<OpenFlagsPopoverProps> = (props) => {

    const {classes} = useStyles();

    const {data, latestPrices} = props;

    const dataRender: PopOverData[] = data.map((event) => {
        const {symbol, segments} = event;

        const {startFlags} = segments[1];

        const latestFlag = startFlags[startFlags.length - 1];

        const currentPrice = latestPrices[symbol] !== undefined ? latestPrices[symbol].price : 'Unavailable';
        const latestTime = new Date().valueOf();
        const issuedSince = parseFloat(((latestTime - latestFlag.ts) / (1000 * 60 * 60)).toFixed(1));


        return {
            instrument: symbol,
            flagPrice: latestFlag.price,
            currentPrice: currentPrice,
            issuedSince: issuedSince
        }
    }).sort((a, b) => a['instrument'].localeCompare(b['instrument']))

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Instrument</TableCell>
                    <TableCell>Flag Price</TableCell>
                    <TableCell>Current Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {dataRender.map(flag => {
                    const activeClass = activeDisplay(classes, flag.issuedSince);
                    return (
                        <TableRow className={classes.tablePopOverRow} key={flag.instrument}>
                            <TableCell className={activeClass}>{flag.instrument}</TableCell>
                            <TableCell className={activeClass}>{flag.flagPrice}</TableCell>
                            <TableCell className={activeClass}>{flag.currentPrice}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

interface CardHeaderActionProps {
    openFlagList: IInstrumentRust[],
    latestPrices: ILatestPrices,
}

const CardHeaderAction: FC<CardHeaderActionProps> = (props) => {
    const {openFlagList, latestPrices} = props;

    const {classes} = useStyles();

    const noOfOpenFlags = openFlagList.length;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const flagColor = noOfOpenFlags > 0 ? '#f50057' : 'black';
    return (
        <Badge badgeContent={noOfOpenFlags} color="secondary"
               className={noOfOpenFlags > 0 ? classes.openFlagBadge : ''}>
            <IconButton onClick={handleClick}>
                <FlagIcon style={{color: flagColor, fontSize: '2rem'}}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box>
                    <ErrorHandler defaultRender={DefaultPopOverError}>
                        <OpenFlagsPopover data={openFlagList} latestPrices={latestPrices}/>
                    </ErrorHandler>
                </Box>
            </Popover>

        </Badge>
    )

}

const DefaultPopOverError = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Instrument</TableCell>
                    <TableCell>Flag Price</TableCell>
                    <TableCell>Current Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow style={{padding: '5px'}}>
                    <Loading type='Loading Data'/>
                </TableRow>
            </TableBody>
        </Table>
    )
}


interface CardHeaderCompProps {
    formattedFlags: IInstrumentRust[],
    symbolSelect: (symbol: string, filterSelect: boolean) => void,
    product: string
}

const CardHeaderComp: FC<CardHeaderCompProps> = (props) => {

    const {classes} = useStyles();

    const {formattedFlags, symbolSelect, product} = props

    const onSymbolSelect = (symbol) => {

        if (symbol === null || symbol === undefined) {
            symbolSelect("", false);
        } else {
            symbolSelect(symbol, true);
        }
    }

    const cardOptions = formattedFlags.map(f => {
        try {
            return f.symbol
        } catch (e) {
            return undefined
        }
    }).filter(sym => (sym !== undefined))

    return (
        <div>
            <Link href={`#${product}`}> </Link>
            <Autocomplete
                id={`products-${product}`}
                options={cardOptions}
                onChange={(_e, value) => onSymbolSelect(value)}
                renderInput={params => (
                    <TextField {...params} label='instruments' variant='standard' fullWidth
                               className={classes.autoComplete}/>
                )}
                popupIcon={<SearchIcon style={{color: 'white'}}/>}
                clearOnEscape={true}
                autoSelect={true}
                includeInputInList={true}
            />
        </div>
    )
}

interface FilteredFlags {
    useFilter: boolean,
    flags: IInstrumentRust[]
}

interface ILatestPrice {
    ts: number,
    price: number,
}

type ILatestPrices = Map<string, ILatestPrice>;

interface ProductTableProps {
    formattedFlags: IInstrumentRust[]
    filteredFlagList: FilteredFlags
    openFlags: IInstrumentRust[],
    product: string,
    productDisplayName: string[],
    portfolioName?: string,
    isComposite?: boolean,
    latestPrices: ILatestPrices,
}

const activeDisplay = (classes, issuedSince, endFlag?) => {
    if (endFlag) return classes.greyAlert
    if (issuedSince < 24) return classes.redAlert;
    if (issuedSince >= 24 && issuedSince < 72) return classes.amberAlert;
    return classes.defaultAlert;
}

const isValidInstrument = new Ajv().compile(instrumentSchema);



const formattedAlertSince = (since) => {
    const timeAlert = parseInt(since);
    if (since < 24) {
        return `+ ${timeAlert.toFixed(0)} hours`
    } else {
        return `+ ${(timeAlert / 24).toFixed(0)} days`
    }
}
const issuedSinceFormatter = (dts: number): string => {
    const latestTime = new Date().valueOf();
    return ((latestTime - dts) / (1000 * 60 * 60)).toFixed(1);
}


const nonCompositeRow = (product: string, symbol: string, compositeSymbols: string[], nonCompositeSymbols: string[], index: number, classes) => {
    const format = (symbols: string[]) => {
        let formatted: string;
        const n = symbols.length;
        if (n > 2) {
          formatted = [symbols.slice(0, symbols.length).join(', '), symbols[n-1]].join(' and ');
        } else {
          formatted = symbols.join(' and ');
        }
        return formatted;
    };

    return (
        <TableRow key={`${symbol}-${index}`} className={classes.productTableRow}>
            <TableCell>{symbol}</TableCell>
            <TableCell colSpan={9} style={{overflowX: "hidden"}}>{`${format(nonCompositeSymbols)}`} flags are used as inputs (inputs only) for this composite instrument.
                {product} {`${format(compositeSymbols)}`} will be generated based on {`${format(nonCompositeSymbols)}`} input Flags</TableCell>
        </TableRow>
    )
}

const noFlagRow = (symbol: string, index: number, latestPrice: string,
                   latestTime: string, description: string, classes) => {
    return (
        <TableRow key={`${symbol}-${index}`} className={classes.productTableRow}>
            <TableCell>{symbol}</TableCell>
            <TableCell style={{maxWidth: "200px"}} className={classes.mediaDisplayTablet}>{description}</TableCell>
            <TableCell style={{overflowX: "hidden"}}>{"No flags available yet"}</TableCell>
            <TableCell className={[classes.mediaDisplayTablet, classes.innerTableCell].join(' ')}>&nbsp;</TableCell>
            <TableCell className={[classes.mediaDisplayTablet, classes.innerTableCell].join(' ')}>&nbsp;</TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell>{latestPrice}</TableCell>
            <TableCell className={classes.mediaDisplayTablet}>{latestTime}</TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell>&nbsp;</TableCell>
        </TableRow>
    )
}

const formatFlag = (flag, product: string, isStartFlag: boolean) => {
    let formatted = {...flag};
    formatted.date = unixConverter(flag.ts);
    formatted.issuedSince = issuedSinceFormatter(flag.ts);

    if (isStartFlag) {
        formatted.formattedAlertSince = formattedAlertSince(formatted.issuedSince);
    } else {
        formatted.formattedAlertSince = product !== 'RAP' ? formattedAlertSince(formatted.issuedSince) : '';
    }
    return formatted;
}

const flagArrow = (flag) => {
    return flag === "Up" ? <ArrowUpwardIcon style={{"fontSize": "0.9rem"}}/> :
        <ArrowDownwardIcon style={{"fontSize": "0.9rem"}}/>
};

const openFlagDisplay = (noOpenFlags: number): string => {
    if (noOpenFlags === 1) return '+';
    if (noOpenFlags > 1) return '++';
    return ''
};

const extractData = (product: string, symbol: string, segments, latestPrices: ILatestPrices | undefined) => {
    const extractFlagData = (product: string, symbol: string, segments: IFlagStartEnd[]) => {
        if (segments.length > 0) {
            const defaultEndFlag: IFlagRust = {ts: 0, price: 0, direction: 'Down'}
            let endFlag: IFlagRust = product !== 'RAP' ? segments[0]['endFlag'] : defaultEndFlag;
            let startFlagsRtn: IFlagRust[];
            if (segments.length === 2) {
                // segments[0] is the closed segments, segments[1] is the currently opened segment
                startFlagsRtn = segments[1].startFlags
            } else {
                startFlagsRtn = segments[0].startFlags
            }
            let startFlag = startFlagsRtn[startFlagsRtn.length - 1];
            const noOfStartFlags = startFlagsRtn.length;
            const currentData = segments.length === 2 ? startFlagsRtn : [];

            startFlag = formatFlag(startFlag, product, true);
            endFlag = formatFlag(endFlag, product, false);

            return {startFlag, endFlag, noOfStartFlags, currentData, product, symbol}
        }
        return null;
    }
    const latestPrice = latestPrices![symbol];
    const latestSymbolDts = latestPrice !== undefined ? latestPrice.ts : `${symbol} no dts`;
    const latestSymbolTime = typeof latestSymbolDts === "string" ? latestSymbolDts : unixConverter(latestSymbolDts);

    const numberDecimalPlaces = product.includes('FX') ? 5 : 2;
    const latestSymbolPrice = latestPrice !== undefined ? latestPrice.price.toFixed(numberDecimalPlaces) : '0.00';

    const data = extractFlagData(product, symbol, segments);

    return {data, latestSymbolPrice, latestSymbolTime}
}

const flagCell = (startValue, endValue, product, cx, classes, activeClasses, mediaClass: string = '',) => {
    const innerTableCell = mediaClass === '' ? classes.innerTableCell : cx(mediaClass, classes.innerTableCell);
    return (
        <TableCell className={innerTableCell}>
            <Table>
                <TableBody>
                    <TableRow className={activeClasses.activeClass}>
                        <TableCell>
                            <span className={classes.tableCellNoWrap}>{startValue}</span>
                        </TableCell>
                    </TableRow>
                    {product !== 'RAP' &&
                    <TableRow className={activeClasses.activeClassEnd}>
                        <TableCell><span className={classes.tableCellNoWrap}>{endValue}</span>
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableCell>)
};


const productBody = (flagData, showHistoricalModal, setRowHandler, rowOpen, modalOpen, cx, classes) => {
    const {
        startFlag, endFlag, noOfStartFlags, currentData,
        product, symbol, index, instrument, description,
        openFlags, latestSymbolPrice, latestSymbolTime,
        isComposite = false,
        ...others
    } = flagData;

    const activeClass = activeDisplay(classes, parseInt(startFlag.issuedSince!));
    const activeClassEnd = activeDisplay(classes, parseInt(endFlag.issuedSince!), parseInt(endFlag.issuedSince!) > parseInt(startFlag.issuedSince!));
    const activeClasses = {activeClass, activeClassEnd}

    const rowIdOpen = rowOpen[index] === undefined ? false : rowOpen[index];
    const modalId = product + symbol;
    const modalIdOpen = modalOpen[modalId] === undefined ? false : modalOpen[modalId];

    const flagArrowDirection = flagArrow(startFlag.direction);
    const noOpenFlagDisplay = openFlagDisplay(noOfStartFlags ?? 0);
    const numberDecimalPlaces = product.includes('FX') ? 5 : 2;

    const historicalSnapshot = (display_type) => {
        if (isComposite) {
            return <CompositeHistoricalSnapShot product={product} symbol={symbol} currentData={currentData}
                                                openFlags={openFlags} type={display_type}
                                                setModalHandler={showHistoricalModal} modalOpen={modalIdOpen}
                                                {...others}/>;
        } else {
            return <HistoricalSnapShot product={product} symbol={symbol} currentData={currentData}
                                       openFlags={openFlags} type={display_type}
                                       setModalHandler={showHistoricalModal} modalOpen={modalIdOpen}
                                       {...others}/>;
        }
    };

    return (
        <Fragment key={`${symbol}-${index}`}>
            <TableRow key={`${symbol}-${index}`} className={classes.productTableRow}>
                <TableCell>{symbol}<br/>{instrument !== undefined && instrument}</TableCell>
                <TableCell style={{maxWidth: "200px"}} className={classes.mediaDisplayTablet}>{description}</TableCell>
                {flagCell(<div>{startFlag.direction} {flagArrowDirection} {noOpenFlagDisplay}</div>, endFlag.direction,
                    product, cx, classes, activeClasses)}
                {flagCell(startFlag.date, endFlag.date, product, cx, classes, activeClasses, classes.mediaDisplayTablet)}
                {flagCell(startFlag.formattedAlertSince + ' ago', endFlag.formattedAlertSince + ' ago',
                    product, cx, classes, activeClasses, classes.mediaDisplay)}
                {flagCell(startFlag.price.toFixed(numberDecimalPlaces),
                    endFlag.price.toFixed(numberDecimalPlaces), product, cx, classes, activeClasses)}
                <TableCell>{latestSymbolPrice}</TableCell>
                <TableCell className={classes.mediaDisplayTablet}>{latestSymbolTime}</TableCell>
                <TableCell>
                    <IconButton onClick={(e) => showHistoricalModal(e, modalId, "IconButton")}>
                        <LaunchIcon/>
                        {modalIdOpen && historicalSnapshot('modal')}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.mediaDisplayTablet}>
                    <IconButton onClick={() => setRowHandler(index)}>
                        {rowIdOpen ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0}} colSpan={10}>
                    <Collapse in={rowIdOpen} timeout="auto" unmountOnExit>
                        <Box margin={0} className={classes.box}>
                            {historicalSnapshot('snapshot')}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

const ProductTable: FC<ProductTableProps> = (props) => {
    const {
        formattedFlags = [],
        filteredFlagList: {useFilter = false, flags = []},
        openFlags = [],
        product = '',
        productDisplayName = [],
        latestPrices = {} as ILatestPrices,
        ...others
    } = props;

    const {classes, cx} = useStyles();

    const flagList = useFilter ? flags : formattedFlags;

    const [rowOpen, setRowOpen] = useState({});

    const setRowHandler = (id) => {
        const rowIdOpen = rowOpen[id] === undefined ? true : !rowOpen[id];
        setRowOpen({...rowOpen, [id]: rowIdOpen})
    }

    const [modalOpen, setModalOpen] = useState({});

    const showHistoricalModal = (e, id, callerId) => {
        /**
         * Event propagation when modal open leading to modal close on click
         *
         * Below fix mean will only close when callerId is HistoricalModal, i.e button clicks
         */
        e.preventDefault();

        if (callerId === "IconButton" && modalOpen[id]) {
            e.stopPropagation();
        } else {
            const modalIdOpen = modalOpen[id] === undefined ? true : !modalOpen[id];
            setModalOpen({...modalOpen, [id]: modalIdOpen});
        }
    }

    const orderedFlagResponse = flagList.filter(instrument => (isValidInstrument(instrument)));
    orderedFlagResponse.sort((a, b) => (a.symbol.localeCompare(b.symbol)));
    const compositeSymbols: string[] = [];
    const nonCompositeSymbols: string[] = [];
    orderedFlagResponse.forEach((instr) => {
        if (instr.composite !== undefined && instr.composite) {
            compositeSymbols.push(instr.symbol);
        } else {
            nonCompositeSymbols.push(instr.symbol);
        }
    });
    const mobileTablet = useMediaQuery('(max-width: 1557px)');

    return (
        <Fragment>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeader}>
                        <TableCell>Instrument</TableCell>
                        <TableCell className={classes.mediaDisplayTablet}>Type</TableCell>
                        <TableCell>Direction</TableCell>
                        <TableCell className={classes.mediaDisplayTablet}>Flag Issued</TableCell>
                        <TableCell className={classes.mediaDisplay}>Flag Issued Since</TableCell>
                        <TableCell>Flag Price</TableCell>
                        <TableCell>Last Price</TableCell>
                        <TableCell className={classes.mediaDisplayTablet}>Last Updated</TableCell>
                        <TableCell>{!mobileTablet && 'Historical'}</TableCell>
                        <TableCell className={classes.mediaDisplayTablet}>Recent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderedFlagResponse.map((instr, index) => {
                        const {symbol, instrument, segments, description, composite} = instr;
                        const {
                            data,
                            latestSymbolPrice,
                            latestSymbolTime
                        } = extractData(product, symbol, segments, latestPrices);
                        if (composite!== undefined  && !composite) {
                            return nonCompositeRow(productDisplayName.length > 0 ? productDisplayName[0]: product, symbol, compositeSymbols, nonCompositeSymbols, index, classes)
                        } 

                        if (data === null) {
                            return noFlagRow(symbol, index, latestSymbolPrice, latestSymbolTime, description, classes)
                        }

                        const flagData = {
                            ...data, index, instrument, description, openFlags,
                            latestSymbolPrice, latestSymbolTime,
                            ...others
                        };
                        return productBody(flagData, showHistoricalModal, setRowHandler, rowOpen, modalOpen, cx, classes);
                    })}
                </TableBody>
            </Table>
        </Fragment>

    )
}

interface ICardHeaderAvatarProps {
    productDisplayName: any[]
}

const CardHeaderAvatar: FC<ICardHeaderAvatarProps> = (props) => {
    const {productDisplayName} = props;

    const hyperLink = () => {
        const baseUrl = 'https://www.algodynamix.com/';
        const productLink = String(productDisplayName[0]).toLowerCase();
        const availableLinks = ['aldx-pi', 'pi-x', 'rap-eq'];
        if (availableLinks.includes(productLink)) {
            window.location.href = `${baseUrl}${productLink}`
        } else {
            window.location.href = `${baseUrl}bespoke-services`
        }
    }

    return (
        <Typography variant="h5">{productDisplayName[0]}&trade; {productDisplayName[1]}
            <IconButton onClick={hyperLink}>
                <InfoIcon/>
            </IconButton>
        </Typography>
    )
}

interface FilterFlagRes {
    useFilter: boolean,
    flags: any[]
}


interface IProductDataRenderProps {
    portfolioName?: string,
    portfolioDescription?: string,
    product: string,
    productDisplayName: any[],
    flagResponse: IInstrumentRust[],
    openFlagResponse: IInstrumentRust[],
    latestPrices: ILatestPrices,
    isComposite?: boolean
}

const ProductDataRender: FC<IProductDataRenderProps> = (props) => {

    const {product, productDisplayName, flagResponse = [], openFlagResponse = [], latestPrices, ...others} = props;

    const {classes} = useStyles();

    const [filterFlagRes, setFilterFlagResponse] = useState<FilterFlagRes>({useFilter: false, flags: []});

    const onSymbolSelectCb = (symbol: string, filterSelect: boolean) => {

        const filteredInstruments = flagResponse.filter((sym) => {
            return sym.symbol === symbol
        });

        setFilterFlagResponse({
            useFilter: filterSelect,
            flags: filteredInstruments
        })
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={<CardHeaderAvatar productDisplayName={productDisplayName}/>}
                title={<CardHeaderComp
                    formattedFlags={flagResponse}
                    symbolSelect={onSymbolSelectCb}
                    product={product}/>}
                action={<CardHeaderAction openFlagList={openFlagResponse} latestPrices={latestPrices}/>}
                className={classes.cardHeader}
            />
            <CardContent style={{padding: '0px', alignContent: "center"}}>
                {flagResponse.length === 0 ? <CircularProgress color="secondary"/> :
                    <ProductTable
                        latestPrices={latestPrices as Map<string, ILatestPrice>}
                        formattedFlags={flagResponse}
                        filteredFlagList={filterFlagRes}
                        openFlags={openFlagResponse}
                        product={product}
                        productDisplayName={productDisplayName}
                        {...others}/>
                }
            </CardContent>
        </Card>
    )
}

export interface IFlagRust {
    direction: 'Up' | 'Down' | ''
    ts: number
    price: number
    issuedSince?: string
    formattedAlertSince?: string
    date?: string
}

interface IFlagStartEnd {
    endFlag: IFlagRust
    startFlags: IFlagRust[]
}

interface IInstrumentRust {
    symbol: string,
    description: string,
    segments: IFlagStartEnd[],
    instrument?: string,
    composite?: boolean,
}

const to_flag_response = (instruments: object) => {
    const flagResponse: IInstrumentRust[] = Object.values(instruments) as IInstrumentRust[];
    const openFlagResponse = flagResponse.filter((instr) => {
        const {segments} = instr;
        if (!segments || !Array.isArray(segments) || segments.length < 1) return false;

        const {startFlags, endFlag} = segments[segments.length - 1];
        if (!startFlags || !Array.isArray(startFlags) || startFlags.length === 0) return false;
        if (!endFlag) return true;

        const latestStartFlag = startFlags[startFlags.length - 1];
        return latestStartFlag.ts > endFlag.ts;
    });
    return {flagResponse, openFlagResponse};
}

const CompositeProductDataProcessor = (props) => {
    const {data: {flags, latestPrices}} = props;
    return (
        <Fragment>
            {Object.keys(flags).map((compositeProduct, index) => {
                const portfolios = flags[compositeProduct];
                return Object.keys(portfolios).map((portfolioName, portfolioIdx) => {
                    const {
                        instruments,
                        portfolioDescription,
                        product
                    }: { instruments: object, portfolioDescription: string, product: string } = portfolios[portfolioName];

                    const productName = String(product).toUpperCase();
                    const displayName = Object.keys(productNamespace).includes(productName) ? productNamespace[productName].displayName : [productName, ''];
                    let instrumentNames: string[] = [];
                    Object.keys(instruments).forEach((instrument) => {
                        let {composite} = instruments[instrument];
                        if (composite === undefined || composite) {
                            instrumentNames.push(instrument)
                        }
                    })
                    const productDisplayName = [`${displayName[0]} Composite`, instrumentNames.join("/")];

                    const {flagResponse, openFlagResponse} = to_flag_response(instruments);
                    return <ProductDataRender key={`${compositeProduct}-${index}-${portfolioIdx}`}
                                              portfolioName={portfolioName}
                                              portfolioDescription={portfolioDescription}
                                              product={productName}
                                              productDisplayName={productDisplayName}
                                              latestPrices={latestPrices}
                                              flagResponse={flagResponse}
                                              openFlagResponse={openFlagResponse}
                                              isComposite={true}/>

                });
            })}
        </Fragment>
    )
}

const ProductDataProcessor = (props) => {
    const {data: {flags, latestPrices}} = props;
    return (
        <Fragment>
            {Object.keys(flags).map((product, index) => {
                const {instruments}: { instruments: object } = flags[product];
                const productName = String(product).toUpperCase();
                const productDisplayName = Object.keys(productNamespace).includes(productName) ? productNamespace[productName].displayName : [productName, ''];

                const {flagResponse, openFlagResponse} = to_flag_response(instruments);
                return <ProductDataRender key={`${index}${product}`}
                                          product={productName}
                                          productDisplayName={productDisplayName}
                                          latestPrices={latestPrices}
                                          flagResponse={flagResponse}
                                          openFlagResponse={openFlagResponse}/>

            })}
        </Fragment>
    )
}

const extractCache = (cacheKey): any => {
    const cached = localStorage.getItem(cacheKey);
    if (cached !== null) {
        try {
            return JSON.parse(window.atob(cached));
        } catch (e) {
            try {
                return JSON.parse(cached);
            } catch (_e) {
                return null
            }
        }
    }
    return null;
}

const ProductDataRequestAll: FC = () => {
    const CacheKey = 'realTimeRust';
    const currentUser = useTypedSelector(selectCurrentUser);
    const email = currentUser?.email ?? '';
    const dispatch = useAppDispatch();

    const {data: statusData, isSuccess: statusIsSucess, isError: statusIsError} = useGetSystemStatusQuery('', {
        pollingInterval: 60000 // every minutes
    })

    useEffect(() =>  {
            dispatch(updateSystemStatus({components: statusData, isError: statusIsError}))
        },
        // eslint-disable-next-line
        [statusData, statusIsError])
    
    const {data, isSuccess, isError} = useGetRealTimeStatusQuery({email}, {
        refetchOnMountOrArgChange: true,
        pollingInterval: 60000 // every minutes
    });


    useEffect(() => {
            const time = current_time();
            if (isError) {
                dispatch(lastUpdate({apiTime: time, status: 'Connecting'}))
            } else {
                dispatch(lastUpdate({apiTime: time, status: 'Active'}))
            }
        },
        // Do we need dependency on dispatch?
        // eslint-disable-next-line
        [data, isError])

    if (isSuccess && statusIsSucess) {
        localStorage.setItem(CacheKey, JSON.stringify(data));
        return (
            <ProductDataProcessor data={data}/>
        )
    } else {
        const cachedParsed = extractCache(CacheKey);
        if (cachedParsed === null) {
            return (
                <Loading type='Delay Loading'>
                    <Typography variant={'body1'} style={{padding: '10px'}}>
                        Delayed Response from server, please contact support if issue persists.<br/>
                        support@algodynamix.com
                    </Typography>
                </Loading>
            )
        } else {
            return (
                <ProductDataProcessor data={cachedParsed}/>
            )
        }
    }
}

export const CompositeProductDataRequestAll: FC = () => {

    const CacheKey = 'compositeRealTime';
    const currentUser = useTypedSelector(selectCurrentUser);
    const email = currentUser?.email ?? '';
    const {data, isSuccess, isError} = useGetCompositeRealTimeStatusQuery({email}, {
        refetchOnMountOrArgChange: true,
        pollingInterval: 60000 // every minutes
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
            const time = current_time();
            if (isError) {
                dispatch(lastUpdate({apiTime: time, status: 'Connecting'}))
            } else {
                dispatch(lastUpdate({apiTime: time, status: 'Active'}))
            }
        },
        // Do we need dependency on dispatch?
        // eslint-disable-next-line
        [data, isError])

    if (isSuccess) {
        localStorage.setItem(CacheKey, JSON.stringify(data));
        return (
            <CompositeProductDataProcessor data={data}/>
        )
    } else {
        const cachedParsed = extractCache(CacheKey);
        if (cachedParsed === null) {
            return (
                <Loading type='Delay Loading'>
                    <Typography variant={'body1'} style={{padding: '10px'}}>
                        Delayed Response from server, please contact support if issue persists.<br/>
                        support@algodynamix.com
                    </Typography>
                </Loading>
            )
        } else {
            return (
                <CompositeProductDataProcessor data={cachedParsed}/>
            )
        }
    }
}

export default ProductDataRequestAll;
