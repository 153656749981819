import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import {FC, ReactNode, useState} from "react";
import {Box} from '@mui/system';
import Header from './Header';
import {endFlagFromProduct} from "./utils";

interface Flag {
    dts: string,
    price: number,
    endTime: number | 'Open',
    endPrice: number | 'Open'
}

interface HistoricalDataProps {
    flags?: Flag[]
    rows?: 5 | 10,
    product: string,
    tableType?: 'normal' | 'large' // normal with 4 heading, large include further columns including returns
}

const FlagRow = ({flag, product, index, tableType}) => {
    const numberDecimalPlaces = product.includes('FX') ? 5 : 2;
    const endPrice = typeof flag['endPrice'] === 'number' ? flag['endPrice'].toFixed(numberDecimalPlaces) : flag['endPrice'];
    const key = `flag-${index}`;
    return (
        <TableRow key={key}>
            <TableCell>{flag['dts']}</TableCell>
            <TableCell>{flag['price'].toFixed(numberDecimalPlaces)}</TableCell>
            <TableCell>{flag['endTime']}</TableCell>
            <TableCell>{endPrice}</TableCell>
            {tableType === 'large' &&
            <TableCell>{flag['status']}</TableCell>
            }
        </TableRow>
    )
}

const HistoricalData: FC<HistoricalDataProps> = (props) => {
    const {flags = [], rows = 5, product, tableType = 'normal'} = props;

    const endFlag = endFlagFromProduct(product);
    const startFlag = endFlag === 'Down' ? 'Up' : 'Down';

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rows);

    const setPageHandler = (_e, the_page) => {
        setPage(the_page)
    }

    const setRowsPerPageHandler = (e) => {
        setRowsPerPage(e.target.value)
        setPage(0)
    }

    const remainingRows = (page * rowsPerPage + rowsPerPage) - flags.length;
    let emptyRows: ReactNode[] = [];
    for (let i = 0; i < remainingRows; i++) {
        emptyRows.push([
            <TableRow key={`empty-${i}`}>
                <TableCell style={{borderColor: 'white'}} colSpan={4}/>
            </TableRow>
        ])
    }

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const flagRows = flags.slice(startIndex, endIndex).map((flag, index) => {
        return (
            <FlagRow key={index} flag={flag} index={index} product={product} tableType={tableType}/>
        )
    });
    return (
        <Box>
            <Header>Current and Historical Flags</Header>
            <TableContainer>
                <Table sx={{
                    width: '100%'
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{`${startFlag} Flag Issued`}</TableCell>
                            <TableCell>{`${startFlag} Flag Price`}</TableCell>
                            <TableCell>{`End Flag Issued `}</TableCell>
                            <TableCell>{`End Flag Price `}</TableCell>
                            {tableType === 'large' &&
                            <TableCell>{'Status'}</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {flagRows.length > 0 && flagRows}
                        {emptyRows.length > 0 && emptyRows}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10]}
                component='div'
                count={flags.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={setPageHandler}
                onRowsPerPageChange={setRowsPerPageHandler}
            />
        </Box>
    )
}

export default HistoricalData;