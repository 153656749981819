export const productDescription = [{
    apiName: 'RAPEQ', displayName: ['RAP-EQ', ''],
    realTime: 'RAPEQ'
},
{
    apiName: 'RAPEQX',
    displayName: ['RAP-EQX', ''],
    realTime: 'RAPEQX'
},
{
    apiName: 'RAPEQM',
    displayName: ['RAP-EQ', ' Magnum'],
    realTime: 'RAPEQM'
},
{
    apiName: 'RAPEQXM',
    displayName: ['RAP-EQX', ' Magnum'],
    realTime: 'RAPEQXM'
},
{
    apiName: 'RAPEQMAGNUM',
    displayName: ['RAP-EQ', ' Magnum'],
    realTime: 'RAPEQM'
},
{
    apiName: 'RAPCO',
    displayName: ['RAP-CO', ''],
    realTime: 'RAPCO'
},
{
    apiName: 'RAPCOX',
    displayName: ['RAP-COX', ''],
    realTime: 'RAPCOX'
},
{
    apiName: 'RAPCOM',
    displayName: ['RAP-CO', ' Magnum'],
    realTime: 'RAPCOM'
},
{
    apiName: 'RAPCOXM',
    displayName: ['RAP-COX', ' Magnum'],
    realTime: 'RAPCOXM'
},
{
    apiName: 'RAPCOMAGNUM',
    displayName: ['RAP-CO', ' Magnum'],
    realTime: 'RAPCOMAGNUM'
},
{
    apiName: 'RAPFX',
    displayName: ['RAP-FX', ''],
    realTime: 'RAPFX'
},
{
    apiName: 'RAPFXX',
    displayName: ['RAP-FXX', ''],
    realTime: 'RAPFXX'
},
{
    apiName: 'RAPFXM',
    displayName: ['RAP-FX', ' Magnum'],
    realTime: 'RAPFXM'
},
{
    apiName: 'RAPFXXM',
    displayName: ['RAP-FXX', ' Magnum'],
    realTime: 'RAPFXXM'
},
{
    apiName: 'RAPFXMAGNUM',
    displayName: ['RAP-FX', ' Magnum'],
    realTime: 'RAPFXM'
},
{
    apiName: 'RAPFI',
    displayName: ['RAP-FI', ''],
    realTime: 'RAPFI'
},
{
    apiName: 'RAPFIX',
    displayName: ['RAP-FIX', ''],
    realTime: 'RAPFIX'
},
{
    apiName: 'RAPFIM',
    displayName: ['RAP-FI', ' Magnum'],
    realTime: 'RAPFIM'
},
{
    apiName: 'RAPFIXM',
    displayName: ['RAP-FIX', ' Magnum'],
    realTime: 'RAPFIXM'
},
{
    apiName: 'RAPFIMAGNUM',
    displayName: ['RAP-FI', ' Magnum'],
    realTime: 'RAPFIMAGNUM'
},
{
    apiName: 'PIEQ',
    displayName: ['PI-EQ', ''],
    realTime: 'PIEQ'
},
{
    apiName: 'PIEQX',
    displayName: ['PI-EQX', ''],
    realTime: 'PIEQX'
},
{
    apiName: 'PIEQM',
    displayName: ['PI-EQ', ' Magnum'],
    realTime: 'PIEQM'
},
{
    apiName: 'PIEQXM',
    displayName: ['PI-EQX', ' Magnum'],
    realTime: 'PIEQXM'
},
{
    apiName: 'PIEQMAGNUM',
    displayName: ['PI-EQ', ' Magnum'],
    realTime: 'PIEQM'
},
{
    apiName: 'PICO',
    displayName: ['PI-CO', ''],
    realTime: 'PICO'
},
{
    apiName: 'PICOX',
    displayName: ['PI-COX', ''],
    realTime: 'PICOX'
},
{
    apiName: 'PICOM',
    displayName: ['PI-CO', ' Magnum'],
    realTime: 'PICOM'
},
{
    apiName: 'PICOXM',
    displayName: ['PI-COX', ' Magnum'],
    realTime: 'PICOXM'
},
{
    apiName: 'PICOMAGNUM',
    displayName: ['PI-CO', ' Magnum'],
    realTime: 'PICOMAGNUM'
},
{
    apiName: 'PIFX',
    displayName: ['PI-FX', ''],
    realTime: 'PIFX'
},
{
    apiName: 'PIFXX',
    displayName: ['PI-FXX', ''],
    realTime: 'PIFXX'
},
{
    apiName: 'PIFXM',
    displayName: ['PI-FX', ' Magnum'],
    realTime: 'PIFXM'
},
{
    apiName: 'PIFXXM',
    displayName: ['PI-FXX', ' Magnum'],
    realTime: 'PIFXXM'
},
{
    apiName: 'PIFXMAGNUM',
    displayName: ['PI-FX', ' Magnum'],
    realTime: 'PIFXM'
},
{
    apiName: 'PIFI',
    displayName: ['PI-FI', ''],
    realTime: 'PIFI'
},
{
    apiName: 'PIFIX',
    displayName: ['PI-FIX', ''],
    realTime: 'PIFIX'
},
{
    apiName: 'PIFIM',
    displayName: ['PI-FI', ' Magnum'],
    realTime: 'PIFIM'
},
{
    apiName: 'PIFIXM',
    displayName: ['PI-FIX', ' Magnum'],
    realTime: 'PIFIXM'
},
{
    apiName: 'PIFIMAGNUM',
    displayName: ['PI-FI', ' Magnum'],
    realTime: 'PIFIMAGNUM'
},
{
    apiName: 'PI',
    displayName: ['ALDX-PI', ' '],
    realTime: 'PIL'
},
{
    apiName: 'PIX',
    displayName: ['PI-X', ' '],
    realTime: 'PIXL'
},
{
    apiName: 'PIXL',
    displayName: ['PI-XL', ' '],
    realTime: 'PIXL'
}]

export const productNamespace = productDescription.reduce((acc, val) => {
    if (acc[val.apiName] === undefined) {
        acc[val.apiName] = {};
        acc[val.apiName]['displayName'] = val.displayName;
        acc[val.apiName]['realTime'] = val.realTime;
    }
    return acc
}, {});

export const productsOrdered = ['PIEQ', 'PIEQMAGNUM', 'RAPEQ', 'RAPEQMAGNUM', 'PIFI', 'RAPFI', 'PIFX', 'PIFXMAGNUM', 'RAPFX', 'RAPFXMAGNUM', 'PIX', 'PIEQX', 'PICO', 'RAPCO', 'PI', 'RAP']