import {Grid, useMediaQuery} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import ProductDataRequestAll, {CompositeProductDataRequestAll} from "./ProductsCard";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(1),
    },
    itemStyle: {
        backgroundColor: '#E5E7EC',
        marginTop: 0,
        paddingTop: 0,
        height: '100%'
    }
}));

export const CompositePageContentAlertStatus = () => {
    const {classes} = useStyles();

    const mobileTablet = useMediaQuery('(max-width: 900px)');

    const spacing = mobileTablet ? 1 : 2;
    return (
        <Grid container spacing={spacing}>
            <Grid item md={1}/>
            <Grid item xs={12} md={10} className={classes.itemStyle}>
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        <CompositeProductDataRequestAll/>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item sm={1}/>
        </Grid>
    )
}

const PageContentAlertStatus = () => {
    const {classes} = useStyles();

    const mobileTablet = useMediaQuery('(max-width: 900px)');

    const spacing = mobileTablet ? 1 : 2;
    return (
        <Grid container spacing={spacing}>
            <Grid item md={1}/>
            <Grid item xs={12} md={10} className={classes.itemStyle}>
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        <ProductDataRequestAll/>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item sm={1}/>
        </Grid>
    )
}

export default PageContentAlertStatus;
