import Canvas from './Canvas';
import PageContentAPI from './pagecontent/api/PageContentApi';

export const AnalyticsApi = () => {
    return (
        <Canvas>
            <PageContentAPI/>
        </Canvas>
    )
}



