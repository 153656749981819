import {FC} from 'react';
import {Box} from '@mui/material';
import compositeUpEndImage from '../../../../assets/CompositeUp2EndChartFinal.png';
import compositeDownEndImage from '../../../../assets/CompositeDown2EndChartFinal.png';
import downEndImage from '../../../../assets/Down2EndChartFinal.png';
import upEndImage from '../../../../assets/Up2EndChartFinal.png';
import upDownImage from '../../../../assets/Up2DownChartFinal.png';
import strategyKey from './StrategyKey.json';
import Header from './Header';
import {Metric, Metrics} from "./Metrics";
import {getProductKey} from "./utils";


interface IFlagDescriptionProps {
    name: string,
    product: string,
    isComposite: boolean,
    strategyName: string
    metrics: Metric[],
}

const FlagDescription: FC<IFlagDescriptionProps> = (props) => {

    const {isComposite, name, product, strategyName, metrics = []} = props;

    const productKey = getProductKey(product, isComposite);

    const {description, image} = strategyKey[productKey][strategyName];


    const getImage = (img) => {
        if (isComposite) {
          if (img === "FirstUpFirstEnd.png") return compositeUpEndImage;
          if (img === "FirstDownFirstEnd.png") return compositeDownEndImage;
        }
        if (img === "FirstUpFirstEnd.png") return upEndImage;
        if (img === "FirstUpOpEnd.png") return upDownImage;
        if (img === "FirstDownFirstEnd.png") return downEndImage;
        return upEndImage
    }
    const imageName = getImage(image)

    return (
        <Box>
            <Header>Description of {name}</Header>
            <Box id='wyt' sx={{
                width: '100%',
                objectFit: 'contain',
                justifySelf: 'start',
                display: 'grid',
                gridTemplateColumns: '80% 20%',
                gridAutoRows: 'minmax(min-content, max-content)',
                gridGap: '10px',
                alignContent: 'center'
            }}>
                <div>
                    <img src={imageName} alt={imageName} width='80%'/>
                    <div dangerouslySetInnerHTML={{__html: description}}/>
                </div>
                <Metrics metrics={metrics}/>
            </Box>
        </Box>
    );
}

export default FlagDescription