import {Fragment} from 'react';
import {Theme} from '@mui/material/styles';
import {makeStyles} from 'tss-react/mui';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import Loading from '../../../common/Loading';
import {useGetPortfolioWeightsByNameQuery} from '../../../../services/api';
import {useTypedSelector} from '../../../../reducers/hooks/hooks';
import {selectCurrentUser} from '../../../../reducers/features/auth/authSlice';

const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(1),
    },
    itemStyle: {
        backgroundColor: '#E5E7EC',
        marginTop: 0,
        paddingTop: 0,
        height: '100%'
    },
    table: {
        width: "100%",
        backgroundColor: "white"
    },
    tableHead: {
        backgroundColor: 'rgba(60, 77, 94,0.5)',
        color: 'black'
    },
    tableHeaderCell: {
        color: "black",

    },
}));

const DownloadCsv = (props) => {
    const {portfolio, weights} = props;

    const downloadClick = () => {
        if (weights['weights'].length > 0) {
            const csvPageHeader = ["AlgoDynamix", "", `Portfolio: ${portfolio}`];
            const csvTableHeader = ["Dates"]
            weights['symbols'].map((symbol, _i) => csvTableHeader.push(symbol));

            const num_symbols = weights['symbols'].length;
            const csvData = weights['dates'].map((date, i) => {
                const w = [date];
                for (let j = 0; j < num_symbols; j++) {
                    w.push(weights['weights'][j][i]);
                }
                return w.join(",");
            });
            const csvCombined = csvPageHeader.concat(csvTableHeader.join(','), csvData);
            const csvReady = csvCombined.join('\n');

            const encodeCSV = encodeURIComponent(csvReady);
            const hiddenHTMLelement = document.createElement('a');
            hiddenHTMLelement.href = ("data:text/csv;charset=utf-8,\uFEFF" + encodeCSV);
            hiddenHTMLelement.download = (`AlgoDynamix Download ${new Date()} portfolio : ${portfolio}.csv`);
            hiddenHTMLelement.click();

        } else {
            alert('No Data Available')
        }

    };

    return (
        <Button variant="contained" onClick={downloadClick}>Download</Button>
    )
}

const PageContentPortfolioWeights = ({portfolio}) => {
    const {classes} = useStyles();

    const currentUser = useTypedSelector(selectCurrentUser);
    const user = currentUser?.email ?? '';
    const {data: weights, isFetching, isLoading} = useGetPortfolioWeightsByNameQuery({
        portfolio, user
    }, {
        refetchOnMountOrArgChange: true,
    });

    if (isFetching || isLoading || !weights) {
        return (
            <Loading type='Loading Data'/>
        )
    } else {
        return (
            <Fragment>
                <DownloadCsv portfolio={portfolio} weights={weights}/>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableHead}>
                            <TableCell className={classes.tableHeaderCell} key='dates'>Dates</TableCell>
                            {weights['symbols'].map((symbol, i) => (
                                <TableCell className={classes.tableHeaderCell} key={i}>{symbol}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weights['dates'].map((date, i) => (
                            <TableRow key={i}>
                                <TableCell>{date}</TableCell>
                                {weights['symbols'].map((_symbol, j) =>
                                    <TableCell key={`weight-${j}`}>{weights['weights'][j][i]}</TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </Fragment>
        )
    }
}


export default PageContentPortfolioWeights;