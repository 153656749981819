import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {authService, User} from "../../../services/AuthenticationService";
import type {RootState} from '../../store';

export interface AuthContext {
    user: User | null,
    token: string | null,
}

interface AuthState {
    error: string | undefined,
    status: 'failed' | 'loading' | 'logout' | 'login',
    context: AuthContext,
}

const initialState: AuthState = {
    context: {user: null, token: null},
    error: undefined,
    status: 'logout',
};

/*
const ensureValidAuthContext = async () => {
    let tokens = AuthenticationService.loadInitialTokens();
    if (tokens.length !== 2) {
        try {
            await authService.asyncRefreshToken();
            // Now that token could be refreshed, let sync to store
            authService.storeTokens(false);
            tokens = AuthenticationService.loadInitialTokens();
        } catch (e) {
            log.error('EnsureAuthenticated failed to refresh token, try to re-login', e)
            return authService.login();
        }
    }
    if (tokens.length === 2) {
        return authService.asyncAuthContext()
    }
}

export const login = createAsyncThunk('auth/login', async () => {
    const authorized = await authService.asyncAuthorised();
    if (!authorized) {
        return authService.login();
    } else {
        return ensureValidAuthContext();
    }
})
 */

export const logout = createAsyncThunk('auth/logout', async () => {
    await authService.logout();
    return {user: null, token: null}
})


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateContext: (
            state,
            {payload: {user, token}}: PayloadAction<{ user: User, token: string }>) => {
            if (state.context.token !== token || !state.context.user || state.context.user.email !== user.email) {
                state.context = {user, token}
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(logout.pending, (state, _action) => {
                state.status = 'loading';
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.status = 'logout';
                state.context = action.payload;
            })
            .addCase(logout.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message
            })
    }
});

export default authSlice.reducer;

export const {updateContext} = authSlice.actions;

export const selectAuthStatus = state => state.auth.status;
export const selectAuthContext = state => state.auth.context;

export const selectCurrentUser = (state: RootState) => state.auth.context.user;
