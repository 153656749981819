import * as log from 'loglevel';
import {useReducer, useState} from 'react';
import {Box, Button, TextField, Typography} from '@mui/material';
import {ApiBaseUrl} from '../../../../App';
import {useTypedSelector} from '../../../../reducers/hooks/hooks';
import {selectAuthContext} from '../../../../reducers/features/auth/authSlice';

const ChangePassword = () => {

    const {user, token} = useTypedSelector(selectAuthContext);

    const initialState = {
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
    }

    const middleware = (currentState, {field, value}) => {
        if (value !== null) {
            return {...currentState, [field]: value}
        }
        return currentState
    }

    const [currentLogin, dispatchLogin] = useReducer(middleware, initialState);

    const [passwordResponse, setPasswordResponse] = useState<string | undefined>(undefined)

    const sendPasswordChange = () => {

        const {oldPassword, newPassword, newPasswordCheck} = currentLogin;

        if (oldPassword === '' || newPassword === '' || newPasswordCheck === '') {
            log.info('password not changed');
            return
        }
        if (newPassword !== newPasswordCheck) {
            alert('Passwords do not match');
            return
        }

        if (user === null || user.email === null || user.email === '' || token === undefined) return;

        const url = `${ApiBaseUrl}/auth/${user.email}/set-password`;
        const body = JSON.stringify({"currentPassword": oldPassword, "newPassword": newPassword});

        const xlr = new XMLHttpRequest();

        xlr.open('POST', url);
        xlr.setRequestHeader('Content-Type', 'application/json');
        xlr.setRequestHeader('Authorization', `Bearer ${token}`);
        log.info("send password reset")

        xlr.send(body);

        xlr.addEventListener('load', () => {
            log.debug("xlr password", xlr.responseText, xlr.status);
            if (xlr.status === 200) {
                setPasswordResponse('Password Updated')
            } else {
                setPasswordResponse('Error setting password please try again')
            }
        })

    }

    return (
        <Box margin={5}>
            <TextField
                id="old-password"
                label="Old Password"
                type="Password"
                style={{margin: 8}}
                placeholder="Old Password"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true
                }}
                variant="outlined"
                onChange={(e) => (dispatchLogin({field: 'oldPassword', value: e.target.value}))}
            />
            <TextField
                id="new-password-1"
                label="New Password"
                type="Password"
                style={{margin: 8}}
                placeholder="New Password"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true
                }}
                variant="outlined"
                onChange={(e) => (dispatchLogin({field: 'newPassword', value: e.target.value}))}
            />
            <TextField
                id="new-password-2"
                label="New Password"
                type="Password"
                style={{margin: 8}}
                placeholder="New Password"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true
                }}
                variant="outlined"
                onChange={(e) => (dispatchLogin({field: 'newPasswordCheck', value: e.target.value}))}
            />
            <Button onClick={sendPasswordChange}>
                Submit Password
            </Button>
            {passwordResponse &&
            <Typography>
                {passwordResponse}
            </Typography>
            }
        </Box>
    )
}

export default ChangePassword