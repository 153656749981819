import Canvas from './Canvas';
import {Fragment, useState} from 'react';
import {makeStyles} from 'tss-react/mui';
import {Divider, Grid, IconButton, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import LensIcon from '@mui/icons-material/Lens';
import PageContentAlertStatus from './pagecontent/alertstatus/PageContentAlertStatus';
import {useTypedSelector} from '../../reducers/hooks/hooks';
import {selectApiTimeAndStatus} from '../../reducers/features/instruments/instrumentSlice';
import { selectSystemStatus } from '../../reducers/features/system/systemSlice';
import DialogueModal from '../common/DialogueModal';
import { ComponentStatus } from '../../services/api';
import moment from 'moment';

const useStyles = makeStyles()(() => ({
    subToolbarDescription: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start'
    },
}));

export const AlertStatusHeaders = () => {
    const {classes} = useStyles();

    const subHeader = [
        {'name': 'Red', 'description': 'Flag issued  < 24 hours', 'color': 'red'},
        {'name': 'Amber', 'description': 'Flag issued  1 - 3 days', 'color': 'orange'},
        {'name': 'Grey', 'description': 'No End Flag', 'color': 'grey'}];


    return (
        <Fragment>
            <div className={classes.subToolbarDescription}>
                {subHeader.map((val, index) => {
                    const classSubHeader = {
                        color: 'white',
                        marginRight: '0.75em',
                        borderBottom: `0.2em ${val['color']}`,
                        marginTop: '0px',
                        marginBottom: '0px',
                        fontSize: '1.1rem'
                    }

                    return (
                        <Fragment key={`subheader-${index}`}>
                            <Typography variant="subtitle2" style={classSubHeader}>
                                <LensIcon style={{fontSize: '0.7rem', marginRight: '0.5rem'}}
                                          htmlColor={val['color']}/>{`${val['name']}`} - {val['description']}
                            </Typography><Divider orientation="vertical"/>
                        </Fragment>
                    )
                })}

            </div>
            <ApiTimeAndStatus/>
        </Fragment>
    );
}

const ApiTimeAndStatus = () => {
    const [opened, setOpened] = useState(false);
    const onClick = () => setOpened(!opened);
    const displayTitle = JSON.stringify(['System Status', '', '']);
    const apiTime = useTypedSelector(selectApiTimeAndStatus);
    const systemStatus = useTypedSelector(selectSystemStatus);
    const statusColor = systemStatus.status === 'Unhealthy' ? 'red': 'white';
    return (
        <div>
            <Typography variant="subtitle2" style={{color: "white"}}>
                {`Updated : ${apiTime.apiTime} Status : ${apiTime.status} `}
                <span style={{color: statusColor}}>
                    {systemStatus.status}
                    <IconButton onClick={onClick}><InfoIcon style={{color: statusColor}}/></IconButton>
                </span>
                <DialogueModal title={displayTitle} open={opened} modalHandler={onClick}>
                    <Grid container>
                        <Grid item xs={2}><strong>Component</strong></Grid>
                        <Grid item xs={2}><strong>Status</strong></Grid>
                        <Grid item xs={4}><strong>Last status change</strong></Grid>
                        <Grid item xs={4}><strong>Last probe time</strong></Grid>
                                {systemStatus.components.map((component: ComponentStatus, _) => {
                                    const lastProbeTime = moment(component.lastProbeTime).local().format('D MMM YY HH:mm');
                                    const lastTransitionTime = moment(component.lastTransitionTime).local().format('D MMM YY HH:mm');
                                    return (
                                        <Grid container key={component.component}>
                                            <Grid item xs={2} key={`${component.component}-0`}>{component.component}</Grid>
                                            <Grid item xs={2}>{component.message}</Grid>
                                            <Grid item xs={4}>{`${lastTransitionTime}`}</Grid>
                                            <Grid item xs={4}>{`${lastProbeTime}`}</Grid>
                                        </Grid>)
                                })}
                    </Grid>
                </DialogueModal>
            </Typography>
        </div>
    )
}


export const AlertStatus = () => {

    const subHeader = [
        {'name': 'Red', 'description': 'Flag issued  < 24 hours', 'color': 'red'},
        {'name': 'Amber', 'description': 'Flag issued  1 - 3 days', 'color': 'orange'},
        {'name': 'Grey', 'description': 'No End Flag', 'color': 'grey'}];

    return (
        <Canvas subHeader={subHeader}>
            <PageContentAlertStatus/>
        </Canvas>
    )
}
