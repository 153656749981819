import {withStyles} from 'tss-react/mui';
import {Button, Dialog, IconButton, Typography} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    modalHeader: {
        backgroundColor: 'rgba(81,109,145,1)',
        color: 'white'
    }
});

const DialogTitle = withStyles(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle className={classes.modalHeader}>
            <div>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={(e) => onClose(e)}>
                        <CloseIcon color="primary"/>
                    </IconButton>
                ) : null}
            </div>
        </MuiDialogTitle>
    );
}, styles);

const DialogContent = withStyles(MuiDialogContent, theme => ({
    root: {
        padding: theme.spacing(2),
    },
}));

const DialogActions = withStyles(MuiDialogActions, theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}));

const DialogueModal = (props) => {

    const {
        title = '["", "", ""]',
        open = false,
        modalHandler = (_) => undefined,
        buttonConfig = {'name': '', 'action': () => undefined},
        buttonDiv = '',
        children
    } = props;

    const titleParsed = JSON.parse(title);

    const modalRequest = (e) => {
        modalHandler(e)
    }

    return (
        <Dialog open={open} aria-labelledby="customized-dialog-title" fullWidth={true} maxWidth={'lg'}>
            <DialogTitle onClose={modalHandler}>
                {titleParsed[2] !== '' ?
                    <Typography>
                        {titleParsed[0]}&trade; {titleParsed[1]} {titleParsed[2]}
                    </Typography> :
                    <Typography>
                        {titleParsed[0]}
                    </Typography>
                }
            </DialogTitle>
            <DialogContent dividers style={{height: "100%"}}>
                {children}
            </DialogContent>
            <DialogActions>
                {buttonConfig.name !== '' &&
                <Button autoFocus onClick={buttonConfig.action} color="secondary">
                    {buttonConfig.name}
                </Button>
                }
                {buttonDiv !== '' && buttonDiv}
                <Button id="modal-close" autoFocus onClick={(e) => modalRequest(e)} color="secondary">
                    Close
                </Button>

            </DialogActions>

        </Dialog>
    )
}

DialogueModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    modalHandler: PropTypes.func,
    headerStyle: PropTypes.object,
    buttonConfig: PropTypes.object,
    buttonDiv: PropTypes.element,
    children: PropTypes.any,
}

export default DialogueModal;