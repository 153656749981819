import {KeycloakInitOptions, KeycloakInstance} from 'keycloak-js';
import Cookies from 'js-cookie';
import moment from 'moment';
import {DeleteCookie} from './utils';
import * as log from 'loglevel';
import keycloak from "../keycloak";

// TODO should this be the global initialisation?
log.setDefaultLevel("debug");

export interface User {
    email?: string,
    name?: string,
    username?: string
}

interface Authentication {
    asyncAuthorised(): Promise<boolean>

    isAuthorised(): boolean
}

const TIME_FORMAT = 'HH:mm:ss';

const resolveUser = (val) => {
    let userProfile: User = {};
    userProfile.email = val.email || '';
    userProfile.name = val.firstName || '';
    userProfile.username = val.username || '';
    // TODO: same ite might not work with secure cookie
    Cookies.set("usr", userProfile, {
        secure: process.env.NODE_ENV === "production",
        sameSite: "strict"
    });
    return userProfile;
}

class AuthenticationService implements Authentication {

    private authorised: boolean;
    private readonly keyCloak: KeycloakInstance;
    onTokenRefresh: any;

    constructor() {
        this.keyCloak = keycloak;
        this.authorised = false;
        this.onTokenRefresh = null;

        log.info('AuthenticationService constructed: ', Date());
    }

    authorise() {
        this.authorised = true;
    }

    isAuthorised() {
        return this.authorised;
    }

    keycloak() {
        return this.keyCloak;
    }

    logout() {
        AuthenticationService.clear();
        return this.keyCloak.logout();
    }

    onEvent = (event: unknown, _error: unknown) => {
        if (event === 'onAuthSuccess') {
            this.authorise()
            this.storeTokens()
        }
    }

    onTokens = (_tokens: unknown) => {
        this.onTokenRefresh()
    }

    storeTokens(notify: boolean = true) {
        // FIXME: should we store refreshToken?
        this.storeValidToken("_kcrtk", this.keyCloak.refreshToken);
        const token = this.storeValidToken("_kctk", this.keyCloak.token);
        if (token && notify && this.onTokenRefresh) {
            if (this.onTokenRefresh) {
                this.onTokenRefresh()
            }
        }
    }

    storeValidToken(name: string, token: string | undefined): string | undefined {
        if (token) {
            const valid = AuthenticationService.isValidToken(token);
            if (valid) {
                // TODO: sameSite might not work with secure cookie
                Cookies.set(name, token, {
                    secure: process.env.NODE_ENV === "production",
                    sameSite: "strict"
                });

                return token;
            }
        }
        return token;
    }


    asyncAuthorised(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            resolve(this.authorised)
        })
    }

    asyncAuthContext(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.keyCloak.loadUserProfile().then((val) => {
                const token = this.keyCloak.token;
                if (val && token) {
                    const user = resolveUser(val);
                    resolve({user, token})
                } else {
                    reject('error')
                }
            }).catch(() => {
                reject('error')
            })
        })
    }

    asyncIsTokenExpired(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const expired = this.keyCloak.isTokenExpired();
            if (expired !== undefined) {
                resolve(expired)
            } else {
                reject(false)
            }

        })
    }

    asyncRefreshToken(): Promise<boolean> {
        return this.keyCloak.updateToken(30).then((refreshed) => {
            if (refreshed) {
                log.info("AuthenticationService: Token refreshed");
                this.storeTokens();
            }
            return true;
        }).catch((e) => {
            log.error("Failed to refresh token", e);
            throw e;
        })
    }

    asyncToken(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const token = this.keyCloak.token;
            if (token) {
                resolve(token)
            } else {
                reject('error')
            }
        })
    }

    static clear() {
        DeleteCookie('_kctk');
        DeleteCookie('_kcrtk');
        DeleteCookie('usr');
        localStorage.removeItem('availableInstruments')
    }

    static keycloakInitOptions() {
        const options: KeycloakInitOptions = {
            enableLogging: true,
        };

        const tokens = AuthenticationService.loadInitialTokens();
        if (tokens.length === 2) {
            log.debug('AuthenticationService loaded valid tokens')
            options['token'] = tokens[0];
            options['refreshToken'] = tokens[1];
        }
        return options
    }

    static loadInitialTokens(): string[] {
        let tokens: string[] = [];
        ['_kctk', '_kcrtk'].forEach((name) => {
            const token = Cookies.get(name);
            const valid = AuthenticationService.isValidToken(token);
            if (valid) {
                tokens.push(token!);
            }
        });

        return tokens;
    }

    static isTokenAvailable(): boolean {
        const token = Cookies.get('_kctk');
        if (!token || token === '') return false;
        const decodedToken = JSON.parse(window.atob(token.split('.')[1]));
        const nowTime = parseInt(moment().format('x'));
        const expiryTime = decodedToken.exp;
        return expiryTime >= nowTime;
    }


    static isValidToken = (token) => {
        if (token && token !== '') {
            const decodedToken = JSON.parse(window.atob(token.split('.')[1]));
            const exp = parseInt(decodedToken.exp);
            const nowTime = parseInt(moment().format('x')) / 1000;
            const timeLeft = exp - nowTime;
            const valid = timeLeft > 0;
            log.debug(`${decodedToken.typ} token is ${valid} at ${moment.unix(nowTime).format(TIME_FORMAT)}, expires at ${moment.unix(exp).format(TIME_FORMAT)}, time left ${timeLeft.toFixed(0)}s`);
            return timeLeft > 0;
        }
        return false;
    }
}

export const authService = new AuthenticationService();

export default AuthenticationService
