const flagResponseSchema = {
    type: "object",
    properties: {
        direction: {type: 'string'},
        ts: {type: 'integer'},
        price: {type: 'number'}
    },
    required: ['direction', 'ts', 'price']
}

const segmentSchema = {
    type: "object",
    properties: {
        startFlags: {
            type: "array",
            items: flagResponseSchema,
            minItems: 1
        },
        endFlag: flagResponseSchema
    },
    required: ['startFlags']
}

export const instrumentSchema = {
    type: "object",
    properties: {
        symbol: {type: 'string'},
        description: {type: 'string'},
        segments: {
            type: "array",
            items: segmentSchema,
            minItems: 0
        }
    },
    required: ['symbol', 'description', 'segments']
}

const instrumentsSchema = {
    type: "object",
    patternProperties: {
        "^[a-zA-Z]{1,}$": instrumentSchema
    }
}

const flagsSchema = {
    type: 'object',
    patternProperties: {
        "^[a-zA-Z]{1,}$": instrumentsSchema
    }
}

const productsSchema = {
    type: 'object',
    patternProperties: {
        "^[a-zA-Z]{1,}$": {
            type: 'object',
            properties: {
                name: {type: "string"},
                abbreviation: {type: "string"}
            }
        }
    }
}

const latestPricesSchema = {
    type: "object",
    patternProperties: {
        "^[a-zA-Z]{1,}$": {
            type: 'object',
            properties: {
                price: {type: "number"},
                ts: {type: "integer"}
            }
        }
    }
}

export const realTimeAllSchema = {
    type: 'object',
    properties: {
        flags: flagsSchema,
        products: productsSchema,
        latestPrices: latestPricesSchema
    },
    required: ['flags', 'latestPrices']
}

export interface IRealTimeAll {
    flags: object,
    products: object,
    latestPrice: object
}
