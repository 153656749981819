import React, {Fragment} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {Outlet, Route, Routes} from "react-router-dom";
import {AnalyticsApi} from "./components/pages/AnalyticsApi";
import {AlertStatus} from "./components/pages/AlertStatus";
import {CompositeAlertStatus} from "./components/pages/CompositeAlertStatus";
import {Portfolios} from "./components/pages/Portfolios";
import {AnalyticsResources} from "./components/pages/AnalyticsResources";
import {Subscriptions} from "./components/pages/Subscriptions";

const ProtectedRoutes = () => {
    const {keycloak, initialized} = useKeycloak()
    if (initialized && !keycloak?.authenticated) {
        keycloak?.login()
    }

    return (
        <Fragment>
            <Routes>
                <Route index element={<AlertStatus/>}/>
                <Route path="api" element={<AnalyticsApi/>}/>
                <Route path="alertstatus" element={<AlertStatus/>}/>
                <Route path="compositealertstatus" element={<CompositeAlertStatus/>}/>
                <Route path="portfolios" element={<Portfolios/>}/>
                <Route path="resources" element={<AnalyticsResources/>}/>
                <Route path="subscriptions" element={<Subscriptions/>}/>
            </Routes>
            <Outlet/>
        </Fragment>
    )
}

export default ProtectedRoutes