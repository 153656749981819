import './index.css';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './reducers/store';
import App from './App';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import AuthenticationService, {authService} from "./services/AuthenticationService";

export const muiCache = createCache({
    'key': 'mui',
    'prepend': true
});

const root = createRoot(document.getElementById('root')!);

root.render(
    <ReactKeycloakProvider
        authClient={authService.keycloak()}
        onEvent={authService.onEvent}
        onTokens={authService.onTokens}
        initOptions={AuthenticationService.keycloakInitOptions()}>
        <Provider store={store}>
            <CacheProvider value={muiCache}>
                <App/>
            </CacheProvider>
        </Provider>
    </ReactKeycloakProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
