import {FC, useState} from 'react';
import {Autocomplete, Button, Grid, TextField} from '@mui/material';
import {csvDownloadElement, CsvData} from '../../services/utils';
import moment from 'moment-timezone';

export interface DownloadCsvProps {
    data: CsvData[],
    symbol: string,
    product?: string,
    quoteOnly?: boolean,
    isComposite?: boolean,
    showTimezone?: boolean,
    strategyName?: string | null,
    title?: string,
}

/**
 *
 * @param {*} props
 *  expected data format : ["Quote Data, Quote Price, Entry Date, Entry Price "]
 */

const DownloadCsv: FC<DownloadCsvProps> = (props) => {
    const {data = [], strategyName, symbol, product = "", showTimezone = true, title = '', isComposite = false} = props;

    const timezones = moment.tz.names();

    // eslint-disable-next-line
    const [_timezone, setTimezone] = useState('Europe/London');

    const setTimezoneHandler = (location) => {
        setTimezone(location);
    }

    const downloadOnClick = () => {

        const productStartDirection = product.includes("RAP") ? "Down" : "Up";

        const productName = isComposite ? `${product} Composite` : product;
        const csvPageHeader = ["AlgoDynamix", "", `Product: ${productName}`, "", `Instrument: ${symbol}`, ""]
        if (strategyName) {
            csvPageHeader.push(`Strategy: ${strategyName}`);
        }
        const csvTableHeader = ["", 'Historical Flags', `Instrument,${productStartDirection} flag date time,Indicative price at ${productStartDirection} flag, ,End flag date time,Indicative price at end flag `];

        // FIXME: when strategy is supported
        const downloadTitle = title === '' ? (strategyName ?? ''): title;
        const hiddenHTMLElement = csvDownloadElement(symbol, csvPageHeader, csvTableHeader, data, downloadTitle);
        if (hiddenHTMLElement == null) {
            alert('No Data Available')
        } else {
            hiddenHTMLElement.click();
        }
    }

    const buttonContent = title === '' ? 'Download' : `Download ${title}`;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {showTimezone &&
                <Autocomplete
                    id="timezones"
                    options={timezones}
                    style={{width: 300}}
                    defaultValue={'Europe/London'}
                    onChange={(_e, v) => setTimezoneHandler(v)}
                    renderInput={params => (
                        <TextField {...params} label='timezone' variant="outlined" fullWidth/>
                    )}
                    autoSelect={true}
                    clearOnEscape={true}
                    includeInputInList={true}
                />
                }
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={downloadOnClick}
                >
                    {buttonContent}
                </Button>
            </Grid>
        </Grid>
    )
}

export default DownloadCsv;