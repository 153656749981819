import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
const keycloak = Keycloak({
    clientId: 'analytics',
    realm: 'customer',
    "url": (process.env.NODE_ENV === "production") ?
        "https://auth.algodynamix.com/auth" : process.env.REACT_APP_AUTH_URL ?? "https://auth.algodynamix.com/auth",
});

export default keycloak;