import {Box, Container,} from '@mui/material';
import {ApiBaseUrl} from '../../../../App';

const PageContentTCs = () => {
    const baseUrlHref = ApiBaseUrl + "/";

    return (
        <Container maxWidth={"lg"}>
            <Box>
                <div>
                    <div>
                        <h3>Contents</h3>
                        <ol>
                            <li><a href="#_Toc446509160">THESE TERMS</a></li>
                            <li><a href="#_Toc446509161">The Service</a></li>
                            <li><a href="#_Toc446509162">Interpretation</a></li>
                            <li><a href="#_Toc446509163">User subscriptions</a></li>
                            <li><a href="#_Toc446509164">VIRUS PROTECTION</a></li>
                            <li><a href="#_Toc446509165">RESTRICTIONS</a></li>
                            <li><a href="#_Toc446509166">Additional user subscriptions</a></li>
                            <li><a href="#_Toc446509167">Services</a></li>
                            <li><a href="#_Toc446509168">Subscriber data</a></li>
                            <li><a href="#_Toc446509169">Log and Performance Data</a></li>
                            <li><a href="#_Toc446509170">Third party providers</a></li>
                            <li><a href="#_Toc446509171">Supplier's obligations</a></li>
                            <li><a href="#_Toc446509172">Subscriber's obligations</a></li>
                            <li><a href="#_Toc446509173">Charges and payment</a></li>
                            <li><a href="#_Toc446509174">Proprietary rights</a></li>
                            <li><a href="#_Toc446509175">Confidentiality</a></li>
                            <li><a href="#_Toc446509176">Indemnity</a></li>
                            <li><a href="#_Toc446509177">Limitation of liability</a></li>
                            <li><a href="#_Toc446509178">Term and termination</a></li>
                            <li><a href="#_Toc446509179">Force majeure</a></li>
                            <li><a href="#_Toc446509180">Conflict</a></li>
                            <li><a href="#_Toc446509181">Variation</a></li>
                            <li><a href="#_Toc446509182">Waiver</a></li>
                            <li><a href="#_Toc446509183">Rights and remedies</a></li>
                            <li><a href="#_Toc446509184">Severance</a></li>
                            <li><a href="#_Toc446509185">Entire agreement</a></li>
                            <li><a href="#_Toc446509186">Assignment</a></li>
                            <li><a href="#_Toc446509187">No partnership or agency</a></li>
                            <li><a href="#_Toc446509188">Third party rights</a></li>
                            <li><a href="#_Toc446509189">Notices</a></li>
                            <li><a href="#_Toc446509190">Governing law</a></li>
                            <li><a href="#_Toc446509191">Jurisdiction</a></li>
                        </ol>
                    </div>
                    <div>
                        <ol>
                            <li><span className="section" id="_Toc446509160">THESE TERMS</span>
                                <p><strong>
                                    THESE TERMS AND CONDITIONS ARE TO BE READ IN CONJUNCTION WITH YOUR PURCHASE
                                    DOCUMENTATION (INCLUDING PROOF OF CONCEPT DOCUMENTATION OR OTHER PROPOSAL
                                    DOCUMENTATION). BY COMMENCING ANY USE OF THE SERVICES YOU AGREE TO BE BOUND BY THESE
                                    TERMS AND CONDITIONS ON BEHALF OF THE ENTITY SET OUT IN THE ABOVE DOCUMENT(S). IF
                                    YOU DO NOT AGREE TO BE SO BOUND YOU MUST CEASE USING THE SERVICE IMMEDIATELY AND
                                    NOTIFY THE SUPPLIER.</strong></p>
                            </li>
                            <li><span className="section" id="_Toc446509161">The Service</span>
                                <p>AlgoDynamix provides access to and use of its software which enable subscribers to
                                    analyse market fluctuations and receive alerts as to market anomaly fluctuations.
                                    (the Services) The Services are provided to subscribers via the internet on a
                                    pay-per-use basis. </p>
                                <p>
                                    The Subscriber wishes to use the Supplier's service in its business operations.</p>
                                <p>
                                    The Supplier has agreed to provide and the Subscriber has agreed to take and pay for
                                    the Supplier's service subject to the terms and conditions of these terms and
                                    conditions and as set out in the Purchase Documentation.
                                </p>

                            </li>
                            <li><span className="section" id="_Toc446509162">Interpretation</span>
                                <p>The definitions and rules of interpretation in this Clause apply in these terms and
                                    conditions.<br/></p>
                                <dl>
                                    <dt>Authorised Users:</dt>
                                    <dd>those employees, agents and independent contractors of the Subscriber who are
                                        described in the Purchase Documentation and as further described in
                                        Clause 4.2(d).
                                    </dd>
                                    <dt>Business Day:</dt>
                                    <dd>a day other than a Saturday, Sunday or public holiday in England when banks in
                                        London are open for business.
                                    </dd>
                                    <dt>Change of Control:</dt>
                                    <dd>shall be as defined in section 1124 of the Corporation Tax Act 2010, and the
                                        expression change of control shall be construed accordingly OR the beneficial
                                        ownership of more than 50% of the issued share capital of a company or the
                                        legal power to direct or cause the direction of the general management of the
                                        company, and controls, controlled and the expression change of control shall
                                        be construed accordingly.
                                    </dd>
                                    <dt>Confidential Information:</dt>
                                    <dd>information that is proprietary or confidential and is either clearly labelled
                                        as such or identified as Confidential Information in Clause 16.5.
                                    </dd>
                                    <dt>Subscriber Data:</dt>
                                    <dd>the data inputted by the Subscriber, Authorised Users, or the Supplier on the
                                        Subscriber's behalf for the purpose of using the Services or facilitating the
                                        Subscriber's use of the Services.
                                    </dd>
                                    <dt>Documentation:</dt>
                                    <dd>the document made available to the Subscriber by the Supplier online via <a
                                        href={baseUrlHref}>https://analytics.algodynamix.com/</a> or such other web
                                        address notified by the Supplier to the Subscriber from time to time which sets
                                        out a description of the Services and the user instructions for the Services.
                                    </dd>
                                    <dt>Effective Date:</dt>
                                    <dd>the commencement date set out in the Purchase Documents.</dd>
                                    <dt>Initial Subscription Term:</dt>
                                    <dd>the initial term set out in the Purchase Documents, unless agreed otherwise the
                                        Initial Subscription Term means a minimum period of twelve (12) months from the
                                        Effective Date.
                                    </dd>
                                    <dt>Log Files:</dt>
                                    <dd>data and information created which logs the use and performance of the Services.
                                        Which shall not include any personal data.
                                    </dd>
                                    <dt>Normal Business Hours:</dt>
                                    <dd>8.00 am to 6.00 pm local UK time, each Business Day.</dd>
                                    <dt>Purchase Documents:</dt>
                                    <dd>all invoices, specification, purchaser orders and specifications which set out
                                        the agreed Subscription Period, the Price, number and nature of any Authorised
                                        Users and the Location (if applicable) at which the service may be accessed
                                        together with any renewal period or other terms agreed between the parties.
                                    </dd>
                                    <dt>Renewal Period:</dt>
                                    <dd>the period described in any Purchase Documents, unless greed otherwise, Renewal
                                        Period is twelve (12) months.
                                    </dd>
                                    <dt>Services:</dt>
                                    <dd>the subscription services provided by the Supplier to the Subscriber under these
                                        terms and conditions at
                                        <a href={baseUrlHref}>https://analytics.algodynamix.com/</a> or any other
                                        website notified to the Subscriber by the Supplier from time to time, as more
                                        particularly described in the Documentation.
                                    </dd>
                                    <dt>Software:</dt>
                                    <dd>the online software applications provided by the Supplier as part of the
                                        Services.
                                    </dd>
                                    <dt>Subscription Fees:</dt>
                                    <dd>the subscription fees payable by the Subscriber to the Supplier for the User
                                        Subscriptions, as set out
                                        in the Purchase Documents.
                                    </dd>
                                    <dt>Subscription Term:</dt>
                                    <dd>has the meaning given in Clause 19.1 (being the Initial Subscription Term
                                        together with any subsequent Renewal Periods).
                                    </dd>
                                    <dt>Support Services Policy:</dt>
                                    <dd>the Supplier's policy for providing support in relation to the Services as may
                                        be notified to the Subscriber from time to time.
                                    </dd>
                                    <dt>User Subscriptions:</dt>
                                    <dd>the user subscriptions purchased by the Subscriber pursuant to Clause 14 which
                                        entitle Authorised Users to access and use the Services and the Documentation
                                        in accordance with these terms and the Purchase Documents.
                                    </dd>
                                    <dt>Virus:</dt>
                                    <dd>any thing or device (including any software, code, file or programme) which may:
                                        prevent, impair or otherwise adversely affect the operation of any computer
                                        software, hardware or network, any telecommunications service, equipment or
                                        network or any other service or device; prevent, impair or otherwise adversely
                                        affect access to or the operation of any programme or data, including the
                                        reliability of any programme or data (whether by re-arranging, altering or
                                        erasing the programme or data in whole or part or otherwise); or adversely
                                        affect the user experience, including worms, trojan horses, viruses and other
                                        similar things or devices.
                                    </dd>
                                </dl>
                                <p>Clause, schedule and paragraph headings shall not affect the interpretation of these
                                    terms and conditions.</p>
                                <p>A person includes an individual, corporate or unincorporated body (whether or not
                                    having separate legal personality) [and that person's legal and personal
                                    representatives, successors or permitted assigns].</p>
                                <p>A reference to a company shall include any company, corporation or other body
                                    corporate, wherever and however incorporated or established.</p>
                                <p>Unless the context otherwise requires, words in the singular shall include the plural
                                    and in the plural shall include the singular.</p>
                                <p>Unless the context otherwise requires, a reference to one gender shall include a
                                    reference to the other genders.</p>
                                <p>A reference to a statute or statutory provision is a reference to it as it is in
                                    force as at the date of these terms and conditions.</p>
                                <p>A reference to a statute or statutory provision shall include all subordinate
                                    legislation made as commencement of the subscription term under that statute or
                                    statutory provision.</p>
                                <p>A reference to writing or written includes faxes but not e-mail.</p>
                                <p>References to Clauses and schedules are to the Clauses and schedules of these terms
                                    and conditions; references to paragraphs are to paragraphs of the relevant schedule
                                    to these terms and conditions.</p>
                            </li>
                            <li><span className="section" id="_Toc446509163">User subscriptions</span>
                                <ol>
                                    <li>Subject to the Subscriber purchasing the User Subscriptions in accordance with
                                        Clause 7.3 and Clause 14, the restrictions set out in this Clause 4 and the
                                        other provision of these terms and conditions , the Supplier hereby grants to
                                        the Subscriber a non-exclusive, non-transferable right to permit the
                                        Authorised Users to use the Services and the Documentation during the
                                        Subscription Term solely for the Subscriber's internal business operations.
                                    </li>
                                    <li>In relation to the Authorised Users, the Subscriber undertakes that:
                                        <ol>
                                            <li>the maximum number of Authorised Users that it authorises to access and
                                                use the Services and the Documentation shall not exceed the number of
                                                User Subscriptions it has purchased from time to time;
                                            </li>
                                            <li>it will not allow or suffer any User Subscription to be used by more
                                                than one individual Authorised User unless it has been reassigned in
                                                its entirety to another individual Authorised User, in which case the
                                                prior Authorised User shall no longer have any right to access or use
                                                the Services and/or Documentation;
                                            </li>
                                            <li>each Authorised User shall keep a secure password for his use of the
                                                Services and Documentation, that such password shall be changed no less
                                                quarterly and that each Authorised User shall keep his password
                                                confidential;
                                            </li>
                                            <li>it shall maintain a written, up to date list of current Authorised Users
                                                and provide such list to the Supplier within 5 Business Days of the
                                                Supplier's written request at any time or times;
                                            </li>
                                            <li>it shall permit the Supplier to audit the Services in order to establish
                                                the name and password of each Authorised User. Such audit may be
                                                conducted no more than once per quarter, at the Supplier's expense, and
                                                this right shall be exercised with reasonable prior notice, in such a
                                                manner as not to substantially interfere with the Subscriber's normal
                                                conduct of business;
                                            </li>
                                            <li>if any of the audits referred to in Clause 2.2(e) reveal that any
                                                password has been provided to any individual who is not an Authorised
                                                User, then without prejudice to the Supplier's other rights, the
                                                Subscriber shall promptly disable such passwords and the Supplier shall
                                                not issue any new passwords to any such individual; and
                                            </li>
                                            <li>if any of the audits referred to in Clause 2.2(e) reveal that the
                                                Subscriber has underpaid Subscription Fees to the Supplier, then
                                                without prejudice to the Supplier's other rights, the Subscriber shall
                                                pay to the Supplier an amount equal to such underpayment as calculated
                                                in accordance with the prices set out in paragraph 1 of Schedule 1
                                                within [10] Business Days of the date of the relevant audit.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509164">VIRUS PROTECTION</span>
                                <ol>
                                    <li>The Subscriber shall not access, store, distribute or transmit any Viruses, or
                                        any material during the course of its use of the Services that:
                                        <div>
                                            <ol>
                                                <li>is unlawful, harmful, threatening, defamatory, obscene, infringing,
                                                    harassing or racially or ethnically offensive;
                                                </li>
                                                <li>facilitates illegal activity;</li>
                                                <li>depicts sexually explicit images;</li>
                                                <li>promotes unlawful violence;</li>
                                                <li>is discriminatory based on race, gender, colour, religious belief,
                                                    sexual orientation, disability; or
                                                </li>
                                                <li>in a manner that is otherwise illegal or causes damage or injury to
                                                    any person or property;
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                </ol>
                                <p>and the Supplier reserves the right, without liability or prejudice to its other
                                    rights to the Subscriber, to disable the Subscriber's access to any material that
                                    breaches the provisions of this Clause.</p>
                            </li>
                            <li><span className="section" id="_Toc446509165"><em>RESTRICTIONS</em></span>
                                <ol>
                                    <li><em>
                                        Except as may be allowed by any applicable law which is incapable of exclusion
                                        by agreement between the parties: and except to the extent expressly permitted
                                        under these terms and conditions. </em></li>
                                    <li><em>The Subscriber shall not;</em>
                                        <ol>
                                            <li>copy or attempt to copy, modify, duplicate, create derivative works
                                                from, frame, mirror, republish, download, display, transmit, or
                                                distribute all or any portion of the Software and/or Documentation
                                                (as applicable) in any form or media or by any means; or
                                            </li>
                                            <li>attempt to reverse compile, disassemble, reverse engineer or otherwise
                                                reduce to human-perceivable form all or any part of the Software; or
                                            </li>
                                            <li>access all or any part of the Services and Documentation in order to
                                                build a product or service which competes with the Services and/or the
                                                Documentation; or
                                            </li>
                                            <li>use the Services and/or Documentation to provide services to third
                                                parties; or
                                            </li>
                                            <li>subject to Clause 27.1, license, sell, rent, lease, transfer, assign,
                                                distribute, display, disclose, or otherwise commercially exploit, or
                                                otherwise make the Services and/or Documentation available to
                                                any third party except the Authorised Users, or
                                            </li>
                                            <li>attempt to obtain, or assist third parties in obtaining, access to the
                                                Services and/or Documentation, other than as provided under this
                                                Clause 4; and
                                            </li>
                                        </ol>
                                    </li>
                                    <li>The Subscriber shall use all reasonable endeavours to prevent any unauthorised
                                        access to, or use of, the Services and/or the Documentation and, in the event
                                        of any such unauthorised access or use, promptly notify the Supplier.
                                    </li>
                                    <li>The rights provided under this Clause 4 are granted to the Subscriber only, and
                                        shall not be considered granted to any subsidiary or holding company of the
                                        Subscriber.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509166">Additional user subscriptions</span>
                                <ol>
                                    <li>Subject to Clause 7.2 and Clause 7.3, the Subscriber may, from time to time
                                        during any Subscription Term, purchase additional User Subscriptions in excess
                                        of the number set out in the Purchase Documents and the Supplier shall grant
                                        access to the Services and the Documentation to such additional Authorised
                                        Users in accordance with the provisions of these terms and conditions.
                                    </li>
                                    <li>If the Subscriber wishes to purchase additional User Subscriptions, the
                                        Subscriber shall notify the Supplier in writing. The Supplier shall evaluate
                                        such request for additional User Subscriptions and respond to the Subscriber
                                        with approval or rejection of the request (such approval not to be unreasonably
                                        withheld).
                                    </li>
                                    <li>If the Supplier approves the Subscriber's request to purchase additional User
                                        Subscriptions, the Subscriber shall, within 30 days of the date of the
                                        Supplier's invoice, pay to the Supplier the relevant fees for such additional
                                        User Subscriptions as set out in the Purchase Documents if such additional User
                                        Subscriptions are purchased by the Subscriber part way through the Initial
                                        Subscription Term or any Renewal Period (as applicable), such fees shall be
                                        pro-rated for the remainder of the Initial Subscription Term or then current
                                        Renewal Period (as applicable).
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509167">Services</span>
                                <ol>
                                    <li>The Supplier shall, during the Subscription Term, provide the Services and make
                                        available the Documentation to the Subscriber on and subject to these terms and
                                        conditions and any additional terms which are set out in the Purchase Documents.
                                    </li>
                                    <li>The Supplier shall use commercially reasonable endeavours to make the Services
                                        available 24 hours a day, seven days a week, except for:
                                    </li>
                                    <li>planned maintenance carried out during the maintenance window of 10.00 pm to
                                        2.00 am UK time; and
                                    </li>
                                    <li>unscheduled maintenance performed outside Normal Business Hours, provided that
                                        the Supplier has used reasonable endeavours to give the Subscriber at least 6
                                        Normal Business Hours' notice in advance.
                                    </li>
                                </ol>
                                <p>The Supplier will, as part of the Services and at no additional cost to the
                                    Subscriber, provide the Subscriber with the Supplier's standard Subscriber support
                                    services during Normal Business Hours in accordance with the Supplier's Support
                                    Services Policy in effect at the time that the Services are provided. The Supplier
                                    may amend the Support Services Policy in its sole and absolute discretion from time
                                    to time. The Subscriber may purchase enhanced support services separately at the
                                    Supplier's then current rates.</p>
                            </li>
                            <li><span className="section" id="_Toc446509168">Subscriber data</span>
                                <ol>
                                    <li>The Subscriber shall own all right, title and interest in and to all of the
                                        Subscriber Data and shall have sole responsibility for the legality,
                                        reliability, integrity, accuracy and quality of the Subscriber Data.
                                    </li>
                                    <li>The Supplier shall be responsible for maintaining preserving and backing up all
                                        Subscriber Data follow its archiving procedures for Subscriber Data as set out
                                        in its documents that may be amended by the Supplier in its sole discretion
                                        rom time to time.
                                    </li>
                                    <li>In the event of any loss or damage to Subscriber Data, the Subscriber's sole and
                                        exclusive remedy shall be for the Supplier to use reasonable commercial
                                        endeavours to restore the lost or damaged Subscriber Data from the latest
                                        back-up of such Subscriber Data maintained by the Supplier. The Supplier shall
                                        not be responsible for any loss, destruction, alteration or disclosure of
                                        Subscriber Data caused by any third party (except those third parties
                                        sub-contracted by the Supplier to perform services related to Subscriber Data
                                        maintenance and back-up).
                                    </li>
                                    <li>The Supplier shall, in providing the Services, comply with its Policy relating
                                        to the privacy and security of the Subscriber Data, such documents may be
                                        amended from time to time by the Supplier in its sole discretion.
                                    </li>
                                    <li>If the Supplier processes any personal data on the Subscriber's behalf when
                                        performing its obligations under these terms and conditions, the parties record
                                        their intention that the Subscriber shall be the data controller and the
                                        Supplier shall be a data processor and in any such case:
                                    </li>
                                    <li>the Subscriber acknowledges and agrees that the personal data may be transferred
                                        or stored outside the EEA or the country where the Subscriber and the Authorised
                                        Users are located in order to carry out the Services and the Supplier's other
                                        obligations under these terms and conditions;
                                    </li>
                                    <li>the Subscriber shall ensure that the Subscriber is entitled to transfer the
                                        relevant personal data to the Supplier so that the Supplier may lawfully use,
                                        process and transfer the personal data in accordance with these terms and
                                        conditions on the Subscriber's behalf;
                                    </li>
                                    <li>the Subscriber shall ensure that the relevant third parties have been informed
                                        of, and have given their consent to, such use, processing, and transfer as
                                        required by all applicable data protection legislation;
                                    </li>
                                    <li>the Supplier shall process the personal data only in accordance with the terms
                                        of these terms and conditions and any lawful instructions reasonably given by
                                        the Subscriber from time to time; and]
                                    </li>
                                    <li>each party shall take appropriate technical and organisational measures against
                                        unauthorised or unlawful processing of the personal data or its accidental loss,
                                        destruction or damage.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509169">Log and Performance Data.</span>
                                <p>The Supplier shall be entitled to maintain logs and records of all use made of the
                                    Service by the Subscriber such data may be used to track faults and improve the
                                    quality of the service provided to the Subscriber. </p>
                            </li>
                            <li><span className="section" id="_Toc446509170">Third party providers</span>
                                <p>The Subscriber acknowledges that the Services may enable or assist it to access the
                                    website content of, correspond with, and purchase products and services from, third
                                    parties via third-party websites and that it does so solely at its own risk.</p>
                                <p>The Supplier makes no representation or commitment and shall have no liability or
                                    obligation whatsoever in relation to the content or use of, or correspondence with,
                                    any such third-party website, or any transactions completed, and any contract
                                    entered into by the Subscriber, with any such third party. </p>
                                <p>Any contract entered into and any transaction completed via any third-party website
                                    is between the Subscriber and the relevant third party, and not the Supplier. The
                                    Supplier recommends that the Subscriber refers to the third party's website terms
                                    and conditions and privacy policy prior to using the relevant third-party website.
                                    The Supplier does not endorse or approve any third-party website nor the content of
                                    any of the third-party website made available via the Services.</p>
                            </li>
                            <li><span className="section" id="_Toc446509171">Supplier's obligations</span>
                                <ol>
                                    <li>The Supplier undertakes that the Services will be performed substantially in
                                        accordance with the Documentation and with reasonable skill and care.
                                    </li>
                                    <li>The undertaking at Clause 12.1 shall not apply to the extent of any
                                        non-conformance which is caused by use of the Services contrary to the
                                        Supplier's instructions, or modification or alteration of the Services by any
                                        party other than the Supplier or the Supplier's duly authorised contractors or
                                        gents.
                                    </li>
                                    <li> If the Services do not conform with the foregoing undertaking, Supplier will,
                                        at its expense, use all reasonable commercial endeavours to correct any such
                                        non-conformance promptly, or provide the Subscriber with an alternative means
                                        of accomplishing the desired performance. Such correction or substitution
                                        constitutes the Subscriber's sole and exclusive remedy for any breach of the
                                        undertaking set out in Clause 12.1. Notwithstanding the foregoing, the Supplier:
                                    </li>
                                    <li>does not warrant that the Subscriber's use of the Services will be uninterrupted
                                        or error-free; or that the Services, Documentation and/or the information
                                        obtained by the Subscriber through the Services will meet the Subscriber's
                                        requirements; and
                                    </li>
                                    <li>is not responsible for any delays, delivery failures, or any other loss or
                                        damage resulting from the transfer of data over communications networks and
                                        facilities, including the internet, and the Subscriber acknowledges that the
                                        Services and Documentation may be subject to limitations, delays and other
                                        problems inherent in the use of such communications facilities.
                                    </li>
                                    <li>These terms and conditions shall not prevent the Supplier from entering into
                                        similar agreements with third parties, or from independently developing, using,
                                        selling or licensing documentation, products and/or services which are similar
                                        to those provided under these terms and conditions.
                                    </li>
                                    <li>The Supplier warrants that it has and will maintain all necessary licences,
                                        consents, and permissions necessary for the performance of its obligations under
                                        these terms and conditions.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509172">Subscriber's obligations</span>
                                <ol>
                                    <li>The Subscriber shall provide the Supplier with:
                                        <ol>
                                            <li>all necessary co-operation in relation to these terms and conditions;
                                                and
                                            </li>
                                            <li>all necessary access to such information as may be required by the
                                                Supplier in order to provide the Services, including but not limited to
                                                Subscriber Data, security access information and configuration data and
                                                information;
                                            </li>
                                            <li>comply with all applicable laws and regulations with respect to its
                                                activities under these terms and conditions;
                                            </li>
                                            <li>carry out all other Subscriber responsibilities set out in these terms
                                                and conditions in a timely and efficient manner. In the event of any
                                                delays in the Subscriber's provision of such assistance as agreed by the
                                                parties, the Supplier may adjust any agreed timetable or delivery
                                                schedule as reasonably necessary;
                                            </li>
                                            <li>ensure that the Authorised Users use the Services and the Documentation
                                                in accordance with the terms and conditions of these terms and
                                                conditions and shall be responsible for any Authorised User's breach of
                                                these terms and conditions;
                                            </li>
                                            <li>obtain and shall maintain all necessary licences, consents, and
                                                permissions necessary for the Supplier, its contractors and agents to
                                                perform their obligations under these terms and conditions, including
                                                without limitation the Services;
                                            </li>
                                            <li>ensure that its network and systems comply with the relevant
                                                specifications provided by the Supplier from time to time; and
                                            </li>
                                            <li>be solely responsible for procuring and maintaining its network
                                                connections and telecommunications links from its systems to the
                                                Supplier's data centres, and all problems, conditions, delays, delivery
                                                failures and all other loss or damage arising from or relating to the
                                                Subscriber's network connections or telecommunications links or caused
                                                by the internet.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509173">Charges and payment</span>
                                <ol>
                                    <li>The Subscriber shall pay
                                        <ol>
                                            <li>the Subscription Fees to the Supplier for the User Subscriptions in
                                                accordance with this Clause 14 and the Purchase Documents.
                                            </li>
                                            <li>subject to Clause 19.1, at least 30 days prior to each anniversary of
                                                the Effective Date for the Subscription Fees payable in respect of the
                                                next Renewal Period,
                                            </li>
                                            <li>each invoice within 30 days after the date of such invoice.</li>
                                        </ol>
                                    </li>
                                    <li>If the Supplier has not received payment within 30 days after the due date, and
                                        without prejudice to any other rights and remedies of the Supplier:
                                    </li>
                                    <li>the Supplier may, without liability to the Subscriber, disable the Subscriber's
                                        password, account and access to all or part of the Services and the Supplier
                                        shall be under no obligation to provide any or all of the Services while the
                                        invoice(s) concerned remain unpaid; and
                                    </li>
                                    <li>interest shall accrue on a daily basis on such due amounts at an annual rate
                                        equal to 3% over the then current base lending rate of [the Supplier's bankers
                                        in the UK] from time to time, commencing on the due date and continuing until
                                        fully paid, whether before or after judgment.
                                    </li>
                                    <li>All amounts and fees stated or referred to in these terms and conditions
                                        <ol>
                                            <li>shall be payable in pounds sterling;</li>
                                            <li>are, subject to Clause 18.8(b), non-cancellable and non-refundable;</li>
                                            <li>are exclusive of value added tax, which shall be added to the Supplier's
                                                invoice(s) at the appropriate rate.
                                            </li>
                                            <li>If, at any time whilst using the Services, the Subscriber exceeds the
                                                amount of disk storage space specified in the Documentation, the
                                                Supplier shall charge the Subscriber, and the Subscriber shall pay, the
                                                Supplier's then current excess data storage fees. The Supplier's excess
                                                data storage fees current as at the
                                            </li>
                                        </ol>
                                    </li>
                                    <li>The Supplier shall be entitled to increase the Subscription Fees, the fees
                                        payable in respect of the additional User Subscriptions purchased pursuant to
                                        Clause 6.3 at the start of each Renewal Period upon 90 days' prior notice to the
                                        Subscriber and the Purchase Documents shall be amended accordingly.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509174">Proprietary rights</span>
                                <ol>
                                    <li>The Subscriber acknowledges and agrees that the Supplier and/or its licensors
                                        own all intellectual property rights in the Services and the Documentation.
                                    </li>
                                    <li>Except as expressly stated herein, these terms and conditions does not grant the
                                        Subscriber any rights to, or in, patents, copyright, database right, trade
                                        secrets, trade names, trade marks (whether registered or unregistered), or any
                                        other rights or licences in respect of the Services or the Documentation.
                                    </li>
                                    <li>The Supplier confirms that it has all the rights in relation to the Services and
                                        the Documentation that are necessary to grant all the rights it purports to
                                        grant under, and in accordance with, the terms of these terms and conditions.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509175">Confidentiality</span>
                                <ol>
                                    <li>Each party may be given access to Confidential Information from the other party
                                        in order to perform its obligations under these terms and conditions. A party's
                                        Confidential Information shall not be deemed to include information that:
                                        <ol>
                                            <li>is or becomes publicly known other than through any act or omission of
                                                the receiving party;
                                            </li>
                                            <li>was in the other party's lawful possession before the disclosure;</li>
                                            <li>is lawfully disclosed to the receiving party by a third party without
                                                restriction on disclosure;
                                            </li>
                                            <li>is independently developed by the receiving party, which independent
                                                development can be shown by written evidence; or
                                            </li>
                                            <li>is required to be disclosed by law, by any court of competent
                                                jurisdiction or by any regulatory or administrative body.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>Each party shall hold the other's Confidential Information in confidence and,
                                        unless required by law, not make the other's Confidential Information available
                                        to any third party, or use the other's Confidential Information for any purpose
                                        other than the implementation of these terms and conditions.
                                    </li>
                                    <li>Each party shall take all reasonable steps to ensure that the other's
                                        Confidential Information to which it has access is not disclosed or distributed
                                        by its employees or agents in violation of the terms of these terms and
                                        conditions.
                                    </li>
                                    <li>Neither party shall be responsible for any loss, destruction, alteration or
                                        disclosure of Confidential Information caused by any third party.
                                    </li>
                                    <li>The Subscriber acknowledges that details of the Services, and the results of any
                                        performance tests of the Services, constitute the Supplier's Confidential
                                        Information.
                                    </li>
                                    <li>The Supplier acknowledges that the Subscriber Data is the Confidential
                                        Information of the Subscriber.
                                    </li>
                                    <li>This Clause 15 shall survive termination of these terms and conditions , however
                                        arising.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509176">Indemnity</span>
                                <ol>
                                    <li>The Subscriber shall defend, indemnify and hold harmless the Supplier against
                                        claims, actions, proceedings, losses, damages, expenses and costs (including
                                        without limitation court costs and reasonable legal fees) arising out of or in
                                        connection with the Subscriber's use of the Services and/or Documentation,
                                        provided that:
                                        <ol>
                                            <li>the Subscriber is given prompt notice of any such claim;</li>
                                            <li>the Supplier provides reasonable co-operation to the Subscriber in the
                                                defence and settlement of such claim, at the Subscriber's expense; and
                                            </li>
                                            <li>the Subscriber is given sole authority to defend or settle the claim.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>The Supplier shall defend the Subscriber, its officers, directors and employees
                                        against any claim that the Services or Documentation infringes any United
                                        Kingdom patent effective as of the Effective Date, copyright, trade mark,
                                        database right or right of confidentiality, and shall indemnify the Subscriber
                                        for any amounts awarded against the Subscriber in judgment or settlement of such
                                        claims, provided that:
                                        <ol>
                                            <li>the Supplier is given prompt notice of any such claim;</li>
                                            <li>the Subscriber provides reasonable co-operation to the Supplier in the
                                                defence and settlement of such claim, at the Supplier's expense; and
                                            </li>
                                            <li>the Supplier is given sole authority to defend or settle the claim.</li>
                                            <li>In the defence or settlement of any claim, the Supplier may procure the
                                                right for the Subscriber to continue using the Services, replace or
                                                modify the Services so that they become non-infringing or, if such
                                                remedies are not reasonably available, terminate these terms and
                                                conditions on 2 Business Days' notice to the Subscriber without any
                                                additional liability or obligation to pay liquidated damages or other
                                                additional costs to the Subscriber.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>In no event shall the Supplier, its employees, agents and sub-contractors be
                                        liable to the Subscriber to the extent that the alleged infringement is based
                                        on:
                                        <ol>
                                            <li>a modification of the Services or Documentation by anyone other than the
                                                Supplier; or
                                            </li>
                                            <li>the Subscriber's use of the Services or Documentation in a manner
                                                contrary to the instructions given to the Subscriber by the Supplier; or
                                            </li>
                                            <li><strong>any failure by the Services to identify any anomaly or
                                                circumstance in any market or system </strong></li>
                                            <li>the Subscriber's use of the Services or Documentation after notice of
                                                the alleged or actual infringement from the Supplier or any appropriate
                                                authority.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>The foregoing and Clause 18.8(b) state[s] the Subscriber's sole and exclusive
                                        rights and remedies, and the Supplier's (including the Supplier's employees',
                                        agents' and sub-contractors') entire obligations and liability, for infringement
                                        of any patent, copyright, trade mark, database right or right of
                                        confidentiality.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509177">Limitation of liability</span>
                                <ol>
                                    <li>This Clause 18 sets out the entire financial liability of the Supplier
                                        (including any liability for the acts or omissions of its employees, agents and
                                        sub-contractors) to the Subscriber:
                                    </li>
                                    <li>arising under or in connection with these terms and conditions;</li>
                                    <li>in respect of any use made by the Subscriber of the Services and Documentation
                                        or any part of them; and
                                    </li>
                                    <li>in respect of any representation, statement or tortious act or omission
                                        (including negligence) arising under or in connection with these terms and
                                        conditions.
                                    </li>
                                    <li>Except as expressly and specifically provided in these terms and conditions:
                                        <ol>
                                            <li>the Subscriber assumes sole responsibility for results obtained from the
                                                use of the Services and the Documentation by the Subscriber, and for
                                                conclusions drawn from such use. The Supplier shall have no liability
                                                for any damage caused by errors or omissions in any information,
                                                instructions or scripts provided to the Supplier by the Subscriber in
                                                connection with the Services, or any actions taken by the Supplier at
                                                the Subscriber's direction;
                                            </li>
                                            <li>all warranties, representations, conditions and all other terms of any
                                                kind whatsoever implied by statute or common law are, to the fullest
                                                extent permitted by applicable law, excluded from these terms and
                                                conditions; and
                                            </li>
                                        </ol>
                                    </li>
                                    <li>the Services and the Documentation are provided to the Subscriber on an &quot;as
                                        is&quot; basis.
                                    </li>
                                    <li>Nothing in these terms and conditions excludes the liability of the Supplier for
                                        death or personal injury caused by the Supplier's negligence; or for fraud or
                                        fraudulent misrepresentation.
                                    </li>
                                    <li>Subject to Clause 18.5 and Clause 18.7:
                                        <ol>
                                            <li>the Supplier shall not be liable whether in tort (including for
                                                [negligence or] breach of statutory duty), contract, misrepresentation,
                                                restitution or otherwise for any loss of profits, loss of business,
                                                depletion of goodwill and/or similar losses or loss or corruption of
                                                data or information, or pure economic loss, or for any special, indirect
                                                or consequential loss, costs, damages, charges or expenses however
                                                arising under these terms and conditions; and
                                            </li>
                                            <li>the Supplier's total aggregate liability in contract [(including in
                                                respect of the indemnity at Clause 17.2), tort (including negligence or
                                                breach of statutory duty), misrepresentation, restitution or otherwise,
                                                arising in connection with the performance or contemplated performance
                                                of these terms and conditions shall be limited to the total Subscription
                                                Fees paid for the User Subscriptions during the 12 months immediately
                                                preceding the date on which the claim arose.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509178">Term and termination</span>
                                <ol>
                                    <li>These terms and conditions shall, unless otherwise terminated as provided in
                                        this Clause 19, commence on the Effective Date and shall continue for the
                                        Initial Subscription Term and, thereafter, these terms and conditions shall be
                                        automatically renewed for successive periods of 12 months (each a Renewal
                                        Period), unless:
                                    </li>
                                    <li>either party notifies the other party of termination, in writing, at least 60
                                        days before the end of the Initial Subscription Term or any Renewal Period, in
                                        which case these terms and conditions shall terminate upon the expiry of the
                                        applicable Initial Subscription Term or Renewal Period; or
                                    </li>
                                    <li>otherwise terminated in accordance with the provisions of these terms and
                                        conditions;
                                    </li>
                                    <li>and the Initial Subscription Term together with any subsequent Renewal Periods
                                        shall constitute the Subscription Term.
                                    </li>
                                    <li>Without affecting any other right or remedy available to it, either party may
                                        terminate these terms and conditions with immediate effect by giving written
                                        notice to the other party if:
                                        <ol>
                                            <li>[the other party fails to pay any amount due under these terms and
                                                conditions on the due date for payment and remains in default not less
                                                than 14 days after being notified in writing to make such payment;
                                            </li>
                                            <li>the other party commits a material breach of any other term of these
                                                terms and conditions which breach is irremediable or (if such breach is
                                                remediable) fails to remedy that breach within a period of 30 days after
                                                being notified in writing to do so;
                                            </li>
                                            <li>the other party repeatedly breaches any of the terms of these terms and
                                                conditions in such a manner as to reasonably justify the opinion that
                                                its conduct is inconsistent with it having the intention or ability to
                                                give effect to the terms of these terms and conditions;
                                            </li>
                                            <li>the other party suspends, or threatens to suspend, payment of its debts
                                                or is unable to pay its debts as they fall due or admits inability to
                                                pay its debts or is deemed unable to pay its debts within the meaning of
                                                section 123 of the Insolvency Act 1986;
                                            </li>
                                            <li>the other party commences negotiations with all or any class of its
                                                creditors with a view to rescheduling any of its debts, or makes a
                                                proposal for or enters into any compromise or arrangement with its
                                                creditors other than for the sole purpose of a scheme for a solvent
                                                amalgamation of that other party with one or more other companies or the
                                                solvent reconstruction of that other party;
                                            </li>
                                            <li>a petition is filed, a notice is given, a resolution is passed, or an
                                                order is made, for or in connection with the winding up of that other
                                                party other than for the sole purpose of a scheme for a solvent
                                                amalgamation of that other party with one or more other companies or the
                                                solvent reconstruction of that other party;
                                            </li>
                                            <li>an application is made to court, or an order is made, for the
                                                appointment of an administrator, or if a notice of intention to appoint
                                                an administrator is given or if an administrator is appointed, over the
                                                other party;
                                            </li>
                                            <li>the holder of a qualifying floating charge over the assets of that other
                                                party has become entitled to appoint or has appointed an administrative
                                                receiver;
                                            </li>
                                            <li>a person becomes entitled to appoint a receiver over the assets of the
                                                other party or a receiver is appointed over the assets of the other
                                                party;
                                            </li>
                                            <li>a creditor or encumbrancer of the other party attaches or takes
                                                possession of, or a distress, execution, sequestration or other such
                                                process is levied or enforced on or sued against, the whole or any part
                                                of the other party's assets and such attachment or process is not
                                                discharged within 14 days;
                                            </li>
                                            <li>any event occurs, or proceeding is taken, with respect to the other
                                                party in any jurisdiction to which it is subject that has an effect
                                                equivalent or similar to any of the events mentioned in Clause 19.5(d)
                                                to Clause 19.5(j) (inclusive);
                                            </li>
                                            <li>the other party suspends or ceases, or threatens to suspend or cease,
                                                carrying on all or a substantial part of its business; [or]
                                            </li>
                                            <li>there is a change of control of the other party; or</li>
                                        </ol>
                                    </li>
                                    <li>On termination of these terms and conditions for any reason:
                                        <ol>
                                            <li>all licences granted under these terms and conditions shall immediately
                                                terminate;
                                            </li>
                                            <li>each party shall return and make no further use of any equipment,
                                                property, Documentation and other items (and all copies of them)
                                                belonging to the other party;
                                            </li>
                                            <li>the Supplier may destroy or otherwise dispose of any of the Subscriber
                                                Data in its possession unless the Supplier receives, no later than ten
                                                days after the effective date of the termination of these terms and
                                                conditions, a written request for the delivery to the Subscriber of the
                                                then most recent back-up of the Subscriber Data. The Supplier shall use
                                                reasonable commercial endeavours to deliver the back-up to the
                                                Subscriber within 30 days of its receipt of such a written request,
                                                provided that the Subscriber has, at that time, paid all fees and
                                                charges outstanding at and resulting from termination (whether or not
                                                due at the date of termination). The Subscriber shall pay all reasonable
                                                expenses incurred by the Supplier in returning or disposing of
                                                Subscriber Data; and
                                            </li>
                                            <li>any rights, remedies, obligations or liabilities of the parties that
                                                have accrued up to the date of termination, including the right to claim
                                                damages in respect of any breach of the agreement which existed at or
                                                before the date of termination shall not be affected or prejudiced.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509179">Force majeure</span>
                                <p>The Supplier shall have no liability to the Subscriber under these terms and
                                    conditions if it is prevented from or delayed in performing its obligations under
                                    these terms and conditions , or from carrying on its business, by acts, events,
                                    omissions or accidents beyond its reasonable control, including, without limitation,
                                    strikes, lock-outs or other industrial disputes (whether involving the workforce of
                                    the Supplier or any other party), failure of a utility service or transport or
                                    telecommunications network, act of God, war, riot, civil commotion, malicious
                                    damage, compliance with any law or governmental order, rule, regulation or
                                    direction, accident, breakdown of plant or machinery, fire, flood, storm or default
                                    of suppliers or sub-contractors, provided that the Subscriber is notified of such an
                                    event and its expected duration.</p>
                            </li>
                            <li><span className="section" id="_Toc446509180">Conflict</span>
                                <p>If there is an inconsistency between any of the provisions in the main body of these
                                    terms and conditions and the Schedules, the provisions in the main body of these
                                    terms and conditions shall prevail.</p>
                            </li>
                            <li><span className="section" id="_Toc446509181">Variation</span>
                                <p>No variation of these terms and conditions shall be effective unless it is in writing
                                    and signed by the parties (or their authorised representatives).</p>
                            </li>
                            <li><span className="section" id="_Toc446509182">Waiver</span>
                                <p>No failure or delay by a party to exercise any right or remedy provided under these
                                    terms and conditions or by law shall constitute a waiver of that or any other right
                                    or remedy, nor shall it prevent or restrict the further exercise of that or any
                                    other right or remedy. No single or partial exercise of such right or remedy shall
                                    prevent or restrict the further exercise of that or any other right or remedy.</p>
                            </li>
                            <li><span className="section" id="_Toc446509183">Rights and remedies</span>
                                <p>Except as expressly provided in these terms and conditions, the rights and remedies
                                    provided under these terms and conditions are in addition to, and not exclusive of,
                                    any rights or remedies provided by law.</p>
                            </li>
                            <li><span className="section" id="_Toc446509184">Severance</span>
                                <ol>
                                    <li>If any provision (or part of a provision) of these terms and conditions is found
                                        by any court or administrative body of competent jurisdiction to be invalid,
                                        unenforceable or illegal, the other provisions shall remain in force.
                                    </li>
                                    <li>If any invalid, unenforceable or illegal provision would be valid, enforceable
                                        or legal if some part of it were deleted, the provision shall apply with
                                        whatever modification is necessary to give effect to the commercial intention of
                                        the parties.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509185">Entire agreement</span>
                                <ol>
                                    <li>These terms and conditions, and any documents referred to in it, constitute the
                                        whole agreement between the parties and supersede any previous arrangement,
                                        understanding or agreement between them relating to the subject matter they
                                        cover.
                                    </li>
                                    <li>Each of the parties acknowledges and agrees that in entering into these terms
                                        and conditions it does not rely on any undertaking, promise, assurance,
                                        statement, representation, warranty or understanding (whether in writing or not)
                                        of any person (whether party to these terms and conditions or not) relating to
                                        the subject matter of these terms and conditions, other than as expressly set
                                        out in these terms and conditions.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509186">Assignment</span>
                                <ol>
                                    <li>The Subscriber shall not, without the prior written consent of the Supplier,
                                        assign, transfer, charge, sub-contract or deal in any other manner with all or
                                        any of its rights or obligations under these terms and conditions.
                                    </li>
                                    <li>The Supplier may at any time assign, transfer, charge, sub-contract or deal in
                                        any other manner with all or any of its rights or obligations under these terms
                                        and conditions.
                                    </li>
                                </ol>
                            </li>
                            <li><span className="section" id="_Toc446509187">No partnership or agency</span>
                                <p>Nothing in these terms and conditions is intended to or shall operate to create a
                                    partnership between the parties, or authorise either party to act as agent for the
                                    other, and neither party shall have the authority to act in the name or on behalf of
                                    or otherwise to bind the other in any way (including, but not limited to, the making
                                    of any representation or warranty, the assumption of any obligation or liability and
                                    the exercise of any right or power).</p>
                            </li>
                            <li><span className="section" id="_Toc446509188">Third party rights</span>
                                <p>These terms and conditions do not confer any rights on any person or party (other
                                    than the parties to these terms and conditions and, where applicable, their
                                    successors and permitted assigns) pursuant to the Contracts (Rights of Third
                                    Parties) Act 1999.</p>
                            </li>
                            <li><span className="section" id="_Toc446509189">Notices</span>
                                <p>Any notice required to be given under these terms and conditions shall be in writing
                                    and shall be delivered by hand or sent by pre-paid first-class post or recorded
                                    delivery post to the other party at its address set out in these terms and
                                    conditions, or such other address as may have been notified by that party for such
                                    purposes, or sent by fax to the other party's fax number as set out in these terms
                                    and conditions.</p>
                                <p>
                                    A notice delivered by hand shall be deemed to have been received when delivered (or
                                    if delivery is not in business hours, at 9 am on the first business day following
                                    delivery). A correctly addressed notice sent by pre-paid first-class post or
                                    recorded delivery post shall be deemed to have been received at the time at which it
                                    would have been delivered in the normal course of post. A notice sent by fax shall
                                    be deemed to have been received at the time of transmission (as shown by the timed
                                    printout obtained by the sender).</p>
                            </li>
                            <li><span className="section" id="_Toc446509190">Governing law</span>
                                <p>These terms and conditions and any dispute or claim arising out of or in connection
                                    with it or its subject matter or formation (including non-contractual disputes or
                                    claims) shall be governed by and construed in accordance with the law of England and
                                    Wales.</p>
                            </li>
                            <li><span className="section" id="_Toc446509191">Jurisdiction</span>
                                <p>Each party irrevocably agrees that the courts of England and Wales shall have
                                    exclusive jurisdiction to settle any dispute or claim arising out of or in
                                    connection with these terms and conditions or its subject matter or formation
                                    (including non-contractual disputes or claims).</p>
                            </li>
                        </ol>
                    </div>
                </div>
            </Box>
        </Container>
    )
}

export default PageContentTCs