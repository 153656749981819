import {FC} from 'react';
import {Box} from '@mui/material';
import FlagDescription from './FlagDescription';
import {StrategyName} from '../../../../services/api';
import StrategyKey from './StrategyKey.json';
import {Metric} from './Metrics';
import HistoricalData from './HistoricalData';
import {getProductKey} from "./utils";

interface IStrategyModalContainer {
    data: any
    strategyName: StrategyName
    symbol: string
    product: string
    metrics: Metric[],
    startTime: string,
    endTime: string,
    isComposite: boolean
}

const StrategyModalContainer: FC<IStrategyModalContainer> = (props) => {

    const {data, strategyName, product, isComposite, metrics = []} = props;

    const productKey = getProductKey(product, isComposite);
    const {name} = StrategyKey[productKey][strategyName];

    /**
     * @dev string of format 5 words eg 'Up Flag to End Flag', 1st and 4th indicate start and end respectively
     */

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
            gridAutoRows: 'minmax(min-content, max-content)',
            gridGap: '10px',
            alignContent: 'center'
        }}>
            <FlagDescription name={name} strategyName={strategyName} product={product} isComposite={isComposite}
                             metrics={metrics}/>
            <HistoricalData product={product} flags={data}/>
        </Box>
    )
}

export default StrategyModalContainer