import Canvas from './Canvas';
import PageContentTCs from './pagecontent/tcs/PageContentTCs';

export const TermsConditions = () => {
    return (
        <Canvas>
            <PageContentTCs/>
        </Canvas>
    )
}



