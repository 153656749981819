import {Box} from "@mui/system";
import {Typography} from "@mui/material";

const Header = ({children}) => {
    return (
        <Box sx={{
            backgroundColor: "rgba(30, 71, 118, 0.7)",
            color: 'white',
            padding: '0.2em 0.5em 0.2em 0.5em',
        }}>
            <Typography gutterBottom={true}>{children}</Typography>
        </Box>);
}

export default Header;
