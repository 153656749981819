import {createSlice} from "@reduxjs/toolkit";

type FetchState = 'failed' | 'idle' | 'loading' | 'succeeded'

interface PortfolioSubscriptionsState {
    subscriptions: object,
    error: string | undefined,
    status: FetchState
}

const initialState: PortfolioSubscriptionsState = {
    subscriptions: {},
    error: undefined,
    status: 'idle'
};

export const portfolioSubscriptionsSlice = createSlice({
    name: 'portfolioSubscriptions',
    initialState,
    reducers: {
        updateSubscriptions: (
            state,
            {payload}) => {
            state.subscriptions = payload;
            state.status = 'succeeded';
        },
    },
});

export default portfolioSubscriptionsSlice.reducer;

export const {updateSubscriptions} = portfolioSubscriptionsSlice.actions;

export const selectPortfolioStatus = state => state.portfolioSubscriptions.status;

export const selectPortfolioSubscriptions = state => state.portfolioSubscriptions.subscriptions;
