import * as log from 'loglevel';
import {configureStore} from '@reduxjs/toolkit'
import AuthReducer, {updateContext} from './features/auth/authSlice';
import {authService} from "../services/AuthenticationService";
import CompositeSubscriptionsReducer from './features/composites/compositeSubscriptionsSlice';
import PortfolioSubscriptionsReducer from './features/portfolios/portfolioSubscriptionsSlice';
import InstrumentReducer from './features/instruments/instrumentSlice';
import SystemStatusReducer from './features/system/systemSlice';
import {api} from '../services/api';

const store = configureStore({
    // Define a top level state field name `auth`, handled by `instrumentReducer`
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: AuthReducer,
        instrument: InstrumentReducer,
        compositeSubscriptions: CompositeSubscriptionsReducer,
        portfolioSubscriptions: PortfolioSubscriptionsReducer,
        systemStatus: SystemStatusReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),

})

authService.onTokenRefresh = async () => {
    try {
        const context = await authService.asyncAuthContext();
        store.dispatch(updateContext(context))
    } catch (e) {
        log.error("OnTokenRefresh failed", e);
    }
}

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {auth: AuthState, instrumentReducer: InstrumentStates}
export type AppDispatch = typeof store.dispatch
