import {FC, useState} from 'react';
import {makeStyles} from 'tss-react/mui';
import {Grid, IconButton, Typography, useMediaQuery} from '@mui/material';
import DialogueModal from '../../../common/DialogueModal';
import FileDownloadIcon from '@mui/icons-material/FileCopy';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {useGetResourcesQuery} from "../../../../services/api";

const useStyles = makeStyles()(() => ({
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: '#E5E7EC',
        height: '1000px'
    },
    gridList: {
        width: 1200,
        height: 750,
        background: '#E5E7EC'
        // background: "white"
    },
    gridHeader: {
        background: "black",
        height: '20px'
    },
    cardRoot: {
        '@media (max-width: 600px)': {
            maxWidth: "90vw",
            height: "25vh",
            borderRadius: '0px'
        },
        '@media (min-width: 600px)': {
            maxWidth: "450px",
            height: "420px",
            borderRadius: '0px'
        }
    },
    cardDesc: {
        maxHeight: '150px',
        overflow: 'hidden'
    },
    cardHeader: {
        backgroundColor: 'rgba(30, 71, 118, 1)',
        color: "white",
        height: "3rem",
        padding: "10px"
    },
    cardActionArea: {
        height: "80%"
    },
    cardContent: {
        minHeight: "150px"
    },
    cardActions: {
        // backgroundColor : theme.palette.secondary.light
    },
    rcContainer: {
        width: "100%",
        display: "grid",
        '@media (max-width: 600px)': {
            gridTemplateColumns: "10% 65% 15%",
            minHeight: "15vh"
        },
        '@media (min-width: 600px)': {
            gridTemplateColumns: "25% 50% 25%",
            maxHeight: "20vh"
        },

        backgroundColor: "white"
    },
    rcIconContainer: {
        gridColumn: "1/2",
        width: "100%",
        placeSelf: "center",
        alignSelf: "center"
    },
    rcIcon: {
        '@media (max-width: 600px)': {
            fontSize: "1.1rem",
            marginLeft: "5px"
        },
        '@media (min-width: 600px)': {
            fontSize: "3rem",
            marginLeft: "calc(50% - 1.5rem)"
        },
        alignSelf: "center",
    },
    rcButton: {
        display: "flex",
        alignItems: "center",
        marginLeft: "calc(40% - 1.5rem)"
    },
    rcTitle: {
        gridColumn: "2/3",
        width: "100%",
        placeSelf: "center",
    },
    rcText: {
        '@media (max-width: 600px)': {
            fontSize: "0.8rem"

        },
        '@media (min-width: 600px)': {
            fontSize: "1.3rem",
        },
    },
    rcVideoContainer: {
        gridColumn: "3/4",
        justifySelf: "center",
        alignSelf: "center"
    },
    rcVideo: {
        maxWidth: "100%",
        '@media (max-width: 600px)': {
            minHeight: "10vh"
        },
        maxHeight: "20vh"
    },

}));

const ResourceCardFullWidth: FC<ResourceCardProps> = (props) => {

    const {data: {name = '', src = '', type = 'mp4'}, title = ''} = props;

    const {classes} = useStyles();

    const mobile = useMediaQuery('(max-width:600px)');

    const [modalOpen, setModalOpen] = useState(false);

    const modalHandler = () => {
        setModalOpen(!modalOpen)
    }

    const displayTitle = JSON.stringify([title, '', '']);

    const downloadPdf = (filename, url) => {
        const file = document.createElement("a");
        file.download = `${filename}.pdf`;
        file.href = url;
        file.click()
    };

    return (
        <div className={classes.rcContainer}>
            <div className={classes.rcIconContainer}>
                {type === 'mp4' ?
                    <IconButton onClick={() => modalHandler()} className={classes.rcButton}>
                        <PlayCircleOutlineIcon className={classes.rcIcon}/>
                    </IconButton>
                    :
                    <IconButton onClick={() => downloadPdf(name, src)} className={classes.rcButton}>
                        <FileDownloadIcon className={classes.rcIcon}/>
                    </IconButton>
                }
            </div>
            <div className={classes.rcTitle}>
                <Typography variant={mobile ? "body1" : "h5"} component="p" className={classes.rcText}>
                    {name}
                </Typography>
            </div>
            <div className={classes.rcVideoContainer}>
                <video className={classes.rcVideo} controls={false} controlsList="nodownload" src={src}>
                </video>
            </div>
            <DialogueModal title={displayTitle}
                           open={modalOpen} modalHandler={modalHandler}
            >
                {type === 'mp4' && <video controls height="608" width="1080" src={src}></video>}
            </DialogueModal>
        </div>
    )

}

interface ResourceInfo {
    name: string,
    src: string,
    type: 'mp4' | 'pdf'
}

interface ResourceCardProps {
    data: ResourceInfo
    title: string
}

const PageContentResources = () => {
    const {classes} = useStyles();

    const mobile = useMediaQuery('(max-width:600px)');

    const setRows = (data: any[], rows: number): Array<Array<any>> => {
        let dataRows: any[] = [];
        for (let i = 0; i < data.length; i += rows) {
            let row: any[] = []
            for (let j = 0; j < rows; j++) {
                const cell = i + j;
                if (cell < data.length) {
                    row.push(data[cell])
                }
            }
            dataRows.push(row)
        }

        return dataRows
    }

    const cellPerRow = 1;

    const {data = []} = useGetResourcesQuery({}, {
        refetchOnMountOrArgChange: true,
    });

    const baseUrl = "https://aldx-web-assets.s3-eu-west-1.amazonaws.com/assets";
    const conferences = data.reduce((filtered: any[], item) => {
        if (item.name.toLowerCase().includes('conference')) {
            const resource = {
                name: item.name,
                type: item.resourceType === 'Video' ? 'mp4' : 'pdf',
                src: `${baseUrl}/${item.path}`
            };
            filtered.push(resource);
        }
        return filtered;
    }, []);
    const researchNotes = data.reduce((filtered: any[], item) => {
        if (item.resourceType === 'Notes') {
            const resource = {
                name: item.name,
                type: 'pdf',
                src: `${baseUrl}/${item.path}`
            };
            filtered.push(resource);
        }
        return filtered;
    }, []);
    const webinars = data.reduce((filtered: any[], item) => {
        if (item.name.toLowerCase().includes('webinar')) {
            const resource = {
                name: item.name,
                type: item.resourceType === 'Video' ? 'mp4' : 'pdf',
                src: `${baseUrl}/${item.path}`
            };
            filtered.push(resource);
        }
        return filtered;
    }, []);
    const workshops = data.reduce((filtered: any[], item) => {
        if (item.resourceType === 'Video' && item.name.toLowerCase().includes('workshop')) {
            const resource = {
                name: item.name,
                type: 'mp4',
                src: `${baseUrl}/${item.path}`
            };
            filtered.push(resource);
        }
        return filtered.reverse();
    }, []);

    const resourceSections = [
        {
            name: 'Webinar',
            data: setRows(webinars, cellPerRow)
        },
        {
            name: "Workshops",
            data: setRows(workshops, cellPerRow)
        },
        {
            name: "Research Notes",
            data: setRows(researchNotes, cellPerRow)
        },
        {
            name: "Conferences",
            data: setRows(conferences, cellPerRow)
        }
    ]

    return (
        <Grid container spacing={1} style={{marginTop: '20px'}}>
            <Grid item md={2} sm={1}/>
            <Grid item md={8} sm={10}>
                {resourceSections.map((content, index) => {
                    return (
                        <Grid container spacing={1} key={index}>
                            <Grid item xs={12}>
                                <Typography variant={mobile ? "body1" : "h5"} component="p"
                                            className={classes.cardHeader}>
                                    {content.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {content.data.map((row, rowIdx) => (
                                        <Grid key={`row-${rowIdx}`} item xs={12}>
                                            <Grid container spacing={2}>
                                                {row.map((cell, idx) => (
                                                    <Grid key={`${cell.name}-${idx}`} item xs={12}>
                                                        <ResourceCardFullWidth data={cell} title={content.name}/>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item md={2} sm={1}/>
        </Grid>
    )
}

export default PageContentResources;