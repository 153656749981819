import Canvas from './Canvas';
import PageContentSupport from './pagecontent/support/PageContentSupport';

export const Support = () => {
    return (
        <Canvas>
            <PageContentSupport/>
        </Canvas>
    )
}



