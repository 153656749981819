import {makeStyles} from 'tss-react/mui';
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {useTypedSelector} from '../../../../reducers/hooks/hooks';
import {selectAuthContext} from '../../../../reducers/features/auth/authSlice';
import Loading from '../../../common/Loading';

const useStyle = () => ({
    table: {
        width: "100%",
        backgroundColor: "white"
    },
    tableHead: {
        backgroundColor: 'rgba(0,0,0,0.05)',
        color: 'black'
    },
    tableHeaderCell: {
        color: "black",

    },
});

const useStyles = makeStyles()(useStyle);

const UserInfoTable = () => {
    const {classes} = useStyles();

    const {user} = useTypedSelector(selectAuthContext);

    if (user) {
        return (
            <Box>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableHead}>
                            <TableCell className={classes.tableHeaderCell}>User Information</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(user).map((info, index) => (
                            <TableRow key={index}>
                                <TableCell>{info}</TableCell>
                                <TableCell>{user[info]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        )
    } else {
        return (<Loading type="Loading"/>)
    }
}

export default UserInfoTable