export const historicalSchema = {
    type: 'object',
    properties: {
        algorithmSpecId: {type: 'number'},
        dts: {type: 'number'},
        flag: {type: 'string'},
        price: {type: 'number'},
        symbol: {type: 'string'}
    },
    required: ["algorithmSpecId", "dts", "flag", "price", "symbol"],
    additionalProperties: true
}


export const historicalArraySchema = {
    type: 'array',
    items: historicalSchema
}