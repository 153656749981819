import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import './App.css';
import ProtectedRoutes from "./ProtectedRoutes";

/// pages
import {Support} from './components/pages/Support';
import {TermsConditions} from "./components/pages/TermsConditions";
import AppCanvas from "./components/pages/AppCanvas";

export const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? "https://api.algodynamix.com";

const App = () => {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#ffffff',
            }
        },
        typography: {
            fontFamily: 'Arial'
        }
    });

    const router = createBrowserRouter([{
        path: "/*",
        element: <AppCanvas/>,
        children: [
            {path: "support", element: <Support/>},
            {path: "tc", element: <TermsConditions/>},
            {path: "*", element: <ProtectedRoutes/>},
        ]
    }])

    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}/>
        </ThemeProvider>
    )
}

export default App;
