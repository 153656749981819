import Canvas from './Canvas';
import PageContentPortfolios from './pagecontent/portfolios/PageContentPortfolios';

export const Portfolios = () => {
    return (
        <Canvas>
            <PageContentPortfolios/>
        </Canvas>
    )
}
