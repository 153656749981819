import {Fragment, FunctionComponent, ReactNode, useState} from 'react';
import {makeStyles} from 'tss-react/mui';
import {Box, Tab, Tabs, Typography} from '@mui/material';
import {Theme} from "@mui/material/styles";

const useCustomTabStyle = (theme: Theme) => ({
    customTabRoot: {
        backgroundColor: 'black',
        lineHeight: '1rem',

    },
    customTableIndicator: {
        backgroundColor: 'rgba(156, 39, 176, 1)',
        height: '2px'
    },
    customFlexInherit: {
        color: 'white',
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: 'white'
        }
    },
    typographyRoot: {
        padding: '20px 5px 5px 5px',
    }
});

const customTabStyles = makeStyles()(useCustomTabStyle);


export type TabsGeneralData = {
    name: string | ReactNode,
    comp: any
}

interface TabsGeneralProps {
    data: TabsGeneralData[],
    level: 'primary' | 'secondary',
    onTabChange?: (any) => void,
}

const TabPanel = (props) => {
    const {classes} = customTabStyles()

    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tab-panel"
            hidden={value !== index}
            id={`nav-tab-panel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && <Box margin={0} p={3} className={classes.typographyRoot}>{children}</Box>}
        </Typography>
    );
}


const TabsGeneral: FunctionComponent<TabsGeneralProps> = (props) => {
    const {classes} = customTabStyles();

    const {data, onTabChange} = props;

    const headers = data.map(_ => _.name);
    const comps = data.map(_ => _.comp);

    const [tabIndex, setTabValue] = useState(0)
    const tabIndexHandler = (_e, newTabIndex) => {
        setTabValue(newTabIndex);
        if (onTabChange) {
            onTabChange(newTabIndex);
        }
    };

    return (
        <Fragment>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={tabIndex}
                    onChange={tabIndexHandler}
                    variant="fullWidth"
                    aria-label="nav tabs"
                    centered
                    TabIndicatorProps={{className: classes.customTableIndicator}}>
                    {headers.map((name, index) => (
                        <Tab label={name}
                             id={`nav-tab-${index}`}
                             aria-controls={`nav-tab-panel-${index}`}
                             onClick={(e) => e.preventDefault()}
                             key={`${name}-${index}`}
                        />
                    ))}
                </Tabs>
            </Box>
            {comps.map((comp, index) => (
                <TabPanel value={tabIndex} index={index} key={`${index}`}>
                    {comp}
                </TabPanel>
            ))}
        </Fragment>
    )
}

export default TabsGeneral;