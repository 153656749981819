import Canvas from "./Canvas";
import {CompositePageContentAlertStatus} from "./pagecontent/alertstatus/PageContentAlertStatus";

export const CompositeAlertStatus = () => {
    const subHeader = [
        {'name': 'Red', 'description': 'Flag issued  < 24 hours', 'color': 'red'},
        {'name': 'Amber', 'description': 'Flag issued  1 - 3 days', 'color': 'orange'},
        {'name': 'Grey', 'description': 'No End Flag', 'color': 'grey'}];

    return (
        <Canvas subHeader={subHeader}>
            <CompositePageContentAlertStatus/>
        </Canvas>
    )
}