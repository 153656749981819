import {FC, Fragment} from "react";
import {Box, Tooltip} from '@mui/material';

export interface Metric {
    name: string,
    description: string,
    value: string,
}

interface MetricProps {
    metric: Metric
}

interface MetricsProps {
    metrics: Metric[]
}

export const MetricRow: FC<MetricProps> = ({metric}) => {
    return (
        <Fragment>
            <Box sx={{
                // metric_card_data
                backgroundColor: 'rgba(30, 71, 118, 1)',
                borderRadius: '50%',
                width: "6em",
                height: "6em",
                margin: '1px',
                alignSelf: 'center',
                alignContent: 'center',
                justifyContent: "center",
                justifySelf: 'center',
                textAlign: "center",
                color: 'white',
                overflowY: 'hidden',
                display: 'grid',
            }}>
                <Tooltip title={metric.description}>
                    <Box>
                        {metric.value}<br/>{metric.name}
                    </Box>
                </Tooltip>
            </Box>
            <Box>&nbsp;</Box>
        </Fragment>);
}

export const Metrics: FC<MetricsProps> = ({metrics}) => {
    return (
        <Box sx={{
            // rowInfoCardItem
            backgroundColor: 'white',
            width: '100%',
            display: 'grid',
            padding: '10px',
            // rowInfoCardItem_metrics
            gridTemplateColumns: '1fr 2fr',
            gridTemplateRows: '1fr 1fr',
            gap: '10px ',
            gridGap: '10px',
        }}>
            {metrics.map((metric, index) => <MetricRow key={index} metric={metric}/>)}
        </Box>
    );
}