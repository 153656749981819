import {FC, ReactNode} from 'react';
import {makeStyles} from 'tss-react/mui';
import {LinearProgress, Typography} from '@mui/material';

const useStyles = makeStyles()(() => ({
    container: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "repeat(3, 1fr)",
        margin: "10px",
        width: "100%",
        height: "100%",
        minHeight: "600px"
    },
    loading: {
        gridColumn: "2 / 3",
        gridRow: "2 / 3",
        display: "grid",
        gridTemplateRows: "20% 80%"
    },
    loadingHeader: {
        backgroundColor: 'white',
        gridRow: "1 / 2",
        textAlign: "center",
        paddingTop: "5px"
    },
    loadingContent: {
        backgroundColor: 'white',
        gridRow: "2 / 3"
    }

}));

interface LoadingProps {
    type: "Authentication" | "Data" | "Delay Loading" | "Error" | "Loading" | "Loading Data",
    children?: ReactNode
}

const Loading: FC<LoadingProps> = (props) => {

    const {classes} = useStyles();

    const {type, children} = props;

    return (
        <div className={classes.container}>
            <div className={classes.loading}>
                <div className={classes.loadingHeader}>
                    <Typography variant="h6">{type}</Typography>
                </div>
                <div className={classes.loadingContent}>
                    <LinearProgress color="secondary"/>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Loading;