import {unixConverter, unixConverterEncode} from './utils';
import {ApiReq, HistoricalData} from './api';
import {productsOrdered} from '../namespace/namespace';
import {historicalSchema} from '../models/historicalSchema';
import Ajv from 'ajv';

type TDataProcessors = {
    [Req in ApiReq]: (...args: any[]) => any
}

function parse_response(response: any): any {
    const result: object = typeof response === 'string' ? JSON.parse(response) : response;
    if (result != null) {
        return result;
    } else {
        return 'error';
    }
}

const isValidHistorical = new Ajv().compile(historicalSchema);

export const DataProcessors: TDataProcessors = {
    historical: function (response: any, product: string): HistoricalData[] {
        const flags: any[] = parse_response(response);
        if (!Array.isArray(flags) || flags.length === 0) return [];

        const endFlag = product.includes("RAP") ? "Up" : "Down";
        const descFlags = flags.filter(flag => isValidHistorical(flag));
        descFlags.sort((a, b) => {
            return a.dts - b.dts
        })

        const noEndFlagStart = descFlags[0].flag === endFlag ? descFlags.slice(1) : descFlags;

        const indexEndFlags = noEndFlagStart.reduce((a, b, c) => {
            if (b.flag === endFlag) {
                a.push(c)
            }
            return a
        }, []);

        let fls: any[] = [];

        for (let i = 0; i < indexEndFlags.length; i++) {
            const startPosition = i === 0 ? 0 : indexEndFlags[i - 1] + 1;
            const reducedFlag = noEndFlagStart.slice(startPosition, indexEndFlags[i]);

            const reducedFlagNew: HistoricalData[] = reducedFlag.map((f) => {
                return {
                    symbol: f['symbol'],
                    price: f['price'],
                    flag: f['flag'],
                    dts: unixConverter(parseInt(f['dts'])),
                    endFlag : noEndFlagStart[indexEndFlags[i]]['flag'],
                    endPrice : noEndFlagStart[indexEndFlags[i]]['price'],
                    endTime : unixConverter(noEndFlagStart[indexEndFlags[i]]['dts'])
                }
            });
            fls.push(reducedFlagNew);
        }

        const sortedFlags = fls.reduce((acc, val) => (acc.concat(val)), [])
        sortedFlags.sort((a, b) => {
            return unixConverterEncode(b['dts']) - unixConverterEncode(a['dts'])
        })

        return sortedFlags;
    },
    subscriptions: function (response: any): any[] | 'error' {
        const subscriptionsObj = parse_response(response);
        if (Object.keys(subscriptionsObj).includes('subscriptions')) {
            return subscriptionsObj['subscriptions'];
        }
        return 'error'
    },
    availableSubscriptions: parse_response,
    compositeRealTimeStatus: parse_response,
    realTimeAll: function (response: any): any {
        const result: object[] = parse_response(response);
        const ReorderFlags = (data) => {
            const {flags} = data;

            if (flags) {
                const capitalisedProducts = Object.keys(flags).reduce((acc, val) => {
                    if (acc[val] === undefined) {
                        const upperCase = String(val).toUpperCase();
                        acc[upperCase] = val
                        return acc
                    }
                    return acc
                }, {})

                const flagsOrdered = productsOrdered.reduce((acc, product) => {
                    const apiProductName = capitalisedProducts[product];
                    if (acc[product] === undefined && flags[apiProductName] != null) {
                        acc[apiProductName] = flags[apiProductName];
                        return acc
                    }
                    return acc
                }, {})

                return {...data, "flags": flagsOrdered}
            }

            return data
        }

        try {
            if (result !== null && typeof result === 'object' && result['flags'] !== undefined) {
                return ReorderFlags(result);
            }

            return 'Incorrect Format'

        } catch (e) {
            return e;
        }
    },
    portfolioSubscriptions: parse_response,
    portfolioWeights: parse_response,
    resources: parse_response,
    strategy: parse_response,
    stockSplit: parse_response,
    systemStatus: parse_response,
}