import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

type InstrumentStatus = {
    apiTime: string,
    status: 'Connecting' | 'Active'
}

export const slice = createSlice({
    name: 'instrument',
    initialState: {apiTime: moment().format(), status: 'Connecting'} as InstrumentStatus,
    reducers: {
        lastUpdate: (state, {payload: {apiTime, status}}) => {
            state.apiTime = apiTime;
            state.status = status;
        }
    }
})

export default slice.reducer;

export const {lastUpdate} = slice.actions;

export const selectApiTimeAndStatus = state => state.instrument;
