import {createSlice} from "@reduxjs/toolkit";
import { ComponentStatus } from "../../../services/api";

type SystemStatus = {
	status: 'Healthy' | 'Unhealthy' | 'Unknown',
	components: ComponentStatus[] 
}

export const slice = createSlice({
    name: 'systemStatus',
    initialState: {status: 'Unknown', components: []} as SystemStatus,
    reducers: {
        updateSystemStatus: (state, {payload: {isError, components}}) => {
					  if (isError || !components) { 
								state.status = 'Unknown';
						} else {
						    const isLive = components.reduce((isLive: boolean, component: ComponentStatus) => {
									  return isLive && component.status === 'True';
	            	}, true);
								if (isLive) {
	            			state.status = 'Healthy';
								} else {
										state.status = 'Unhealthy';
								}
						}
						if (components) {
	            state.components = components;
						}
        }
    }
})

export default slice.reducer;

export const {updateSystemStatus} = slice.actions;

export const selectSystemStatus = state => state.systemStatus;
