import Canvas from './Canvas';
import PageContentSubscription from '../pages/pagecontent/subscriptions/PageContentSubscriptions';

export const Subscriptions = () => {
    const subHeader = [{'name': '', 'description': 'Settings', 'color': 'white'}];
    return (
        <Canvas subHeader={subHeader}>
            <PageContentSubscription/>
        </Canvas>
    )
}