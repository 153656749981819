import {Fragment} from 'react';
import Loading from './Loading';

export const ErrorLoading = () => {

    return (
        <Fragment>
            <Loading type='Loading'>
                <p>
                    Delayed Response from server, please contact support if issue persists.
                    support@algodynamix.com
                </p>
            </Loading>
        </Fragment>
    )
}