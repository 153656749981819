import {Button, Divider,} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {Box} from '@mui/system';
import {Theme} from '@mui/material/styles';
import {Link} from "react-router-dom"

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    footerAddress: {
        flexGrow: 1,
        flexBasis: "33%",
        textAlign: "center"

    },
    footerBase: {
        height: "50px",
        backgroundColor: 'white',
        color: 'black',
        display: "flex",
        flexFlow: "row no-wrap",
        justifyContent: "center"
    },
    footerContent: {
        height: "200px",
        display: "flex",
        flexFlow: "row no-wrap",
        justifyContent: "center",
        color: "white"
    },
    footerLinks: {
        flexGrow: 1,
        flexBasis: "33%"
    },
    hyperLinkBtn: {
        textTransform: 'none',
        color: 'white',
        "&:hover": {
            backgroundColor: 'rgba(0,0,0,0)',
            textDecoration: "underline"
        }
    },
    mobileDraw: {
        '@media (min-width: 600px)': {
            width: '150px'
        }
    },
    pageFooter: {
        height: '250px',
        backgroundColor: 'rgba(81,109,145,1)',
        // position: 'relative',
        width: '100%'
    },
    subToolbar: {
        //backgroundColor: 'rgba(60, 77, 94,0.5)',
        //backgroundColor: '#233358',
        backgroundColor: 'rgba(81,109,145,1)',
        minHeight: '70px',
        color: 'rgb(0,0,0)',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between'
    },
    title: {
        flexGrow: 1
    },
}));

const Footer = () => {
    const {classes} = useStyles();
    const year = new Date().getFullYear();

    const sxLink = {flexGrow: 1, flexBasis: '33%'};
    const sxButtonLink = {
        textTransform: 'none',
        color: 'white',
        "&:hover": {
            backgroundColor: 'rgba(0,0,0,0)',
            textDecoration: "underline"
        }
    };
    return (
        <Box component="footer" sx={{
            height: '250px',
            backgroundColor: 'rgba(81,109,145,1)',
            width: '100%'
        }}>
            <Box sx={{
                height: "200px",
                display: "flex",
                flexFlow: "row no-wrap",
                justifyContent: "center",
                color: "white"
            }}>
                <Box sx={sxLink}/>
                <Box sx={{textAlign: "center", ...sxLink}}>
                    <p>
                        46 New Broad Street <br/>
                        London <br/>
                        United Kingdom <br/>
                        EC2M 1JH <br/>
                    </p>
                </Box>
                <Box sx={sxLink}>
                    <Button to={'/tc'} variant={'text'} component={Link} sx={sxButtonLink}>
                        Terms and Conditions
                    </Button>
                    <Divider/>
                    <Button to={'/support'} variant={'text'} component={Link} sx={sxButtonLink}>
                        Support
                    </Button>
                </Box>
            </Box>
            <div className={classes.footerBase}>
                <p> Copyright &#169; {year} AlgoDynamix </p>
            </div>
        </Box>);
}

export default Footer;