import {FC, ReactNode} from 'react';
import {makeStyles} from 'tss-react/mui';
import {Theme} from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex'
    },
    pageContent: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: '0px',
        backgroundColor: '#E5E7EC',
        marginTop: '110px',
        minHeight: "100vh",
        width: '100vw'
    },
}));

type SubHeader = {
    name: string,
    description: string,
    color: string
}

type CanvasProps = {
    children: ReactNode,
    sidebar?: ReactNode,
    subHeader?: SubHeader[],
}


const Canvas: FC<CanvasProps> = (props) => {
    const {children} = props;
    const {classes,} = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.pageContent}>
                {children}
            </div>
        </div>
    )
}
export default Canvas;