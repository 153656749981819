import {FC, Fragment, useState} from 'react';
import {Theme} from '@mui/material/styles';
import {makeStyles} from 'tss-react/mui';
import {
    Box,
    Collapse,
    Grid,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChangePassword from './ChangePassword';
import UserInfoTable from './UserInfoTable';
import Loading from '../../../common/Loading';
import {useTypedSelector} from '../../../../reducers/hooks/hooks';
import {selectCurrentUser} from '../../../../reducers/features/auth/authSlice';
import {useGetAllSubscriptionsQuery, useGetAvailableSubscriptionsQuery} from '../../../../services/api';
import {productNamespace} from '../../../../namespace/namespace';
import * as log from 'loglevel';

const useStyle = (theme: Theme) => ({
    main: {
        margin: "0px"
    },
    table: {
        width: "100%",
        backgroundColor: "white"
    },
    tableHead: {
        backgroundColor: 'rgba(60, 77, 94,0.5)',
        color: 'black'
    },
    tableHeaderCell: {
        color: "black",

    },
    cardHead: {
        width: "100%",
        padding: '10px',
        color: "white",
        backgroundColor: 'rgba(30, 71, 118, 1)',
    },
    autoComplete: {
        width: '200px',
        color: "white",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "green"
        },
        "& .MuiFormLabel-root": {
            color: "rgba(255,255,255,0.5)"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        }
    },
    list: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    listItem: {
        borderBottom: "2px solid grey",
    }
})

const useStyles = makeStyles()(useStyle);

interface ProductSubscriptionTableHeaderProps {
    symbols: string[],
    region: string
    onSymbolSelect: (val: any) => any
}

const ProductSubscriptionTableHeader: FC<ProductSubscriptionTableHeaderProps> = (props) => {
    const {region, symbols, onSymbolSelect} = props;

    const {classes} = useStyles();

    return (
        <div>
            <Autocomplete
                id={`instrument-${region}`}
                options={symbols}
                onChange={(_e, value) => onSymbolSelect(value)}
                renderInput={params => (
                    <TextField {...params} label='instruments' variant='standard' fullWidth
                               className={classes.autoComplete}/>
                )}
                popupIcon={<SearchIcon style={{color: 'white'}}/>}
                clearOnEscape={true}
                autoSelect={true}
                includeInputInList={true}
            />
        </div>
    )
}

interface IProductSubscriptionTableProps {
    regionSymbols: any,
    region: string
}

const ProductSubscriptionTable: FC<IProductSubscriptionTableProps> = (props) => {
    const {regionSymbols, region} = props;

    const {classes} = useStyles();

    const symbols = Object.keys(regionSymbols);

    const [regSymbols, setRegSymbols] = useState(symbols || []);

    const onSymbolSelectHandler = (symbol) => {
        if (symbol === null || symbol === '') {
            setRegSymbols(symbols)
        } else {
            setRegSymbols([symbol])
        }
    }

    return (
        <Box>

            <div className={classes.cardHead}>
                <Typography>{region}</Typography>
                <ProductSubscriptionTableHeader region={region} symbols={symbols}
                                                onSymbolSelect={onSymbolSelectHandler}/>
            </div>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableHead}>
                        {['Symbol', 'Exchange', 'Description', 'Subscriptions'].map((v, i) => {
                            const colSpan = (v !== "Subscriptions") ? 1 : 10;
                            return (
                                <TableCell className={classes.tableHeaderCell} colSpan={colSpan} key={i}>{v}</TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {regSymbols.map((symbol) => {
                        const symbolData = regionSymbols[symbol];

                        return (
                            <TableRow key={symbol}>
                                <TableCell>{symbol}</TableCell>
                                <TableCell>{symbolData.exchange}</TableCell>
                                <TableCell>{symbolData.description}</TableCell>
                                {symbolData.alertType.map((aType, index) => {
                                    return (
                                        <TableCell key={`${aType.type}-${index}`}>{aType.subscribed ?
                                            <CheckCircleIcon/> :
                                            <RadioButtonUncheckedIcon/>} {aType.type[0]}&trade; {aType.type[1]}</TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Box>
    )
}

const instrumentsSubscribed = (subscriptionsByProduct) => {
    return subscriptionsByProduct.reduce((acc, product) => {
        const symbol = Object.keys(product)[0];
        if (acc[symbol] === null || acc[symbol] === undefined) {
            acc[symbol] = product[symbol].reduce((acc, val) => {
                const sym = val.symbol;
                if (acc[sym] === null || acc[sym] === undefined) {
                    acc[sym] = true
                    return acc
                }
                return acc
            }, {})
            return acc
        }
        return acc
    }, {})
};

const groupByRegionBySymbol = (subscriptions) => {
    const groupedByRegion = subscriptions.reduce((acc, val) => {
        const region = val.region;
        if (acc[region] === undefined || acc[region] === null) {
            acc[region] = [];
            acc[region].push(val);
            return acc
        } else {
            acc[region].push(val)
            return acc
        }
    }, {});


    const groupBySymbol = (regionalSymbols) => {
        return regionalSymbols.reduce((acc, val) => {
            const product = String(val.alertType).toUpperCase();
            const alertTypeDescription = productNamespace[product] !== undefined ?
                productNamespace[product]['displayName'] : [product, ''];

            const alertType = {type: alertTypeDescription, subscribed: val.subscribed || false};
            const symbol = val.symbol;
            if (acc[symbol] === undefined || acc[symbol] === null) {
                acc[symbol] = {...val, 'alertType': [alertType]};
                return acc
            } else {
                acc[symbol]["alertType"].push(alertType);
                return acc
            }
        }, {})
    };

    return Object.keys(groupedByRegion).map(region => (groupBySymbol(groupedByRegion[region])));
}
const processData = (availableSubscriptions, mySubscriptions) => {
    const subscribedProducts = instrumentsSubscribed(mySubscriptions);
    const allProductsSubscribed = availableSubscriptions.map(instrument => {
        const product = String(instrument.alertType).toUpperCase();
        const symbols = subscribedProducts[product];
        if (!symbols) return false
        return ({...instrument, 'subscribed': symbols[instrument.symbol] || false})
    }).filter(instrument => (instrument !== false));

    return groupByRegionBySymbol(allProductsSubscribed);
}

const SubscriptionData = () => {

    const currentUser = useTypedSelector(selectCurrentUser);
    const email = currentUser?.email ?? '';
    const {data: mySubscriptions, isSuccess: isMySubscriptionSuccess} = useGetAllSubscriptionsQuery({email}, {
        refetchOnMountOrArgChange: true,
    });

    const {
        data: availableSubscriptions,
        isSuccess: isAvailableSubscriptionSuccess
    } = useGetAvailableSubscriptionsQuery({}, {
        refetchOnMountOrArgChange: true,
    });

    let data: any | undefined = undefined;
    if (isMySubscriptionSuccess && isAvailableSubscriptionSuccess) {
        try {
            data = processData(availableSubscriptions, mySubscriptions);
        } catch (e) {
            log.error('Failed to process subscription data', e);
        }
    }

    if (data) {
        return (
            <Grid item xs={12}>
                {data.map((reg, index) => {
                    const region = Object.values<{ [key: string]: any }>(reg)[0].region;
                    return (
                        <ProductSubscriptionTable regionSymbols={reg} region={region} key={`${index}`}/>
                    )
                })
                }
            </Grid>
        )
    } else {
        return (
            <Loading type='Loading Data'/>
        )
    }

}

const PageContentSubscription: FC = () => {
    const {classes} = useStyles();

    const [rowOpen, setRowOpen] = useState({});

    const setRowOpenHandler = (id) => {

        const rowIdOpen = rowOpen[id] === undefined ? true : !rowOpen[id];

        setRowOpen({...rowOpen, [id]: rowIdOpen})
    }

    const subscriptionHeaders = [
        {name: "User Information", component: <UserInfoTable/>},
        {name: "Change Password", component: <ChangePassword/>},
        {name: "Subscriptions", component: <SubscriptionData/>},
    ]

    return (
        <Grid container className={classes.main}>
            <Grid item xs={1}/>
            <Grid item xs={10}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                    </Grid>

                    <List className={classes.list}>
                        {subscriptionHeaders.map((row, index) => {

                            const rowIdOpen = rowOpen[index] === undefined ? false : rowOpen[index]
                            return (
                                <Fragment key={index}>
                                    <ListItem button onClick={() => setRowOpenHandler(index)}
                                              className={classes.listItem}>
                                        <ListItemText primary={row.name}/>
                                        {rowIdOpen ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>
                                    <Collapse in={rowIdOpen} timeout="auto" unmountOnExit>
                                        <Box margin={0}>
                                            {row.component}
                                        </Box>
                                    </Collapse>
                                </Fragment>
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    )
}


export default PageContentSubscription;